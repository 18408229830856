<template>
  <div class="text-editor">
    <editor
      v-bind="$props"
      :api-key="tinymceApiKey"
      :init="editorConfig"
      :value="value"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    />
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
  components: {
    Editor
  },
  props: {
    ...Editor.props,
    value: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: null
    }
  },
  computed: {
    tinymceApiKey() {
      return process.env.VUE_APP_TINYMCE_API_KEY;
    },
    editorConfig() {
      return {
        plugins:
          'lists code codesample emoticons media image imagetools link fullscreen',
        height: 500,
        toolbar: [
          'bold italic strikethrough underline | numlist bullist outdent indent | link image emoticons | fullscreen',
          'formatselect forecolor backcolor removeformat | alignleft aligncenter alignright alignjustify'
        ],
        ...(this.config || {})
      };
    }
  }
};
</script>
<style scoped lang="scss">
.text-editor /deep/ {
  .tox-fullscreen {
    top: 3.5em !important;
  }
}
</style>
