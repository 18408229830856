<template>
  <div class="billing-status-column" @click.stop.prevent>
    <template v-if="canChangeStatus">
      <select-content
        :value="lineItem.status"
        :items="allowedStatuses"
        placement="right"
        :disabled="allowedStatuses.length === 0"
        @change="$emit('change', $event)"
      >
        <status-icon
          :icons="OrderLineItemStatusIcons"
          :status="lineItem.status"
        />

        <status-icon
          slot="option"
          slot-scope="{ status }"
          :icons="OrderLineItemStatusIcons"
          :status="status"
          inline
        />
      </select-content>
    </template>

    <template v-else>
      <el-tooltip :disabled="canChangeStatus" effect="light">
        <template #content>
          <div class="w-40">
            <a
              href="https://intercom.help/flytedesk/articles/3102562-assigning-team-roles"
              target="_blank"
              >Learn more about user permission roles
            </a>
          </div>
        </template>
        <status-icon
          :class="{ 'cursor-not-allowed': !canChangeStatus }"
          :icons="OrderLineItemStatusIcons"
          :status="lineItem.status"
        />
      </el-tooltip>
    </template>
  </div>
</template>

<script>
import SelectContent from '@/components/Core/SelectContent';
import StatusIcon from '@/components/Core/Statuses/StatusIcon';

import {
  CampaignType,
  OrderLineItemStatusIcons,
  OrderLineItemStatusKeys,
  OrderLineItemStatusWorkflow
} from '@/constants';

export default {
  components: {
    SelectContent,
    StatusIcon
  },
  props: {
    campaign: { type: Object, default: null },
    lineItem: {
      type: Object,
      required: true
    },
    loading: Boolean
  },

  data() {
    return {
      OrderLineItemStatusIcons
    };
  },

  computed: {
    allowedStatuses() {
      const statuses = OrderLineItemStatusWorkflow[this.lineItem.status] || [];

      if (this.lineItem.ads[0]?.is_ssp_booked) {
        return statuses.filter(s => s !== OrderLineItemStatusKeys.CANCELED);
      }
      return statuses;
    },
    paymentMethodIsCreditCard() {
      return (
        this.lineItem &&
        this.lineItem.order &&
        this.lineItem.order.paymentMethod &&
        this.lineItem.order.paymentMethod.is_credit_card
      );
    },
    canChangeStatus() {
      return (
        !this.paymentMethodIsCreditCard &&
        (this.isNational
          ? this.$can('do_anything')
          : this.$can('pub_change_line_item_status'))
      );
    },
    isNational() {
      return this.campaign && this.campaign.type === CampaignType.NATIONAL;
    }
  }
};
</script>
