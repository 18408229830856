<template>
  <div class="fulfillment" @click="$emit('edit', fulfillment)">
    <h4 class="text-dark-gray text-md">{{ fulfillment.name }}</h4>

    <div class="attributes text-sm text-dark-silver margin-top-xs">
      <div class="display-name">{{ fulfillment.display_name }}</div>

      <div class="price text-green font-bold">
        <template v-if="isFree">
          Free
        </template>
        <template v-else>{{ fulfillment.price | currency }}</template>
      </div>

      <div class="deadline">
        <template v-if="fulfillment.deadline_days">
          {{ fulfillment.deadline_days }} business days
        </template>
        <template v-else>
          No Deadline
        </template>
      </div>

      <div v-if="requirementList" class="flex">
        <div class="block py-3 pr-0 pl-3">Requires:</div>
        <div class="block py-3 pr-3 pl-2">{{ requirementList }}</div>
      </div>
    </div>

    <tooltip-icon
      v-if="deletable"
      class="remove absolute top-0 right-0 p-2"
      :is-disabled="isDisabled"
      :icon="trashEmpty"
      :icon-class="
        `text-red cursor-pointer ${isDisabled ? '' : 'hover:text-blue'}`
      "
      @click="$emit('remove')"
    />
  </div>
</template>

<script>
import { float } from '@/utils/filters';
import { trashEmpty } from '@/vendor/icons';
import TooltipIcon from '@/components/Core/TooltipIcon';

export default {
  name: 'FulfillmentMethod',
  components: {
    TooltipIcon
  },
  props: {
    fulfillment: {
      type: Object,
      required: true
    },
    isDisabled: Boolean,
    deletable: Boolean
  },

  data() {
    return { trashEmpty };
  },

  computed: {
    isFree() {
      return !float(this.fulfillment.price);
    },

    requirementList() {
      let list = [];

      if (this.fulfillment.requires_shipping) {
        list.push('Shipping');
      }

      if (this.fulfillment.requires_assets) {
        list.push('File Upload');
      }

      if (this.fulfillment.requires_text) {
        list.push('Text Copy');
      }

      if (this.fulfillment.requires_printing) {
        list.push('Supplier Printing');
      }

      return list.join(', ');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables';

.fulfillment {
  border: 1px solid $color-gray;
  cursor: pointer;
  display: inline-block;
  padding: 1em;
  border-radius: 5px;
  width: 15em;
  position: relative;

  .remove {
    opacity: 0;
    transition: all 0.3s;
  }

  &:hover {
    .remove {
      opacity: 1;
    }
  }
}
</style>
