<script>
import { union, xor } from 'lodash';
import { range } from '@/utils/helpers';

export default {
  data() {
    return {
      mostRecentIndex: 0,
      shiftSelectLimits: [],
      selections: [],
      tableData: []
    };
  },
  watch: {
    tableData() {
      this.resetSelections();
    }
  },
  methods: {
    onRowClick(selectByIdFn, row, event) {
      if (event.shiftKey) {
        this.shiftSelect(selectByIdFn, row);
      } else {
        this.singleSelect(selectByIdFn, row);
      }
    },
    onSelect(selectByIdsFn, selections, row) {
      this.selections = selections;
      const isSelected = !!this.selections.find(
        selection => selection.id === row.id
      );
      selectByIdsFn(isSelected, [row.id]);
    },
    onSelectAll(selectByFilterFn, selection) {
      const selectAll = selection.length !== 0;
      selectByFilterFn(selectAll, this.filter);
    },
    resetSelections() {
      this.selections = this.tableData.filter(tr => tr.is_selected);
      this.$nextTick(this.syncRowSelection);
    },
    shiftSelect(selectByIdFn, row) {
      // Index of the row being shift clicked
      const index = this.tableData.findIndex(tr => tr.id === row.id);
      // Current direction of the shift select range
      const direction = index < this.mostRecentIndex;
      // Selection state of the row being shift clicked
      const isSelected = !!this.selections.find(
        selection => selection.id === row.id
      );

      // Range of indices for the shift selections, and previous selections
      const shiftSelections = range(index, this.mostRecentIndex);
      const previousSelections = this.selections.map(selection =>
        this.tableData.findIndex(tr => tr.id === selection.id)
      );

      // Check for adjacent contiguous selections in opposite direction (and remove those)
      const adjacentContiguousSelections = range(
        this.mostRecentIndex + (direction ? 1 : -1),
        direction ? this.tableData.length - 1 : 0
      );
      const adjBreakIndex = adjacentContiguousSelections.findIndex(index => {
        return !this.selections.find(sel => sel === this.tableData[index]);
      });
      if (adjBreakIndex > -1)
        adjacentContiguousSelections.splice(adjBreakIndex);

      // Check for contiguous selections extending beyond the currently clicked row (and remove those)
      let extendedContiguousSelections = [];
      // We only need to perform this logic if the current row is selected
      // Otherwise it will look ahead and deselect selections beyond the shift select range
      if (isSelected) {
        extendedContiguousSelections = range(
          index + (direction ? -1 : 1),
          direction ? 0 : this.tableData.length - 1
        );
        const extBreakIndex = extendedContiguousSelections.findIndex(index => {
          return !this.selections.find(sel => sel === this.tableData[index]);
        });
        if (extBreakIndex > -1)
          extendedContiguousSelections.splice(extBreakIndex);
      }

      // Combined array of selections to be removed
      const selectionsToRemove = [
        ...adjacentContiguousSelections,
        ...extendedContiguousSelections
      ];

      // Resulting array of selections to remain selected or become selected
      const resultingSelections = xor(
        union(previousSelections, shiftSelections),
        selectionsToRemove
      );

      // Update the mixins selections array
      this.selections = resultingSelections.map(index => this.tableData[index]);

      // Toggle (on and off, respectively) the selections in the Element UI table
      selectionsToRemove.forEach(index => {
        this.$refs.table.toggleRowSelection(this.tableData[index], false);
      });
      this.selections.forEach(selection => {
        this.$refs.table.toggleRowSelection(selection, true);
      });

      // Call mutations (to select or deselect) on the respective arrays
      selectByIdFn(
        true,
        resultingSelections.map(index => this.tableData[index].id)
      );
      selectByIdFn(
        false,
        selectionsToRemove.map(index => this.tableData[index].id)
      );
    },
    singleSelect(selectByIdFn, row) {
      const index = this.tableData.findIndex(tr => tr.id === row.id);

      this.selections = xor(this.selections, [row]);
      const isSelected = !!this.selections.find(
        selection => selection.id === row.id
      );

      this.$refs.table.toggleRowSelection(row, isSelected);
      if (isSelected) this.mostRecentIndex = index;

      selectByIdFn(isSelected, [row.id]);
    },
    syncRowSelection() {
      for (let row of this.tableData) {
        this.$refs.table.toggleRowSelection(row, row.is_selected);
      }
    }
  }
};
</script>
