<template>
  <div v-if="stats" class="campaign-analytics-column text-left">
    <div v-if="isEnded" class="text-dark-gray leading-loose">
      Campaign Ended
    </div>
    <div v-else class="text-dark-gray leading-loose">Campaign Progress</div>

    <div v-if="isStarted" class="mt-1">
      <el-tooltip effect="light">
        <div slot="content" class="leading-relaxed">
          <div>{{ timeRemainingText }}</div>
          <div>
            {{ numberOfAdsLeftToRun }}
            {{ numberOfAdsLeftToRun === 1 ? 'ad' : 'ads' }} left to run
          </div>
        </div>

        <div class="flex items-center w-full">
          <el-progress
            class="flex-grow"
            :color="colors.green"
            :percentage="percentCompleted"
            text-inside
            :show-text="false"
            :stroke-width="10"
          />
          <div class="ml-3 text-size-default flex-shrink text-green font-bold">
            {{ percentCompleted | round(1) }}%
          </div>
        </div>
      </el-tooltip>
    </div>

    <div v-else class="time-remaining">{{ timeRemainingText }}</div>

    <el-popover class="ad-counts-box">
      <a slot="reference" class="mt-2 inline-block"
        >{{ stats.total_ads }} Ads</a
      >

      <div class="ad-counts">
        <div
          v-for="adCount in adCounts"
          :key="`ad-count-${adCount.key}`"
          class="ad-count p-1 cursor-pointer hover:text-very-light-gray whitespace-no-wrap"
        >
          <status-icon :icon="adCount.icon" inline />
          :
          <span class="count font-bold text-lg">{{ adCount.count }}</span>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import moment from 'moment';
import { AdStatus, AdStatusInfo, CampaignStatus } from '@/constants';
import { slug } from '@/utils/filters';
import { colors } from '@/tailwind';

import StatusIcon from '@/components/Core/Statuses/StatusIcon';

export default {
  components: { StatusIcon },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      colors
    };
  },

  computed: {
    stats() {
      return this.campaign.analytics;
    },

    adCounts() {
      let counts = [];

      for (let key of Object.keys(AdStatusInfo)) {
        let countKey = slug(key + '_count', '_');

        let count = this.stats[countKey];

        if (count > 0) {
          counts.push({
            key: key,
            icon: AdStatusInfo[key],
            count
          });
        }
      }

      return counts;
    },

    numberOfAdsLeftToRun() {
      return (
        this.stats['total_ads'] -
        this.stats[slug(AdStatus.CANCELED + '_count', '_')]
      );
    },

    isStarted() {
      return this.startDate.isBefore();
    },

    isEnded() {
      if (this.campaign.status === CampaignStatus.CANCELED) {
        return true;
      }

      return this.percentCompleted >= 100;
    },

    startDate() {
      return moment(this.campaign.start_date).startOf('day');
    },

    endDate() {
      return moment(this.campaign.end_date).startOf('day');
    },

    totalDays() {
      return this.endDate.diff(this.startDate, 'days') + 1;
    },

    daysFromStart() {
      return this.startDate.diff(moment().startOf('day'), 'days');
    },

    daysFromEnd() {
      return this.endDate.diff(moment().startOf('day'), 'days');
    },

    percentCompleted() {
      if (!this.daysFromStart || !this.totalDays) {
        return 0;
      }
      return Math.min(100, (100 * -1 * this.daysFromStart) / this.totalDays);
    },

    timeRemainingText() {
      if (this.daysFromStart > 0) {
        if (this.daysFromStart === 1) {
          return 'Starts tomorrow';
        } else {
          return `Starts in ${this.daysFromStart} days`;
        }
      } else {
        if (this.daysFromEnd > 0) {
          if (this.daysFromEnd === 1) {
            return 'Ends tomorrow';
          } else {
            return `${this.daysFromEnd} days remaining`;
          }
        } else if (this.daysFromEnd < 0) {
          if (this.daysFromEnd === -1) {
            return 'Ended yesterday';
          } else {
            return `Ended ${-1 * this.daysFromEnd} days ago`;
          }
        } else {
          return 'Ended today';
        }
      }
    }
  }
};
</script>
