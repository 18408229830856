<template>
  <section>
    <ads-mutation :supplier="supplier" :buyer="buyer" :mutating.sync="isSaving">
      <template
        v-slot="{
          acceptCreative,
          rejectCreative,
          setCreative,
          updateDownloadDate
        }"
      >
        <confirm-dialog
          class="p-0"
          disabled
          close-on-click-modal
          :title="'Fulfillment for Ad #' + ad.ref"
          :cancel-text="isSaving ? 'Saving...' : 'Close'"
          @close="onClose"
        >
          <div class="flex">
            <div class="flex-shrink flex flex-col">
              <creative-reviewer-sidebar
                class="flex-grow"
                :ad="ad"
                :buyer="buyer"
                :supplier="supplier"
                :is-loading="isSaving"
                @approve="acceptCreative(ad, $event)"
                @reject="
                  showRejectCreativeDialogForAccountType = $event || true
                "
              />

              <confirm-dialog
                v-if="showRejectCreativeDialogForAccountType"
                confirm-text="Reject"
                confirm-class="button-red"
                title="Reject Creative"
                :is-saving="isSaving"
                @close="showRejectCreativeDialogForAccountType = false"
                @confirm="rejectCreativeForAccountType(rejectCreative)"
              >
                <div class="font-bold">
                  Please explain why you are rejecting this creative:
                </div>
                <div class="mt-5">
                  <el-input v-model="rejectionNote" type="textarea" />
                </div>
              </confirm-dialog>
            </div>
            <div class="reviewer-box flex-grow p-5 overflow-y-auto pb-16">
              <ad-custom-form
                v-model="creativeInput.formResponse.fieldResponses"
                :form="ad.creativeForm"
                :is-disabled="isDisabled"
                :is-saving="isSaving"
                @uploading="isUploading = $event"
                @save="setCreative(ad, creativeInput)"
              />
            </div>
          </div>
        </confirm-dialog>
      </template>
    </ads-mutation>
  </section>
</template>

<script>
import { AccountType } from '@/constants';

import AdCustomForm from '@/components/Ads/AdCustomForm';
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import CreativeReviewerSidebar from '@/components/Supplier/Fulfillment/CreativeReviewerSidebar';
import { AdsMutation } from '@/components/Mutations';

export default {
  components: {
    AdCustomForm,
    AdsMutation,
    ConfirmDialog,
    CreativeReviewerSidebar
  },
  props: {
    ad: {
      type: Object,
      required: true
    },
    isDisabled: Boolean
  },
  data() {
    return {
      isSaving: false,
      isUploading: false,

      // Reject Creative
      showRejectCreativeDialogForAccountType: false,
      rejectionNote: '',

      // Form responses
      creativeInput: {
        formResponse: {
          form_id: this.ad.creativeForm.id,
          fieldResponses:
            (this.ad.creative &&
              this.ad.creative.formResponse &&
              this.ad.creative.formResponse.fieldResponses) ||
            []
        }
      }
    };
  },
  computed: {
    accountType() {
      return this.$store.getters['auth/accountType'];
    },
    buyer() {
      return this.accountType === AccountType.BUYER
        ? this.ad.buyer || this.$store.getters['auth/buyer']
        : this.ad.buyer;
    },
    supplier() {
      return this.accountType === AccountType.SUPPLIER
        ? this.ad.supplier || this.$store.getters['auth/supplier']
        : this.ad.supplier;
    },
    mediumType() {
      return this.ad.orderProduct.mediumType;
    }
  },
  methods: {
    onClose() {
      if (this.isSaving || this.isUploading) {
        return this.$message.warning(
          'Please wait until the creative has saved'
        );
      }

      this.$emit('close');
    },
    async rejectCreativeForAccountType(rejectCreative) {
      let accountType = this.showRejectCreativeDialogForAccountType;

      // If accountType === true, that means there was no accountType specified, so just leave that as null
      await rejectCreative(
        this.ad,
        this.rejectionNote,
        accountType === true ? null : accountType
      );

      this.showRejectCreativeDialogForAccountType = false;
      this.rejectionNote = '';
    }
  }
};
</script>
