<template>
  <transition name="fade" appear>
    <el-dialog
      :visible="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :title="'Verifications for #' + ad.ref"
      @update:visible="$emit('close')"
    >
      <div class="flex flex-wrap">
        <template v-if="hasVerifications">
          <form-response-preview :response="ad.verification.formResponse" />
        </template>
        <template v-else>
          No Verifications have been uploaded yet.
        </template>
      </div>

      <template slot="footer">
        <el-button class="button-white" @click="$emit('close')"
          >Close
        </el-button>
      </template>
    </el-dialog>
  </transition>
</template>

<script>
import FormResponsePreview from '@/components/Core/Form/FormBuilder/FormResponsePreview';

export default {
  components: {
    FormResponsePreview
  },
  props: {
    ad: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      input: false,
      isUploading: false
    };
  },

  computed: {
    hasVerifications() {
      return (
        this.ad.verification &&
        this.ad.verification.formResponse &&
        !this.ad.verification.formResponse.is_empty
      );
    }
  }
};
</script>
