<template>
  <div
    class="editable-document-item"
    :class="{
      [styleClass]: true,
      'cursor-pointer': editable,
      'hover:bg-silver': editable && !dashed,
      'dashed-outline': dashed,
      'dashed-outline-visible': editable && dashed
    }"
    @click="onClick"
  >
    <transition name="fade">
      <div v-if="editable" class="editable-before" @click="isEditing = true">
        <slot name="before" />
      </div>
    </transition>

    <div class="editable-content">
      <template v-if="select">
        <el-select
          ref="select"
          class="doc-item-select"
          v-bind="typeof select === 'object' ? select : {}"
          :popper-append-to-body="false"
          automatic-dropdown
          :value="value"
          @input="$emit('change', $event)"
        >
          <slot name="select-options" />
        </el-select>

        <slot>
          <div v-if="hasValue" class="item-value">{{ value | date }}</div>
          <div v-else-if="placeholder" class="value-placeholder">
            {{ placeholder }}
          </div>
        </slot>
      </template>
      <template v-else-if="datepicker">
        <el-date-picker
          ref="datepicker"
          :value="value"
          format="MM/dd/yyyy"
          :clearable="false"
          :append-to-body="false"
          class="doc-item-datepicker"
          @input="$emit('change', datetimeDb($event))"
        />

        <slot>
          <div v-if="hasValue" class="item-value">{{ value | date }}</div>
          <div v-else-if="placeholder" class="value-placeholder">
            {{ placeholder }}
          </div>
        </slot>
      </template>
      <template v-else-if="image">
        <div class="upload-image h-full w-full">
          <div class="placeholder-image">
            <slot><img :src="value" alt="Image"/></slot>
          </div>
          <upload-box
            class="upload-box"
            :disabled="!editable"
            transition-name="fade"
            :file-item="{
              width: 'auto',
              height: 'auto',
              alignLeft: true
            }"
            :multiple="false"
            :value="value"
            @complete="$emit('change', $event)"
          />
        </div>
      </template>
      <template v-else-if="textarea">
        <el-input
          class="doc-item-textarea w-full"
          :value="value"
          type="textarea"
          :disabled="!editable"
          v-bind="textareaProps"
          :resize="editable ? 'vertical' : 'none'"
          @input="$emit('change', $event)"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
        />
      </template>
      <template v-else-if="currency">
        <el-input
          v-mask.currency
          class="doc-item-input"
          :disabled="!editable"
          :value="value"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
          @change="$emit('change', $event)"
        />
      </template>
      <template v-else-if="popover">
        <el-popover>
          <div
            slot="reference"
            :style="{ opacity: editable ? 1 : 0 }"
            class="transition-all"
          >
            <slot name="reference">
              <icon :icon="pencilIcon" />
            </slot>
          </div>
          <slot />
        </el-popover>
      </template>
      <template v-else>
        <slot>
          <el-input
            class="doc-item-input"
            :value="value"
            :disabled="!editable"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
            @change="$emit('change', $event)"
          />
        </slot>
      </template>
    </div>

    <transition name="fade">
      <div v-if="editable" class="editable-after" @click="isEditing = true">
        <slot name="after">
          <div v-if="showIcon" class="flex">
            <div>
              <icon v-if="datepicker" :icon="calendarIcon" class="text-blue" />
              <icon v-else :icon="pencilIcon" class="text-blue" />
            </div>
            <div>
              <transition name="fade">
                <a
                  v-if="clearable && hasValue"
                  class="p-2 text-gray"
                  @click.stop.prevent="$emit('change', null)"
                >
                  <icon :icon="clearIcon" />
                </a>
              </transition>
            </div>
          </div>
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  calendar as calendarIcon,
  cancel as clearIcon,
  pencil as pencilIcon
} from '@/vendor/icons';
import { datetimeDb } from '@/utils/filters';
import UploadBox from '@/components/Core/UploadBox';

export default {
  components: { UploadBox },
  props: {
    showIcon: {
      type: Boolean,
      default: true
    },
    clearable: Boolean,
    editable: Boolean,
    datepicker: Boolean,
    image: Boolean,
    currency: Boolean,
    popover: Boolean,
    dashed: Boolean,
    placeholder: {
      type: String,
      default: null
    },
    styleClass: {
      type: String,
      default: 'inline-block relative w-full'
    },
    textarea: {
      type: [Boolean, Object],
      default: null
    },
    select: {
      type: [Boolean, Object],
      default: null
    },
    value: {
      type: [Number, String, Object, Array],
      default: null
    }
  },
  data() {
    return {
      isEditing: false,

      // Icons
      calendarIcon,
      clearIcon,
      pencilIcon
    };
  },
  computed: {
    hasValue() {
      return (
        this.value !== null &&
        this.value !== undefined &&
        this.value !== '' &&
        (!Array.isArray(this.value) || this.value.length > 0)
      );
    },
    textareaProps() {
      const props = {
        rows: 10,
        ...(typeof this.textarea === 'object' ? this.textarea : {})
      };

      // Hide the placeholder text when not editable
      props.placeholder = this.editable ? this.textarea.placeholder : '';

      return props;
    }
  },
  methods: {
    datetimeDb,
    onClick() {
      if (this.editable) {
        this.isEditing = true;

        if (this.select) {
          this.$refs.select.focus();
        } else if (this.datepicker) {
          this.$refs.datepicker.focus();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables.scss';

.doc-item-datepicker,
.doc-item-select {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  top: 1em;
}

.editable-content {
  height: 100%;
  width: 100%;
}

.placeholder-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
}

.upload-box {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 0;
  border-radius: 0;
  display: block;
  box-sizing: border-box;
  border: none;
  transition: opacity 0.5s;
  text-align: left;
  z-index: 6;

  &.is-disabled {
    opacity: 0;
  }

  /deep/ {
    .el-upload-container {
      height: 100%;
      width: 100%;

      .el-upload-dragger {
        border-radius: 0;
      }

      .uploaded-file-list {
        height: 100%;
        width: 100%;
        padding: 0 !important;

        .animate-position {
          margin: 0;
          height: 100%;
          width: 100%;

          .block.p-3 {
            padding: 0;
            height: 100%;
            width: 100%;

            .file-item {
              height: 100% !important;
              width: 100% !important;
              text-align: left;

              .is-overlay {
                transition: opacity 0.3s;

                .action-bar {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100%;

                  .flex-1 {
                    flex: auto;

                    .justify-end {
                      justify-content: flex-start;
                      font-size: 2em;
                    }
                  }
                }
              }

              img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.doc-item-textarea /deep/ {
  textarea {
    border: none;
    color: $color-dark-gray;
  }

  &.is-disabled .el-textarea__inner {
    background: white;
    cursor: default;
    color: $color-dark-gray;
  }
}

.dashed-outline {
  outline: dashed 2px transparent;
  transition: outline 0.3s;
}

.dashed-outline-visible {
  outline-color: $color-silver;
}

.doc-item-select /deep/ {
  .el-input {
    height: 1px;
  }
}

.doc-item-input /deep/ {
  .el-input__inner {
    height: 2em;
    border: none;
    color: $color-dark-gray;
  }

  &.is-disabled .el-input__inner {
    background: white;
    cursor: default;
    color: $color-dark-gray;
  }
}

.value-placeholder {
  color: $color-dark-silver;
}

.editable-content {
  outline: none;
}

.editable-after {
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 0.5em;
}
</style>

<style lang="scss">
@media print {
  .editable-before,
  .editable-after {
    display: none;
  }

  .editable-content {
    outline: 0;
  }

  .editable-document-item {
    background: none !important;
  }
}
</style>
