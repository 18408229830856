<template>
  <div class="select-content is-clickable">
    <el-dropdown
      :is="disabled ? 'div' : 'el-dropdown'"
      :placement="placement"
      :trigger="trigger"
      @command="change"
    >
      <div class="selected-content">
        <slot>{{ value }}</slot>
      </div>

      <el-dropdown-menu v-if="items.length > 0" slot="dropdown">
        <el-dropdown-item
          v-for="item in items"
          :key="`select-content-${item}`"
          :command="item"
        >
          <slot name="option" :status="item">{{ item }}</slot>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { chevronUp } from '@/vendor/icons';

export default {
  name: 'SelectContent',
  props: {
    value: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    trigger: {
      type: String,
      default: 'click'
    },
    items: {
      type: Array,
      required: true
    },
    disabled: Boolean
  },

  data() {
    return {
      // Icons
      chevronUp
    };
  },

  methods: {
    change(item) {
      this.$emit('input', item);
      this.$emit('change', item);
    }
  }
};
</script>
