<script>
import axios from 'axios';
import { downloadFileResponse, renderless } from '@/utils/helpers';

export default {
  data() {
    return {
      isExporting: false
    };
  },
  methods: {
    pdfUrl(invoice) {
      return this.$apiUrl + `/invoice/${invoice.id}/pdf`;
    },
    async exportInvoiceToPdf(invoice) {
      this.isExporting = true;
      this.$emit('update:is-exporting', this.isExporting);

      this.$message.success(
        'Your PDF Invoice is being generated and will be downloaded shortly. Please wait for a moment while we get it ready for you!'
      );

      const response = await axios.get(this.pdfUrl(invoice), {
        responseType: 'arraybuffer'
      });

      let jsonResponse = null;

      try {
        jsonResponse = JSON.parse(new TextDecoder().decode(response.data));

        if (jsonResponse) {
          console.error(jsonResponse);
          if (jsonResponse.errors) {
            this.$message.error(jsonResponse.errors[0].message);
          } else {
            this.message.error(
              'There was an error exporting the invoice to PDF. Please try again later.'
            );
          }
        }
      } catch (e) {
        // we expect an error thrown for invalid JSON when the response is a file
      }

      if (jsonResponse) {
        this.$message.error(jsonResponse.message);
      } else {
        downloadFileResponse(response);
      }

      this.isExporting = false;
      this.$emit('update:is-exporting', this.isExporting);
    }
  },
  render(c) {
    return renderless.call(this, c, {
      exportInvoice: this.exportInvoiceToPdf,
      isExporting: this.isExporting
    });
  }
};
</script>
