<template>
  <io-column :campaign="campaign" :order="order" :show-status="showStatus">
    <template slot="reviewing">
      <el-tooltip effect="light" placement="right">
        <io-item
          show-campaign-ref
          :campaign="campaign"
          :order="order"
          icon-class="text-yellow hover:text-dark-yellow"
        />

        <div slot="content" class="text-lg p-3">
          <div class="font-bold">Pending Publisher Approval</div>
          <div class="mt-3">
            Publisher must approve campaign and payment settings.
          </div>
        </div>
      </el-tooltip>
    </template>

    <template slot="changes">
      <el-popover placement="right" trigger="hover" width="250">
        <div>
          <div class="font-bold">Approve Campaign</div>
          <div>Review changes made by publisher to activate this campaign.</div>
          <div class="mt-3 flex">
            <el-button
              class="w-40 button-blue"
              size="mini"
              @click="$emit('review')"
            >
              Review
            </el-button>
          </div>
        </div>

        <io-item
          slot="reference"
          show-campaign-ref
          :campaign="campaign"
          :order="order"
          icon-class="text-red hover:text-dark-red"
        />
      </el-popover>
    </template>
  </io-column>
</template>

<script>
import IoItem from '@/components/Order/IoItem';
import IoColumn from '@/components/Core/Table/Columns/IoColumn';

export default {
  components: { IoColumn, IoItem },
  props: {
    // TODO For National we will need to refactor this to better handle showing a Buyer's full campaign (ie: Multiple orders)
    // Not just the Campaign ID
    campaign: { type: Object, default: null },
    order: {
      type: Object,
      required: true
    },
    showStatus: Boolean
  }
};
</script>
