<script>
import { getCampaignInvoicedAds } from '@/graphql/campaign/queries.graphql';

import { QueryMixin } from '@/components/Core/Helpers';
import { CampaignStatusInfo } from '@/constants';

export default {
  mixins: [QueryMixin],
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },

  query() {
    return {
      query: getCampaignInvoicedAds,
      variables: {
        campaign_id: this.campaign.id,
        buyer_id: this.$store.getters['auth/buyerId'],
        filter: {
          status: {
            '!=': CampaignStatusInfo.CANCELED.name
          }
        }
      }
    };
  }
};
</script>
