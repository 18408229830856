<script>
import {
  createInvoiceLineItem,
  deleteInvoice,
  exportSupplierInvoices,
  invoiceAllowCreditCardPayment,
  invoicesSelectByFilter,
  invoicesSelectByIds,
  makeCreditCardPaymentToInvoice,
  removeInvoiceLineItem,
  supplierUpdateInvoice,
  unlockInvoice,
  updateInvoiceLineItem,
  voidInvoice
} from '@/graphql/billing/supplierInvoiceDocumentMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';
import { datetimeDb } from '@/utils/filters';
import { remove } from '@/utils/helpers';
import { findIndex } from 'lodash';
import { InvoiceStatusKeys } from '@/constants/invoice';

export default {
  mixins: [MutationHelper],

  props: {
    invoiceData: {
      type: Object,
      default: null
    },
    paginatorInfo: {
      type: Object,
      default: () => {
        return {
          total: 0
        };
      }
    }
  },

  mutation() {
    return {
      mutations: {
        /**
         * Updates an Invoice Document
         *
         * @param invoice
         * @param input
         */
        updateInvoice(invoice, input) {
          if (input.logo) {
            input.logo.__typename = 'File';
          }

          this.optimisticResponse = {
            mutateSupplier: {
              __typename: 'SupplierMutation',
              mutateInvoice: {
                __typename: 'InvoiceMutation',
                update: true,
                invoice: {
                  __typename: 'InvoiceDocument',
                  ...invoice,
                  ...input
                }
              }
            }
          };

          if (input.logo !== undefined) {
            input.logo_id = input.logo ? input.logo.id : null;
            delete input.logo;
          }

          if (input.terms) {
            input.terms_id = input.terms.id;
            delete input.terms;
          }

          return this.post(supplierUpdateInvoice, { id: invoice.id, input });
        },
        markInvoiceSent(invoice, input) {
          return this.post(supplierUpdateInvoice, {
            id: invoice.id,
            input: { status: InvoiceStatusKeys.SENT, ...(input || {}) }
          });
        },
        unlockInvoice(invoice) {
          return this.post(unlockInvoice, {
            id: invoice.id,
            ...this.pager
          });
        },
        createLineItem(invoice, input) {
          const documentLineItems = invoice.documentLineItems;

          documentLineItems.push({
            __typename: 'DocumentLineItem',
            sku: '',
            end_date: null,
            description: '',
            created_at: datetimeDb(),
            updated_at: datetimeDb(),
            ...input
          });

          this.optimisticResponse = {
            mutateSupplier: {
              __typename: 'SupplierMutation',
              mutateInvoice: {
                __typename: 'InvoiceMutation',
                createLineItem: true,
                invoice: {
                  __typename: 'InvoiceDocument',
                  ...invoice,
                  documentLineItems
                }
              }
            }
          };

          return this.post(createInvoiceLineItem, { id: invoice.id, input });
        },
        updateLineItem(invoice, lineItemId, input) {
          const documentLineItems = invoice.documentLineItems;

          const index = findIndex(documentLineItems, { id: lineItemId });

          documentLineItems[index] = {
            ...documentLineItems[index],
            ...input
          };

          this.optimisticResponse = {
            mutateSupplier: {
              __typename: 'SupplierMutation',
              mutateInvoice: {
                __typename: 'InvoiceMutation',
                updateLineItem: true,
                invoice: {
                  __typename: 'InvoiceDocument',
                  ...invoice,
                  documentLineItems
                }
              }
            }
          };

          return this.post(updateInvoiceLineItem, {
            invoiceId: invoice.id,
            lineItemId,
            input
          });
        },
        removeLineItem(invoice, lineItemId) {
          const documentLineItems = invoice.documentLineItems;

          remove(documentLineItems, { id: lineItemId });

          this.optimisticResponse = {
            mutateSupplier: {
              __typename: 'SupplierMutation',
              mutateInvoice: {
                __typename: 'InvoiceMutation',
                removeLineItem: true,
                invoice: {
                  __typename: 'InvoiceDocument',
                  ...invoice,
                  documentLineItems
                }
              }
            }
          };

          return this.post(removeInvoiceLineItem, {
            invoiceId: invoice.id,
            lineItemId
          });
        },
        selectByFilter(select, filter) {
          let totalSelected = Math.min(
            this.paginatorInfo.total,
            (this.invoiceData.selectionSummary
              ? this.invoiceData.selectionSummary.total_selected
              : 0) +
              (select ? this.paginatorInfo.total : -this.paginatorInfo.total)
          );

          this.setSelectionOptimisticResponse(totalSelected);

          filter = filter || this.pager.filter;

          return this.post(invoicesSelectByFilter, { select, filter });
        },
        selectByIds(select, ids) {
          let totalSelected =
            this.invoiceData.selectionSummary.total_selected +
            (select ? ids.length : -ids.length);

          this.setSelectionOptimisticResponse(totalSelected);

          return this.post(invoicesSelectByIds, {
            ids,
            select
          });
        },
        clearSelections() {
          return this.post(invoicesSelectByFilter, {
            filter: {},
            select: false
          });
        },
        exportInvoices(filter) {
          return this.post(exportSupplierInvoices, { filter });
        },
        voidInvoice(invoice) {
          this.refetchCache('getSupplierLineItems');

          return this.post(voidInvoice, { id: invoice.id });
        },
        deleteInvoice(invoice) {
          this.refetchCache('getSupplierLineItems');

          return this.post(deleteInvoice, {
            id: invoice.id,
            ...this.pager
          });
        },
        makeCreditCardPayment(invoice, stripeToken) {
          return this.post(makeCreditCardPaymentToInvoice, {
            id: invoice.id,
            input: { stripeToken }
          });
        },
        invoiceAllowCreditCardPayment(invoice, allow_credit_card_payment) {
          return this.post(invoiceAllowCreditCardPayment, {
            id: invoice.id,
            input: { allow_credit_card_payment }
          });
        }
      },
      variables: {
        supplier_id: this.supplierId || this.$store.getters['auth/supplierId']
      }
    };
  },

  computed: {
    pager() {
      return this.$store.getters['invoices/pager'];
    }
  },

  methods: {
    setSelectionOptimisticResponse(total) {
      const invoiceData = this.invoiceData;

      invoiceData.selectionSummary.total_selected = total;
      invoiceData.__typename = 'InvoiceData';

      this.optimisticResponse = {
        mutateSupplier: {
          mutateInvoice: {
            selectByIds: [],
            selectByFilter: [],
            __typename: 'SupplierInvoiceDocumentMutation'
          },
          supplier: {
            id: this.supplierId || this.$store.getters['auth/supplierId'],
            invoiceData,
            __typename: 'Supplier'
          },
          __typename: 'SupplierMutation'
        }
      };
    }
  }
};
</script>
