<script>
import { deleteNote, editNote } from '@/graphql/common/noteMutations.graphql';
import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],
  mutation() {
    return {
      mutations: {
        editNote(note, input) {
          return this.post(editNote, { id: note.id, input });
        },
        deleteNote(note) {
          return this.post(deleteNote, { id: note.id });
        }
      }
    };
  }
};
</script>
