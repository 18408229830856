<template>
  <div
    class="min-max-price"
    :class="{ 'text-md': minRate && minRate.minPrice > 1000 }"
  >
    <template v-if="minRate">
      <div>
        {{ minRate.minPrice | currency }}
        <template v-if="showMax && minRate.maxPrice > minRate.minPrice">
          to {{ minRate.maxPrice | currency }}
        </template>
        <template v-else-if="rateCount > 1">+</template>

        <template v-if="isPerQuantity"> per {{ minRate.quantity }}</template>
        <template v-else-if="isPerInterval">
          per Interval ({{ quantityLimit.increment }} days)
        </template>
      </div>
      <div v-if="rateCount > 1">({{ rateCount }} rates)</div>
    </template>
    <template v-else>
      <div class="text-dark-silver font-normal hover-low-opacity">
        <i>Enter Rate(s)</i>
      </div>
    </template>
  </div>
</template>
<script>
import { RateType } from '@/constants';

export default {
  props: {
    collection: {
      type: Object,
      default: null
    },
    rateSheets: {
      type: Array,
      required: true
    },
    showMax: Boolean
  },

  computed: {
    quantityLimit() {
      return (
        this.collection &&
        this.collection.schedule &&
        this.collection.schedule.quantityLimit
      );
    },

    minRate() {
      let minRate = null;

      for (let rateSheet of this.rateSheets) {
        let allow0 = rateSheet.type !== RateType.IMPRESSIONS.value;

        let minPrice = rateSheet.rates.reduce(
          (acc, r) =>
            r.price > 0 ? Math.min(r.price, acc) : allow0 ? r.price : Infinity,
          Infinity
        );

        let maxPrice = rateSheet.rates.reduce(
          (acc, r) => Math.max(r.price, acc),
          0
        );

        if (!minRate || minPrice < minRate.minPrice) {
          minRate = {
            ...rateSheet,
            minPrice,
            maxPrice
          };
        }
      }

      return minRate;
    },

    rateCount() {
      return this.rateSheets.length;
    },
    isPerInterval() {
      return this.minRate.type === RateType.PER_INTERVAL;
    },
    isPerQuantity() {
      return this.minRate.type === RateType.PER_QUANTITY;
    }
  }
};
</script>
