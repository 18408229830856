<template>
  <div class="fulfillment-due-column">
    <div class="font-semibold" :class="{ 'text-red': isDueSoon }">
      {{ ad.deadline_date | date }}
    </div>
    <div v-if="fulfillment" class="icon-list mt-2">
      <icon
        v-if="fulfillment.requires_assets"
        :icon="fileImage"
        class="icon-list-item"
      />
      <icon
        v-if="fulfillment.requires_text"
        :icon="type"
        class="icon-list-item"
      />
      <icon
        v-if="fulfillment.requires_printing"
        :icon="mail"
        class="icon-list-item"
      />
      <icon
        v-if="fulfillment.requires_shipping"
        :icon="printer"
        class="icon-list-item"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import { fileImage, mail, printer, type } from '@/vendor/icons';

export default {
  props: {
    ad: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      // Icons
      fileImage,
      mail,
      printer,
      type
    };
  },

  computed: {
    fulfillment() {
      return this.ad.fulfillment_method;
    },

    isDueSoon() {
      // Due soon if the deadline is before tomorrow
      return moment(this.ad.deadline_date).isBefore(
        moment()
          .add(1, 'day')
          .startOf('day')
      );
    }
  }
};
</script>
