<template>
  <el-form
    ref="paymentForm"
    class="record-payment-form text-dark-gray"
    :model="input"
    :rules="rules"
  >
    <div class="flex -mx-3 -mt-3 mb-3">
      <div class="block p-3 flex-1">
        <custom-el-form-item
          label="Amount"
          label-class="font-semibold"
          prop="amount"
        >
          <el-input
            v-model="input.amount"
            v-mask.currency="{ min: 0, max: maxAmount }"
            class="w-full"
          />
        </custom-el-form-item>
      </div>

      <div class="block p-3 flex-1">
        <custom-el-form-item
          label="Date Paid"
          label-class="font-semibold"
          prop="payment_date"
        >
          <el-date-picker
            v-model="input.payment_date"
            format="MM/dd/yyyy"
            placeholder="Pending Payment"
          />
        </custom-el-form-item>
      </div>

      <div class="block p-3 flex-1">
        <custom-el-form-item
          label="Payment Type"
          label-class="font-semibold"
          prop="paymentMethod"
        >
          <el-select
            v-model="input.paymentMethod"
            filterable
            allow-create
            value-key="id"
            class="w-full"
          >
            <el-option
              v-for="paymentMethod in paymentMethods"
              :key="`payment-method-${paymentMethod.id}`"
              :value="paymentMethod"
              :label="paymentMethod.name"
            />
          </el-select>
        </custom-el-form-item>
      </div>
    </div>

    <custom-el-form-item
      label="Notes"
      label-class="font-semibold"
      class="margin-top"
    >
      <el-input
        v-model="input.description"
        type="textarea"
        placeholder="Enter any Payment notes..."
      />
    </custom-el-form-item>

    <!-- Additional Fields / Submit Button -->
    <slot>
      <div class="mt-5">
        <el-button v-if="!isSaving" class="button-blue w-full" @click="save">
          {{ paymentText }}
        </el-button>
        <loading-button v-else class="w-full" />
      </div>
    </slot>
  </el-form>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import moment from 'moment';
import { dateDb, float } from '@/utils/filters';

export default {
  components: {
    CustomElFormItem
  },
  props: {
    paymentText: {
      type: String,
      default: 'Record Payment'
    },
    isSaving: Boolean,
    maxAmount: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      float,
      input: {
        payment_date: moment(),
        amount: this.maxAmount || 0,
        paymentMethod: this.$store.getters['auth/supplier'].paymentMethods[0],
        description: ''
      },
      rules: {
        payment_date: {
          required: true,
          trigger: 'blur',
          message: `Payment Date is required`
        },
        amount: {
          required: true,
          trigger: 'blur',
          message: `Paid Amount is required`
        },
        paymentMethod: {
          required: true,
          trigger: 'blur',
          message: `Payment Method is required`
        }
      }
    };
  },
  computed: {
    paymentMethods() {
      return this.$store.getters['auth/supplier'].paymentMethods;
    },
    // Resolves the input data structure for either a New or Existing Payment
    paymentMethodInput() {
      if (typeof this.input.paymentMethod === 'string') {
        return { name: this.input.paymentMethod };
      } else {
        return { id: this.input.paymentMethod.id };
      }
    }
  },
  watch: {
    input: {
      deep: true,
      handler() {
        this.$emit('input', this.input);
      }
    }
  },
  methods: {
    save() {
      this.$refs['paymentForm'].validate(valid => {
        if (valid) {
          this.$emit('save', {
            ...this.input,
            payment_date: dateDb(this.input.payment_date),
            paymentMethod: this.paymentMethodInput
          });

          // Reset the Form
          if (this.maxAmount) {
            const paidAmount = +this.input.amount.replace(/[,$]/, '');
            this.input.amount = this.maxAmount - paidAmount;
          } else {
            this.input.amount = 0;
          }
        }
      });
    }
  }
};
</script>
