<template>
  <div class="stripe-card-element">
    <div ref="cardDiv" class="stripe-card-rendered">
      <!-- A Stripe Element will be inserted here. -->
    </div>

    <el-alert
      v-if="error"
      class="mt-3"
      :title="error"
      type="error"
      :closable="false"
    />
  </div>
</template>

<script>
import stripeLoader from '@/vendor/stripe';

export default {
  data() {
    return {
      cardElement: null,
      error: null
    };
  },

  beforeDestroy() {
    this.cardElement.destroy();
  },

  async mounted() {
    const Stripe = await stripeLoader();

    this.cardElement = Stripe.elements().create('card', {
      hidePostalCode: true
    });

    // Add an instance of the card Element into the `card-element` <div>.
    this.cardElement.mount(this.$refs.cardDiv);

    this.cardElement.addEventListener('change', ({ error }) => {
      this.cardError = error ? error.message : null;
    });

    this.$emit('card', this.cardElement);
  }
};
</script>

<style lang="scss">
@import '~@/scss/_variables';

.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid $color-gray;
  box-shadow: none;
  transition: border 0.3s;
}

.StripeElement--focus {
  border-color: $color-blue;
}

.StripeElement--invalid {
  border-color: $color-red;
}
</style>
