<template>
  <div class="address-format">
    <div v-if="address.attn" class="address-attn">Attn: {{ address.attn }}</div>
    <div v-else-if="address.name" class="address-name">{{ address.name }}</div>
    <template v-if="address.address">
      <div class="address-line-1">
        {{ address.address }}
      </div>
      <div v-if="address.address2" class="address-line-2">
        {{ address.address2 }}
      </div>
      <div v-if="address.address3" class="address-line-3">
        {{ address.address3 }}
      </div>
      <div class="address-city-state-zip">
        <template v-if="address.city">{{ address.city }},</template>
        {{ address.state }} {{ address.zip_code }}
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    address: {
      type: Object,
      required: true
    }
  }
};
</script>
