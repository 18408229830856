var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative",style:({ width: _vm.actualWidth, height: _vm.actualHeight })},[_c('transition',{attrs:{"name":"fade"}},[(!_vm.isLoaded)?_c('loader-box',{style:({ 'z-index': 1000 }),attrs:{"width":_vm.actualLoaderWidth,"height":_vm.actualLoaderHeight,"loader-class":"m-auto"}}):_vm._e()],1),_c('iframe',{ref:"iframe",style:({
      border: 'none',
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: _vm.isLoaded ? 1 : 0,
      transition: 'all 1s'
    }),attrs:{"src":_vm.src,"frameborder":"0","scrolling":"no","width":_vm.actualWidth,"height":_vm.actualHeight}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }