<template>
  <div class="supplier-verification-column has-content-centered">
    <template v-if="hasVerifications">
      <form-response-preview
        :response="ad.verification.formResponse"
        @click="showDialog = true"
      />

      <div class="margin-top-xs">
        <a class="font-bold" @click="showDialog = true">
          Review Verifications
        </a>
      </div>
    </template>

    <template v-else-if="isNational">
      <el-popover
        trigger="hover"
        placement="right"
        class="flex flex-1 justify-center w-full"
      >
        <div class="text-left w-64">
          <div class="font-bold pb-2">
            Flytedesk National Verifications
          </div>
          Verification uploads are required within 3 days of the ad's run date
          in order for payment to be sent.
          <a
            href="https://intercom.help/flytedesk/en/articles/3338520-verifying-national-ads"
            target="_blank"
            >Learn more about verification requirements.</a
          >
        </div>
        <div slot="reference" class="h-32 w-full mx-4">
          <div>Due: {{ dueDate }}</div>
          <tooltip-icon
            reference-class="p-4 cursor-pointer text-blue hover:text-dark-blue"
            :is-disabled="!$can('pub_manage_verifications')"
            :icon="plus"
            @click="showDialog = true"
          />
        </div>
      </el-popover>
    </template>
    <template v-else>
      <tooltip-icon
        reference-class="p-4 cursor-pointer text-blue hover:text-dark-blue"
        :is-disabled="!$can('pub_manage_verifications')"
        :icon="plus"
        @click="showDialog = true"
      />
    </template>

    <verification-dialog
      v-if="showDialog || startOpen"
      is-supplier
      :ad="ad"
      @close="
        showDialog = false;
        $emit('close', true);
      "
    />
  </div>
</template>

<script>
import VerificationDialog from '@/components/Core/Dialogs/VerificationDialog';

import FormResponsePreview from '@/components/Core/Form/FormBuilder/FormResponsePreview';
import TooltipIcon from '@/components/Core/TooltipIcon';
import { CampaignType } from '@/constants';
import { DATE_FORMAT_SHORT_US } from '@/utils/date';
import { plus } from '@/vendor/icons';
import moment from 'moment';

export default {
  components: {
    FormResponsePreview,
    TooltipIcon,
    VerificationDialog
  },
  props: {
    ad: {
      type: Object,
      required: true
    },
    startOpen: Boolean
  },

  data() {
    return {
      showDialog: false,

      // Icons
      plus
    };
  },

  computed: {
    dueDate() {
      return moment(this.ad.start_date)
        .businessAdd(3)
        .format(DATE_FORMAT_SHORT_US);
    },
    isNational() {
      return (
        this.ad.campaign && this.ad.campaign.type === CampaignType.NATIONAL
      );
    },
    hasVerifications() {
      return !!(
        this.ad.verification &&
        this.ad.verification.formResponse &&
        !this.ad.verification.formResponse.is_empty
      );
    }
  }
};
</script>
