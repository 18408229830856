<template>
  <div class="flex flex-col border-right min-w-15 p-8">
    <div class="py-4 border-bottom border-very-light-gray">
      <h4 class="font-semibold mb-1">
        Ad Details
      </h4>
      <ad-order-product-info :ad="ad" />
      <div>{{ date(ad.start_date) }}</div>
    </div>

    <div
      v-if="
        $store.getters['auth/isNationalBuyer'] || $store.getters['auth/isAdmin']
      "
      class="py-4 border-bottom border-very-light-gray"
    >
      <h4 class="font-semibold mb-1">
        Campus
      </h4>
      <div>{{ ad.campus.name }}</div>
      <div>{{ ad.campus.state }}</div>
      <div v-if="$store.getters['auth/isAdmin']" class="mt-3">
        <div>
          <a class="flex items-center" @click="$emit('location-map')">
            <location-pin class="mr-2" />
            View Location(s)
          </a>
        </div>
        <div class="mt-1">
          <a :href="verificationGalleryUrl" target="_blank">View Gallery</a>
        </div>
        <div class="mt-1">
          <a :href="verificationGalleryUrl + '/zip'" download target="_blank">
            Download All Files
          </a>
        </div>
      </div>
    </div>

    <div
      v-if="$store.getters['auth/isSupplier'] || $store.getters['auth/isAdmin']"
      class="py-4 border-bottom border-very-light-gray"
    >
      <h4 class="font-semibold mb-1">
        Customer
      </h4>
      <div>{{ ad.buyer.buyer_company }}</div>
    </div>

    <div
      v-if="
        ($store.getters['auth/isSupplier'] || $store.getters['auth/isAdmin']) &&
          isNational
      "
      class="py-4"
    >
      <h4 class="font-semibold mb-1">
        Approvals
      </h4>
      <div class="flex items-center mt-4">
        <icon
          :icon="AdStatusInfo[ad.status].icon"
          :class="AdStatusInfo[ad.status].class"
          class="mr-2"
        />
        Flytedesk
      </div>
      <div class="ml-6" :class="AdStatusInfo[ad.status].class">
        {{ AdStatusInfo[ad.status].name }}
      </div>
    </div>

    <div v-if="rejectionNote" class="border-top border-very-light-gray py-4">
      <div class="font-bold">Rejection Reason</div>
      <div v-if="rejectionNote.text">{{ rejectionNote.text }}</div>
      <div v-else class="text-dark-silver italic">No Reason Given</div>
    </div>

    <div v-if="canReview" class="flex flex-col mt-2">
      <loading-button v-if="isSaving" class="w-full" />
      <template v-else>
        <el-button
          v-if="!isVerified"
          class="button-green w-full"
          @click="$emit('approve')"
        >
          Approve
        </el-button>
        <el-button
          v-if="!isRejected"
          class="button-white-red w-full mt-2"
          @click="$emit('reject')"
        >
          Reject
        </el-button>
      </template>
    </div>
    <div v-else-if="!hasRunDatePassed" class="mt-2 text-red w-40">
      You cannot approve a verification until after the run date.
    </div>
  </div>
</template>

<script>
import { AdStatus, AdStatusInfo, CampaignType } from '@/constants';
import { LoadingButton } from '@/components/Core/Loading';

import moment from 'moment';
import LocationPin from '@/components/Svg/LocationPin';
import AdOrderProductInfo from '@/components/Ads/AdOrderProductInfo';

export default {
  components: {
    AdOrderProductInfo,
    LocationPin,
    LoadingButton
  },
  props: {
    ad: {
      type: Object,
      required: true
    },
    isSaving: Boolean
  },
  data() {
    return {
      AdStatusInfo
    };
  },
  computed: {
    supplier() {
      return this.ad.supplier || this.$store.getters['auth/supplier'];
    },
    canReview() {
      return (
        this.$can('approve_verifications') &&
        this.isNational &&
        this.hasRunDatePassed
      );
    },
    hasRunDatePassed() {
      return moment(this.ad.start_date).isBefore();
    },
    isNational() {
      return (
        this.ad.campaign && this.ad.campaign.type === CampaignType.NATIONAL
      );
    },
    isRejected() {
      return this.ad.status === AdStatus.VERIFICATION_REJECTED;
    },
    isVerified() {
      return this.ad.status === AdStatus.VERIFIED;
    },
    rejectionNote() {
      return this.ad.verification && this.ad.verification.rejectionNote;
    },
    verificationGalleryUrl() {
      return `${window._env.API_URL}/ad/${this.ad.id}/verification/gallery`;
    }
  },
  methods: {
    date(date) {
      return moment(date).format('MM/DD/YYYY');
    }
  }
};
</script>
