<template>
  <div class="bulk-invoice-dialog">
    <confirm-dialog title="Bulk Invoicing" @close="$emit('close')">
      <iframe-v4
        v-model="isLoaded"
        :src="url"
        height="500"
        loader-width="400"
        @close="$emit('close')"
      />

      <template #footer>
        <div></div>
      </template>
    </confirm-dialog>
  </div>
</template>
<script>
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import IframeV4 from '@/components/Core/IframeV4';

export default {
  components: { IframeV4, ConfirmDialog },
  props: {},
  data() {
    return {
      isLoaded: false,
      input: {
        start_date: '',
        end_date: '',
        transaction_date: ''
      }
    };
  },
  computed: {
    url() {
      return process.env.VUE_APP_V4_URL + '/v4/iframe/bulk-invoice';
    }
  },
  methods: {}
};
</script>
