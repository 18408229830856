<template>
  <section>
    <ads-mutation
      v-if="isSupplier"
      :mutating.sync="isSavingSupplier"
      @mounted="setSupplierMutations"
    />

    <campaign-tracker-mutation
      :mutating.sync="isSavingCT"
      @mounted="setMutations"
    />

    <confirm-dialog
      disabled
      class="p-0"
      close-on-click-modal
      :title="`Verification for Ad #${ad.ref}`"
      :cancel-text="isSaving ? 'Saving...' : 'Close'"
      @close="onClose"
    >
      <div class="flex">
        <verification-dialog-sidebar
          :ad="ad"
          :is-saving="isSaving"
          @approve="mutations.approveVerification(ad)"
          @reject="rejectConfirmDialogVisible = true"
          @location-map="isShowingMap = true"
        />

        <div class="flex flex-1 flex-col p-8 overflow-hidden">
          <div class="px-3 pb-3 border-bottom mb-3">
            <h2>Creative</h2>
            <form-response-preview
              :response="ad.creative.formResponse"
              spread-files
              enable-preview
              :max-files="50"
            />
          </div>
          <transition name="fadeLeft" mode="out-in">
            <verification-map
              v-if="isShowingMap"
              :files="ad.verification.formResponse.images || []"
              class="min-h-50"
              @close="isShowingMap = false"
            />
            <ad-custom-form
              v-else
              v-model="verificationInput.formResponse.fieldResponses"
              :form="ad.verificationForm"
              :is-saving="isSaving"
              @uploading="isUploading = $event"
              @save="mutations.saveVerification(ad, verificationInput)"
            />
          </transition>
        </div>
      </div>
    </confirm-dialog>

    <confirm-dialog
      v-if="rejectConfirmDialogVisible"
      confirm-class="button-red"
      confirm-text="Reject"
      width="40em"
      :is-saving="isSaving"
      @confirm="rejectVerification(ad, rejectForm.reject)"
      @close="rejectConfirmDialogVisible = false"
    >
      <el-form ref="rejectForm" class="mt-4" :disabled="isSaving">
        <custom-el-form-item
          prop="reject"
          label="Reason for rejection"
          label-class="font-semibold"
        >
          <el-input
            v-model="rejectForm.reject"
            type="textarea"
            :rows="2"
            placeholder="Enter a reason for rejecting this "
          />
        </custom-el-form-item>
        <custom-el-form-item
          class="mt-4"
          label="Ad Tags"
          label-class="font-semibold"
        >
          <campaign-tags-column
            v-if="$can('view_national_campaigns')"
            :ad="ad"
            :options="adTags"
            @change="mutations.setTags(ad.id, $event)"
          />
        </custom-el-form-item>
      </el-form>
    </confirm-dialog>
  </section>
</template>

<script>
import { AdStatus, CampaignType } from '@/constants';

import AdCustomForm from '@/components/Ads/AdCustomForm';
import AdsMutation from '@/components/Mutations/Ads/AdsMutation';
import CampaignTagsColumn from '@/components/Admin/Campaigns/CampaignTagsColumn';
import CampaignTrackerMutation from '@/components/Mutations/Admin/CampaignTrackerMutation';
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import VerificationDialogSidebar from './VerificationDialogSidebar';
import VerificationMap from '@/components/Ads/VerificationMap';
import FormResponsePreview from '@/components/Core/Form/FormBuilder/FormResponsePreview.vue';

export default {
  components: {
    FormResponsePreview,
    VerificationMap,
    AdCustomForm,
    AdsMutation,
    CampaignTagsColumn,
    CampaignTrackerMutation,
    ConfirmDialog,
    CustomElFormItem,
    VerificationDialogSidebar
  },
  props: {
    ad: {
      type: Object,
      required: true
    },
    adTags: {
      type: Array,
      default: () => []
    },
    isSupplier: Boolean,
    isDisabled: Boolean
  },
  data() {
    return {
      mutations: {},
      isUploading: false,
      isSavingSupplier: false,
      isSavingCT: false,
      isShowingMap: false,
      verificationInput: {
        formResponse: {
          form_id: this.ad.verificationForm.id,
          fieldResponses:
            (this.ad.verification &&
              this.ad.verification.formResponse &&
              this.ad.verification.formResponse.fieldResponses) ||
            []
        }
      },
      rejectForm: { reject: '' },
      rejectConfirmDialogVisible: false
    };
  },
  computed: {
    isSaving() {
      return this.isSavingCT || this.isSavingSupplier;
    },
    canEdit() {
      return this.isVerified && this.isNational
        ? this.$can('manage_verified_national_verifications')
        : !this.isDisabled && this.$can('pub_manage_verifications');
    },
    isNational() {
      return (
        this.ad.campaign && this.ad.campaign.type === CampaignType.NATIONAL
      );
    },
    isVerified() {
      return this.ad.status === AdStatus.VERIFIED;
    }
  },
  methods: {
    setSupplierMutations({ supplierSaveVerification }) {
      this.mutations.saveVerification = supplierSaveVerification;
    },
    setMutations({
      saveVerification,
      rejectVerification,
      approveVerification,
      setTags
    }) {
      let mutations = {
        rejectVerification,
        approveVerification,
        setTags
      };

      // If this is a Supplier we want to skip the saveVerification mutation, as this will be assigned
      // via the AdsMutation
      if (!this.isSupplier) {
        mutations.saveVerification = saveVerification;
      }

      Object.assign(this.mutations, mutations);
    },
    rejectVerification(ad, rejectReason) {
      this.mutations.rejectVerification(ad, { text: rejectReason });
      this.rejectConfirmDialogVisible = false;
    },
    onClose() {
      if (this.isSaving || this.isUploading) {
        return this.$message.warning(
          'Please wait until the verification has saved'
        );
      }

      this.$emit('close');
    }
  }
};
</script>
