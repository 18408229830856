<template>
  <div class="variant-fulfillment-column">
    <multi-select-column
      label-key="name"
      value-key="id"
      :options="product.fulfillmentMethods"
      empty-text="Not Required"
      :value="value"
      @input="onChange"
    />
  </div>
</template>

<script>
import MultiSelectColumn from '@/components/Core/Table/Columns/Mediakit/MultiSelectColumn';

import { intersection } from 'lodash';

/**
 * TODO: Maybe for use in the future, need to fix popover issue...
 */
export default {
  components: {
    MultiSelectColumn
  },
  props: {
    product: {
      type: Object,
      required: true
    },

    // The array of fulfillment methods object of a product variant
    value: {
      type: Array,
      required: true
    }
  },

  computed: {
    availableFulfillmentMethodIds() {
      return this.product.fulfillmentMethods.map(f => f.id);
    }
  },

  methods: {
    onChange(value) {
      this.$emit(
        'input',
        intersection(value, this.availableFulfillmentMethodIds) || []
      );
    }
  }
};
</script>
