<template>
  <div class="discount-column">
    <div>Discount Code: {{ orderDiscount.discount.code }}</div>
    <div class="font-bold text-green">
      {{ orderDiscount.amount | currency }} off
    </div>
    <div>{{ orderDiscount.discount.name }}</div>
    <div>{{ orderDiscount.discount.notes }}</div>
  </div>
</template>

<script>
export default {
  name: 'DiscountColumn',
  props: {
    orderDiscount: {
      type: Object,
      required: true
    }
  }
};
</script>
