var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('supplier-invoices-query',{on:{"data":_vm.onReceivedInvoices},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"flex flex-row border-top"},[_c('div',{staticClass:"flex-shrink-0"},[_c('collapsible-search-group-sidebar',{attrs:{"search-tools":_vm.invoiceData && _vm.invoiceData.searchTools,"is-loading":!_vm.invoices,"search-groups-info":_vm.InvoiceSearchGroups,"filter":_vm.pager.filter},on:{"search":function($event){_vm.pager = Object.assign({}, _vm.pager, $event)}},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1),_c('div',{staticClass:"flex flex-1 flex-col p-8 overflow-x-auto min-w-40 border-left"},[_c('supplier-invoice-document-mutation',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isSaving = ref.isSaving;
var exportInvoices = ref.exportInvoices;
var clearSelections = ref.clearSelections;
return [_c('filter-toolbar',{staticClass:"items-end",attrs:{"value":_vm.pager.filter},on:{"input":function($event){_vm.pager = Object.assign({}, _vm.pager, {filter: $event})},"export":function($event){return _vm.onExport(exportInvoices)}}},[(_vm.selectionSummary)?_c('table-selections-count',{attrs:{"count":_vm.selectionSummary.total_selected,"is-saving":isSaving},on:{"clear":clearSelections}}):_vm._e()],1)]}}],null,true)}),_c('supplier-invoices-summary',{staticClass:"mt-5 border border-b-0 border-light-gray",attrs:{"summary":_vm.selectionSummary}}),_c('supplier-invoice-document-mutation',{attrs:{"invoice-data":_vm.invoiceData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isSaving = ref.isSaving;
var selectByIds = ref.selectByIds;
var selectByFilter = ref.selectByFilter;
return [_c('supplier-invoices-table',{ref:"table",attrs:{"is-loading":isLoading,"invoices":_vm.invoices},on:{"select-all":function($event){return _vm.onSelectAll(selectByFilter, $event)},"select":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onSelect.apply(void 0, [ selectByIds ].concat( argsArray ))},"row-click":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onRowClick.apply(void 0, [ selectByIds ].concat( argsArray ))}}})]}}],null,true)}),(_vm.paginatorInfo && _vm.paginatorInfo.total > 0)?_c('pagination',{staticClass:"mt-6",attrs:{"info":_vm.paginatorInfo,"pager":_vm.pager},on:{"update:pager":function($event){_vm.pager=$event}}}):_vm._e()],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }