<template>
  <el-table :data="subLineItems" border class="el-table-slim">
    <template v-slot:empty>
      <table-empty-slot
        :has-data="!!subLineItems"
        empty-message="No Discounts or Fees have been added"
      />
    </template>

    <el-table-column width="50" align="center">
      <template v-slot="{ row }">
        <icon v-if="row.amount > 0" :icon="feeIcon" class="text-red" />
        <icon v-else :icon="discountIcon" class="text-green" />
      </template>
    </el-table-column>

    <el-table-column label="Date" prop="created_at" sortable width="170">
      <template v-slot="{ row }">{{ row.created_at | datetime }}</template>
    </el-table-column>

    <el-table-column label="Name" sortable prop="name" width="170">
      <template v-slot="{ row }">{{ row.name }}</template>
    </el-table-column>

    <el-table-column label="Amount" prop="amount" sortable width="120">
      <template v-slot="{ row }">{{ row.amount | currency }}</template>
    </el-table-column>

    <el-table-column label="User" width="150" align="center">
      <template v-slot="{ row }">
        {{ row.user && row.user.email }}
      </template>
    </el-table-column>

    <el-table-column
      label="Description"
      prop="description"
      sortable
      width="200"
    >
      <template v-slot="{ row }">{{ row.description }}</template>
    </el-table-column>

    <el-table-column v-if="!locked" width="50" align="center">
      <template v-slot="{ row }">
        <a
          class="text-lg text-red hover:text-dark-red"
          @click.stop="$emit('delete', row)"
        >
          <icon :icon="deleteIcon" />
        </a>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {
  dollar as feeIcon,
  money as discountIcon,
  trashEmpty as deleteIcon
} from '@/vendor/icons';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';

export default {
  components: { TableEmptySlot },
  props: {
    locked: Boolean,
    subLineItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      deleteIcon,
      discountIcon,
      feeIcon
    };
  }
};
</script>
