var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transaction-history-table"},[_c('el-table',{staticClass:"el-table-slim",attrs:{"data":_vm.transactions,"border":"","default-sort":{ prop: 'date', order: 'descending' }}},[_c('el-table-column',{attrs:{"label":"ID","sortable":"","prop":"id","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.id))]}}])}),_c('el-table-column',{attrs:{"label":"Date","sortable":"","prop":"date","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm._f("date")(row.date)))]}}])}),_c('el-table-column',{attrs:{"label":"Type","prop":"type","sortable":"","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.type === _vm.TransactionType.CREDIT)?_c('div',{staticClass:"text-red"},[_c('icon',{attrs:{"icon":_vm.dollarIcon}}),_vm._v(" Credit ")],1):_vm._e(),(row.type === _vm.TransactionType.PAYMENT)?_c('div',{staticClass:"text-green"},[_c('icon',{attrs:{"icon":_vm.moneyIcon}}),_vm._v(" Payment ")],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Status","prop":"status","sortable":"","width":"125"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('status-icon',{attrs:{"icons":_vm.TransactionStatusInfo,"status":row.status,"inline":""}})]}}])}),_c('el-table-column',{attrs:{"label":"Amount","prop":"amount","sortable":"","width":"120","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:{
            'text-green': row.amount > 0,
            'text-red': row.amount < 0
          }},[_vm._v(" "+_vm._s(_vm._f("currency")(row.amount))+" ")]),(+row.refunded_amount)?_c('div',{staticClass:"text-dark-silver text-md mt-2"},[_c('div',[_vm._v("Refunded")]),_c('div',[_vm._v(_vm._s(_vm._f("currency")(row.refunded_amount)))])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Method","prop":"paymentMethod","sortable":"","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.paymentMethod)?[_vm._v(" "+_vm._s(row.paymentMethod.name)+" ")]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Description","prop":"description","sortable":"","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.description))]),(row.provider)?_c('div',{staticClass:"invoice-provider text-gray"},[_vm._v(" Paid via "+_vm._s(row.provider)+" ")]):_vm._e()]}}])}),(_vm.isVoidable)?_c('el-table-column',{attrs:{"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.status !== _vm.TransactionStatus.VOID)?[(!_vm.isLoading)?_c('div',{key:'voidable-item-' + row.id},[(!row.provider)?_c('a',{staticClass:"text-red",on:{"click":function($event){(_vm.isVoiding[row.id] = true) && _vm.$emit('void', row)}}},[_c('icon',{attrs:{"icon":_vm.voidIcon}}),_vm._v(" Void ")],1):_c('el-tooltip',{attrs:{"effect":"light","content":"You cannot Void Credit Card Payments. Please contact us for help with this."}},[_c('a',{staticClass:"text-silver hover:text-silver cursor-not-allowed"},[_c('icon',{attrs:{"icon":_vm.voidIcon}}),_vm._v(" Void ")],1)])],1):(_vm.isVoiding[row.id])?_c('div',{key:'voiding-item-' + row.id},[_c('icon',{attrs:{"icon":_vm.loadingIcon,"spin":""}})],1):_vm._e()]:_vm._e()]}}],null,false,1633622946)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }