<template>
  <supplier-line-items-query @data="onReceivedLineItems">
    <template v-slot="{ isLoading }">
      <div class="flex flex-row border-top">
        <div class="flex-shrink-0">
          <collapsible-search-group-sidebar
            v-model="searchInput"
            :search-tools="searchTools"
            :is-loading="!lineItems"
            :search-groups-info="LineItemsSearchGroups"
            :filter="pager.filter"
            @search="pager = { ...pager, ...$event }"
          />
        </div>
        <div
          class="flex flex-1 flex-col p-8 overflow-x-auto min-w-40 border-left"
        >
          <!-- Toolbar -->
          <supplier-billing-toolbar
            :value="pager"
            :selections-summary="selectionsSummary"
            @input="pager = $event"
            @create-invoice="isCreatingInvoice = true"
          />

          <supplier-run-dates-summary
            :summary="selectionsSummary"
            class="mt-5 border border-b-0 border-light-gray"
          />

          <!-- Table -->
          <supplier-run-dates-table
            :is-loading="isLoading"
            :line-items="lineItems"
            :selections-summary="selectionsSummary"
            :paginator-info="paginatorInfo"
            @create-invoice="isCreatingInvoice = true"
          />
          <!-- Pagination -->
          <pagination
            v-if="paginatorInfo && paginatorInfo.total > 0"
            class="mt-6"
            :info="paginatorInfo"
            :pager.sync="pager"
          />
        </div>

        <supplier-create-invoice-flow
          v-if="isCreatingInvoice"
          :selections-summary="selectionsSummary"
          @close="isCreatingInvoice = false"
        />
      </div>
    </template>
  </supplier-line-items-query>
</template>

<script>
import Pagination from '@/components/Core/Table/Pagination';
import CollapsibleSearchGroupSidebar from '@/components/Core/CollapsibleSearchGroupSidebar';
import SupplierRunDatesTable from './SupplierRunDatesTable';
import SupplierBillingToolbar from './SupplierBillingToolbar';
import { SupplierLineItemsQuery } from '@/components/Queries';
import { LineItemsSearchGroups } from '@/constants';
import SupplierCreateInvoiceFlow from '@/components/Supplier/Billing/SupplierCreateInvoiceFlow';
import SupplierRunDatesSummary from '@/components/Supplier/Billing/SupplierRunDatesSummary';

export default {
  components: {
    SupplierRunDatesSummary,
    SupplierCreateInvoiceFlow,
    Pagination,
    CollapsibleSearchGroupSidebar,
    SupplierRunDatesTable,
    SupplierBillingToolbar,
    SupplierLineItemsQuery
  },
  data() {
    return {
      isCreatingInvoice: false,
      lineItems: null,
      LineItemsSearchGroups,
      paginatorInfo: null,
      searchTools: null,
      // TODO: This is needed because of the way SearchGroupSidebar behaves. We need to refactor this
      searchInput: {},
      selectionsSummary: null
    };
  },
  computed: {
    pager: {
      get() {
        return this.$store.getters['orderLineItems/pager'];
      },
      set(value) {
        this.$store.commit('orderLineItems/setPager', value);
      }
    }
  },
  methods: {
    onReceivedLineItems({
      orderLineItemTracker: {
        searchTools,
        orderLineItems,
        lineItemsSelectionSummary
      }
    }) {
      this.searchTools = searchTools;
      this.lineItems = orderLineItems.data;
      this.paginatorInfo = orderLineItems.paginatorInfo;
      this.selectionsSummary = lineItemsSelectionSummary;
    }
  }
};
</script>
