<template>
  <div class="script-tag-column">
    <el-popover placement="left" trigger="hover">
      <div slot="reference">View Tags</div>

      <div class="popover-content pad max-w-5xl">
        <template v-if="isEmail">
          <label-content
            label="E-Newsletter Ad Serving Tag"
            help="Copy and paste this code where ads targeting this placement will appear in your Mailchimp e-newsletter template"
            label-class="font-semibold"
          />
          <div class="margin-top">
            <template v-if="variant.servingTag">
              <digital-script-tag :id="property.id" copy
                >{{ variant.servingTag }}
              </digital-script-tag>
            </template>
            <template v-else>
              <el-alert
                type="error"
                title="No Ad Serving Tag"
                :closable="false"
              >
                The serving tag has not been generated yet. Please check back
                later.
              </el-alert>
            </template>
          </div>
        </template>
        <template v-else>
          <label-content
            label="Script Tag"
            help="You only need to enter this tag once, in the header of your website."
            label-class="font-semibold"
          />
          <digital-script-tag :id="property.id" copy class="margin-top" />

          <template v-if="variant.id">
            <label-content
              label="Class"
              help="Enter this class in each ad unit pertaining to this variant."
              class="mt-8"
              label-class="font-semibold"
            />

            <!-- @formatter:off -->
            <!-- eslint-disable-next-line -->
            <digital-script-tag :id="property.id" copy class="margin-top"
              >&lt;div class="flytead {{ classSlug }}
              {{ variant.id }}"&gt;&lt;/div&gt;</digital-script-tag
            >
            <!-- @formatter: on -->
          </template>
          <template v-else>
            <div class="font-bold text-red margin-top-xxl">
              Please save changes to this Product first, then come back here to
              view the Script Class
            </div>
          </template>
        </template>
      </div>
    </el-popover>
  </div>
</template>

<script>
import LabelContent from '@/components/Core/LabelContent';
import DigitalScriptTag from '@/components/Supplier/Mediakit/Common/DigitalScriptTag';

export default {
  components: {
    DigitalScriptTag,
    LabelContent
  },
  props: {
    medium: {
      type: Object,
      required: true
    },
    property: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    variant: {
      type: Object,
      required: true
    }
  },

  computed: {
    isEmail() {
      return ['Email', 'Email Ad Serving'].includes(
        this.medium.typeEdges[0]?.type.value
      );
    },
    classSlug() {
      let classSlug = '';

      for (let type of this.product.typeEdges) {
        classSlug += type.type.class_tag;
      }

      return classSlug;
    }
  }
};
</script>
