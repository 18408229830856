<template>
  <div class="fulfillment-info leading-loose text-left">
    <div v-if="title" class="font-bold">{{ fulfillment.name }}</div>

    <div class="text-blue">{{ fulfillment.display_name }}</div>

    <div v-if="price" class="price">
      <span class="font-bold">Fee:</span>
      <span class="text-green font-bold">
        <template v-if="fulfillment.price > 0"
          >{{ fulfillment.price | currency }}
        </template>
        <template v-else>Free</template>
      </span>
    </div>

    <div v-if="deadlineDate">
      <label class="font-bold">Due:</label>
      <span :class="{ 'text-red': isDueSoon }">{{ deadlineDate | date }}</span>
    </div>

    <div class="requirements mt-2">
      <div v-if="fulfillment.requires_assets" class="flex">
        <icon :icon="fileImage" class="text-dark-silver text-xl" />
        <span class="ml-3 leading-normal">File Upload</span>
      </div>

      <div v-if="fulfillment.requires_text" class="flex mt-3">
        <icon :icon="type" class="text-dark-silver text-xl" />
        <span class="ml-3 leading-normal">Text</span>
      </div>

      <div v-if="fulfillment.requires_shipping" class="flex mt-3">
        <icon :icon="mail" class="text-dark-silver text-xl" />
        <div class="ml-3 mt-1 leading-normal">
          <div>Ship To:</div>
          <div>{{ fulfillment.shipping_address }}</div>
        </div>
      </div>

      <div v-if="fulfillment.requires_printing" class="flex mt-3">
        <icon :icon="printer" class="text-dark-silver text-xl" />
        <span class="ml-3 leading-normal">Printing</span>
      </div>
    </div>

    <div v-if="!fulfillment.description" class="description mt-3">
      <span class="whitespace-pre-wrap">{{ fulfillment.description }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import { fileImage, mail, ok, printer, type } from '@/vendor/icons';

export default {
  props: {
    title: Boolean,
    price: Boolean,
    deadlineDate: { type: String, default: null },
    fulfillment: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      // Icons
      fileImage,
      mail,
      ok,
      printer,
      type
    };
  },

  computed: {
    isDueSoon() {
      // Due soon if the deadline is before tomorrow
      return moment(this.deadlineDate).isBefore(
        moment()
          .add(1, 'day')
          .startOf('day')
      );
    }
  }
};
</script>
