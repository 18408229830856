<template>
  <div class="buyer-verification-column has-content-centered">
    <template v-if="hasVerifications">
      <form-response-preview :response="ad.verification.formResponse" />

      <div class="margin-top-xs">
        <a class="font-bold" @click="showDialog = true">
          Review Verifications
        </a>
      </div>
    </template>

    <template v-else>
      <a
        class="add-creative pad-xxl hover:bg-very-light-silver hover:text-green"
        @click="showDialog = true"
      >
        <icon :icon="upload" class="icon-xxl" />
      </a>
    </template>

    <buyer-verification-dialog
      v-if="showDialog"
      :ad="ad"
      @close="showDialog = false"
    />
  </div>
</template>

<script>
import BuyerVerificationDialog from '@/modules/Buyer/Verifications/BuyerVerificationDialog';
import FormResponsePreview from '@/components/Core/Form/FormBuilder/FormResponsePreview';

import { upload } from '@/vendor/icons';

export default {
  components: {
    BuyerVerificationDialog,
    FormResponsePreview
  },
  props: {
    ad: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showDialog: false,

      // Icons
      upload
    };
  },

  computed: {
    hasVerifications() {
      return !!(
        this.ad.verification &&
        this.ad.verification.formResponse &&
        !this.ad.verification.formResponse.is_empty
      );
    }
  }
};
</script>
