<template>
  <edit-dialog
    show-dialog
    title="Create Invoice"
    save-text="Create"
    :is-saving="isLoading"
    :is-disabled="lineItemsCount === 0"
    @save="onSaveInvoice"
    @close="$emit('close')"
  >
    <invoice-terms-query @data="termOptions = $event">
      <template v-slot="{ isLoading: isLoadingTerms }">
        <template v-if="lineItemsCount > 0">
          <div class="text-md pr-32">
            You've selected {{ lineItemsCount }}
            {{ lineItemsCount === 1 ? 'line item' : 'line items' }}

            <template v-if="selectedCustomer">
              for {{ selectedCustomer.buyer.buyer_company }}.
            </template>
            <template v-else>
              across
              {{ customerCount }}
              unique customers.
            </template>
            <div>
              Choose from the options below to indicate the invoice settings for
              your selections:
            </div>
          </div>
          <el-form
            ref="invoiceForm"
            class="text-left mt-6"
            :disabled="isLoading"
            :model="model"
            :rules="rules"
          >
            <div class="flex w-full">
              <custom-el-form-item
                label="Terms"
                class="w-1/2 mr-4"
                label-class="font-semibold"
                prop="terms"
              >
                <custom-el-select
                  v-model="model.terms"
                  placeholder="Select terms"
                  class="w-full"
                  select-class="w-full"
                  singular
                  hide-icons
                  value-by-object
                  label-key="text"
                  :is-loading="isLoadingTerms"
                  :options="termOptions"
                  @input="updateDueDate"
                />
              </custom-el-form-item>
              <custom-el-form-item
                label="Billing Period"
                class="w-1/2"
                label-class="font-semibold"
                prop="billingPeriod"
              >
                <el-date-picker
                  v-model="model.billingPeriod"
                  type="daterange"
                  range-separator="To"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  format="MM/dd/yyyy"
                />
              </custom-el-form-item>
            </div>
            <div class="flex w-full mt-6">
              <custom-el-form-item
                label="Due Date"
                class="w-1/2 mr-4"
                label-class="font-semibold"
                prop="dueDate"
              >
                <el-date-picker
                  v-model="model.dueDate"
                  placeholder="Due Date"
                  format="MM/dd/yyyy"
                />
              </custom-el-form-item>
              <custom-el-form-item
                label="Issued Date"
                class="w-1/2"
                label-class="font-semibold"
                prop="transactionDate"
              >
                <el-date-picker
                  v-model="model.transactionDate"
                  format="MM/dd/yyyy"
                  placeholder="Issued Date"
                  @change="updateDueDate"
                />
              </custom-el-form-item>
            </div>
          </el-form>
        </template>
        <template v-else>
          <div class="p-10 text-dark-silver">
            To create an invoice, first select the line items you would to
            invoice for, then click on the "Action" menu and choose "Create
            Invoice".
          </div>
        </template>

        <template v-if="errors">
          <el-alert
            v-for="message in errors"
            :key="message"
            class="mt-5"
            title=""
            type="error"
          >
            {{ message }}
          </el-alert>
        </template>
      </template>
    </invoice-terms-query>
  </edit-dialog>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import CustomElSelect from '@/components/Custom/CustomElSelect';
import EditDialog from '@/components/Core/EditDialog';
import { InvoiceTermsQuery } from '@/components/Queries';
import moment from 'moment';
import { datetimeDb } from '@/utils/filters';

const rule = propName => [
  { required: true, trigger: 'blur', message: `A ${propName} is required` }
];

export default {
  components: {
    CustomElFormItem,
    CustomElSelect,
    EditDialog,
    InvoiceTermsQuery
  },
  props: {
    selectionsSummary: {
      type: Object,
      default: null
    },
    errors: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isLoading: Boolean
  },
  data() {
    return {
      model: {
        dueDate: null,
        billingPeriod: [moment().startOf('month'), moment().endOf('month')],
        transactionDate: moment(),
        terms: null
      },
      termOptions: [],
      rules: {
        terms: rule('Terms Selection'),
        dueDate: rule('Due Date'),
        transactionDate: rule('Issued Date'),
        billingPeriod: rule('Billing Period')
      }
    };
  },
  computed: {
    lineItemsCount() {
      return this.selectionsSummary ? this.selectionsSummary.total_selected : 0;
    },
    selectedCustomer() {
      return this.customerCount === 1
        ? this.selectionsSummary.uniqueCustomers[0]
        : null;
    },
    customerCount() {
      return this.selectionsSummary && this.selectionsSummary.uniqueCustomers
        ? this.selectionsSummary.uniqueCustomers.length
        : 0;
    }
  },
  mounted() {
    if (this.selectedCustomer) {
      this.model.terms = this.selectedCustomer.terms;
      this.updateDueDate();
    }
  },
  methods: {
    updateDueDate() {
      this.model.dueDate = moment(this.model.transactionDate).add(
        this.model.terms.days,
        'days'
      );
    },
    onSaveInvoice() {
      this.$refs.invoiceForm.validate(async isValid => {
        if (isValid) {
          this.$emit('save', {
            terms_id: this.model.terms.id,
            transaction_date: datetimeDb(this.model.transactionDate),
            start_date: datetimeDb(this.model.billingPeriod[0]),
            end_date: datetimeDb(this.model.billingPeriod[1]),
            due_date: datetimeDb(this.model.dueDate)
          });
        }
      });
    }
  }
};
</script>
