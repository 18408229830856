<template>
  <div v-if="!isDeleted" class="border flex flex-row p-4 rounded">
    <avatar v-if="note.user" class="mr-3" :user="note.user" />
    <note-mutation
      class="flex-grow"
      @done="(isEditing = false) || (showConfirmDeleteDialog = false)"
    >
      <template v-slot="{ isSaving, editNote, deleteNote }">
        <div class="flex flex-1 flex-col">
          <h4 v-if="note.user">
            {{ note.user.name }}
          </h4>
          <div class="text-xs flex">
            <div>{{ momentTz(note.created_at) | dateAtTime }}</div>
            <div v-if="hasEdits" class="text-dark-silver ml-3">
              (edited {{ momentTz(note.updated_at) | dateAtTime }})
            </div>
          </div>
          <div v-if="canEditNote" class="flex">
            <template v-if="!isEditing">
              <a @click="isEditing = true">
                <icon :icon="editIcon" />
                Edit</a
              >
              <a
                class="text-red hover:text-dark-red ml-4"
                @click="showConfirmDeleteDialog = true"
              >
                <icon :icon="deleteIcon" />
                Delete
              </a>
            </template>
          </div>
          <div class="flex flex-row mt-3">
            <b v-if="note.description" class="text-dark-gray pr-2">
              {{ note.description }}:
            </b>
            <div v-if="!isEditing">{{ note.text }}</div>
            <div v-else class="flex-grow">
              <el-input v-model="noteText" type="textarea" class="w-full" />
              <div class="mt-3 flex items-center justify-space-between">
                <el-button
                  class="button-white flex-grow mr-5"
                  size="small"
                  @click="isEditing = false"
                >
                  Cancel
                </el-button>
                <div class="flex-grow">
                  <el-button
                    v-if="!isSaving"
                    size="small"
                    class="button-blue w-full"
                    @click="editNote(note, { text: noteText })"
                  >
                    Save
                  </el-button>
                  <loading-button v-else class="w-full" size="small" />
                </div>
              </div>
            </div>
          </div>
          <confirm-dialog
            v-if="showConfirmDeleteDialog"
            title="Delete Note"
            confirm-class="button-red"
            confirm-text="Delete Note"
            :is-saving="isSaving"
            @confirm="onDeleteNote(deleteNote)"
            @close="showConfirmDeleteDialog = false"
          >
            Are you sure you want to permanently delete this note?
          </confirm-dialog>
        </div>
      </template>
    </note-mutation>
  </div>
</template>

<script>
import Avatar from '@/components/Core/Avatar';
import { pencil as editIcon, trashEmpty as deleteIcon } from '@/vendor/icons';
import NoteMutation from '@/components/Mutations/Core/NoteMutation';
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import { momentTz } from '@/utils/date';

export default {
  components: {
    ConfirmDialog,
    NoteMutation,
    Avatar
  },
  props: {
    note: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isDeleted: false,
      isEditing: false,
      showConfirmDeleteDialog: false,
      noteText: this.note.text,

      // Icons
      deleteIcon,
      editIcon
    };
  },
  computed: {
    hasEdits() {
      return this.note.created_at !== this.note.updated_at;
    },
    canEditNote() {
      return (
        this.note.user &&
        this.$store.getters['auth/userId'] === this.note.user.id
      );
    }
  },
  methods: {
    async onDeleteNote(deleteNote) {
      await deleteNote(this.note);

      // XXX: Quick way to hide this note after it is deleted
      this.isDeleted = true;

      this.$emit('deleted');
    },
    momentTz
  }
};
</script>
