<template>
  <div class="dimension">
    <template v-if="hasStandard">
      <span class="width">{{ dimension.width }}</span>
      <span class="x">x</span>
      <span class="height">{{ dimension.height }}</span>
      <span class="unit ml-1">{{ dimension.unit }}</span>
    </template>
    <template v-else-if="hasRatio">
      <div class="ratio">
        <div>
          {{ dimension.width_ratio }}:{{ dimension.height_ratio }} Ratio
        </div>
        <div v-if="dimension.max_width">
          Max Width: {{ dimension.max_width }}px
        </div>
        <div v-if="dimension.max_height">
          Max Height: {{ dimension.max_height }}px
        </div>
      </div>
    </template>
    <template v-else>
      <i>N/A</i>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    dimension: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasRatio() {
      return this.dimension.width_ratio;
    },
    hasStandard() {
      return this.dimension.width;
    }
  }
};
</script>
