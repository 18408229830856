<template>
  <div>
    <template v-if="isCanceled || !download">
      <el-tooltip
        :content="
          download
            ? 'The Insertion Order is not available because the Order has been canceled'
            : 'Please approve ads for this order'
        "
        effect="light"
      >
        <io-item
          :order="order"
          :campaign="campaign"
          :icon-class="{
            'text-dark-silver hover:text-gray': !isCanceled,
            'text-gray hover:text-gray cursor-not-allowed': isCanceled
          }"
          :download="download"
          @action="$emit('action')"
        />
      </el-tooltip>
    </template>

    <template v-else>
      <template v-if="isReviewing && canReview">
        <slot name="reviewing" />
      </template>

      <template v-else-if="isChangesRequested && canReview">
        <slot name="changes" />
      </template>

      <template v-else>
        <el-popover placement="right" trigger="hover" :disabled="!isNational">
          <div class="text-left w-64">
            <div class="font-bold pb-2">
              Flytedesk National Campaign
            </div>
            This national order is active. You can download this IO from the
            “Action” column to view it. Please contact
            <a href="mailto:nationalads@flytedesk.com"
              >nationalads@flytedesk.com</a
            >
            with any questions.
          </div>
          <io-item
            slot="reference"
            :icon="isNational ? fileLogoIcon : undefined"
            :order="order"
            :campaign="campaign"
            :download="download"
            :icon-class="itemClass"
            @action="$emit('action')"
          />
        </el-popover>
      </template>

      <div v-if="showStatus" class="whitespace-no-wrap">
        <status-icon :icons="OrderStatusIcons" :status="order.status" inline />
      </div>
    </template>
  </div>
</template>

<script>
import { bubble as bubbleIcon, fileLogo as fileLogoIcon } from '@/vendor/icons';
import {
  CampaignType,
  OrderApprovalStatus,
  OrderStatus,
  OrderStatusIcons
} from '@/constants';

import IoItem from '@/components/Order/IoItem';
import StatusIcon from '@/components/Core/Statuses/StatusIcon';

export default {
  components: { IoItem, StatusIcon },
  props: {
    canReview: Boolean,
    download: Boolean,
    campaign: { type: Object, default: null },
    order: {
      type: Object,
      required: true
    },
    showStatus: Boolean
  },
  data() {
    return {
      OrderStatus,
      OrderStatusIcons,
      bubbleIcon,
      fileLogoIcon
    };
  },
  computed: {
    isChangesRequested() {
      return (
        this.order.approval_status === OrderApprovalStatus.CHANGES_REQUESTED
      );
    },
    isCanceled() {
      return this.order.status === OrderStatus.CANCELED;
    },
    isReviewing() {
      return this.order.approval_status === OrderApprovalStatus.PENDING;
    },
    itemClass() {
      return this.isNational
        ? 'text-blue hover:text-dark-blue'
        : 'text-dark-silver hover:text-gunmetal';
    },
    isNational() {
      return this.order.campaign
        ? this.order.campaign.type === CampaignType.NATIONAL
        : this.campaign && this.campaign.type === CampaignType.NATIONAL;
    }
  }
};
</script>
