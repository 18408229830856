<template>
  <el-tooltip
    :content="tooltipContent"
    effect="light"
    popper-class="whitespace-pre-wrap"
    :enterable="false"
  >
    <div class="inline-block">
      <slot name="before" />
      <icon class="text-dark-silver" :icon="lock" />
      <slot />
    </div>
  </el-tooltip>
</template>

<script>
import { lock } from '@/vendor/icons';

export default {
  props: {
    message: { type: String, default: null }
  },

  data() {
    return {
      lock
    };
  },

  computed: {
    tooltipContent() {
      return (
        this.message ||
        'This is a flytedesk curated setting, which you are unable to modify.\nThis allows us to sell your products nationally.'
      );
    }
  }
};
</script>
