<template>
  <div>
    <template v-if="ad.orderProduct.is_ssp_product">
      {{ ad.orderProduct.product_variant_name }}
    </template>
    <template
      v-else-if="
        ($store.getters['auth/isSupplier'] &&
          ad.orderProduct &&
          ad.orderProduct.product_variant &&
          !ad.orderProduct.product_variant.is_super) ||
          !ad.nationalTagPath
      "
    >
      <div>
        <router-link
          :to="{
            name: 'supplier.mediakit.medium',
            params: { id: ad.orderProduct.medium_id }
          }"
        >
          {{ ad.orderProduct.medium.name }}
        </router-link>
      </div>
      <div>
        <router-link
          :to="{
            name: 'supplier.mediakit.property',
            params: { id: ad.orderProduct.property_id }
          }"
        >
          {{ ad.orderProduct.property.name }}
        </router-link>
      </div>
      <div>
        <router-link
          :to="{
            name: 'supplier.mediakit.collection',
            params: { id: ad.orderProduct.collection_id }
          }"
        >
          {{ ad.orderProduct.collection.name }}
        </router-link>
      </div>
      <div>
        {{ ad.orderProduct.product.name }},
        {{ ad.orderProduct.product_variant.name }}
      </div>
    </template>
    <template v-else>
      <div>
        {{ ad.nationalTagPath.name }}
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    ad: {
      type: Object,
      required: true
    }
  }
};
</script>
