<template>
  <div class="form-renderer-field">
    <div class="field-label flex items-top">
      <div class="flex flex-grow">
        <label
          v-if="field.label"
          class="form-label cursor-pointer font-bold text-base"
          :for="field.name"
          :class="{ 'text-dark-silver': !field.label }"
        >
          {{ field.label }}
          <template v-if="$can('manage_any_custom_form')">
            ({{ field.name }})
          </template>
        </label>
        <div
          v-if="field.required || field.required_group"
          class="text-red font-bold ml-2"
        >
          {{ field.required_group || '*' }}
        </div>
      </div>
      <div v-if="!isDisabled || alwaysAllowClear">
        <a @click="$emit('input', null)">clear</a>
      </div>
    </div>
    <div v-if="description" class="field-description mt-2">
      <text-editor-renderer :content="description" />
    </div>
    <div class="form-input mt-2">
      <template v-if="field.component">
        <div
          :is="field.component"
          v-mask="field.mask"
          v-bind="fieldProps"
          :disabled="isDisabled"
          :download="field.allow_downloads"
          :value="value"
          @update:uploading="$emit('uploading', $event)"
          @input="onInput"
          @complete="onComplete"
        />
      </template>
    </div>
  </div>
</template>

<script>
import CustomElSwitch from '@/components/Custom/CustomElSwitch';
import TextEditor from '@/components/Core/Form/TextEditor';
import UploadBox from '@/components/Core/UploadBox';
import TextEditorRenderer from '@/components/Core/Form/TextEditorRenderer';
import { BuilderFields } from '@/constants';

export default {
  components: {
    TextEditorRenderer,
    CustomElSwitch,
    TextEditor,
    UploadBox
  },
  props: {
    value: {
      type: [Object, Array, String, Number, Boolean, Date],
      default: null
    },
    field: {
      type: Object,
      required: true
    },
    isDisabled: Boolean,
    alwaysAllowClear: Boolean
  },
  computed: {
    isBuyer() {
      return this.$store.getters['auth/isBuyer'];
    },
    description() {
      if (this.isBuyer && this.field.show_buyer_description) {
        return this.field.buyer_description;
      } else {
        return this.field.description;
      }
    },
    fieldProps() {
      let props = { ...this.field.props };

      props.name = this.field.name;

      // Certain components require overriding certain properties (particularly placeholder)
      switch (this.field.component) {
        case 'TextEditor':
          if (!props.config) {
            props.config = {};
          }

          props.config.placeholder = this.field.placeholder;
          break;

        case 'UploadBox':
          // Styled file item boxes
          props.fileItem = {
            width: '7.5em',
            height: '7.5em',
            class: 'rounded shadow text-lg'
          };
          break;

        default:
          props.placeholder = this.field.placeholder;
          break;
      }

      return props;
    }
  },
  mounted() {
    // XXX: Masks applied asynchronously so we must wait until the final input value of the mask is applied before being ready
    setTimeout(() => this.$emit('ready'), 10);
  },
  methods: {
    getFileInput(file) {
      return {
        disk: file.disk,
        filepath: file.filepath,
        id: file.id,
        // Must include the file uid in order to maintain same key on FileItem when uploading
        uid: file.uid,
        location: file.location,
        mime: file.mime,
        name: file.name,
        size: file.size,
        url: file.url
      };
    },
    /**
     * This is for UploadBox complete event, which will let us know
     * when the file uploads are completed and the input can be updated
     *
     * @param value
     */
    onComplete(value) {
      if (value) {
        if (Array.isArray(value)) {
          value = value.map(this.getFileInput);
        } else {
          value = this.getFileInput(value);
        }
      }

      this.$emit('input', value);
    },
    onInput(value) {
      // Certain components like UploadBox we only want to trigger changes after completion
      if (this.field.inputOnComplete) {
        return;
      }

      switch (this.field.type) {
        // Boolean fields if the value is exactly the same then no change has occurred
        case BuilderFields.BOOLEAN.type:
          if (this.value === value) return;
          break;

        // For TEXT fields we want to ignore triggered inputs if the value is the same
        default:
          if ((!this.value && !value) || this.value === value) return;
          break;
      }

      this.$emit('input', value);
    }
  }
};
</script>
