<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="text-editor-renderer" v-html="content"></div>
  <!--eslint-enable-->
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true
    }
  }
};
</script>
<style scoped lang="scss">
.text-editor-renderer /deep/ {
  font-size: 16px;

  ul {
    list-style: disc;

    ul {
      padding-left: 1em;
    }

    & > li > ul {
      list-style: circle;

      & > li > ul {
        list-style: square;
      }
    }
  }

  ol {
    ol {
      padding-left: 1em;
    }

    & > li > ol {
      list-style: upper-roman;

      & > li > ol {
        list-style: lower-roman;
      }
    }
  }

  li {
    list-style-position: inside;
  }
}
</style>
