<template>
  <div v-if="avatarSource" class="mr-4" :style="containerStyle">
    <div
      :style="imageStyle"
      class="bg-gray bg-center bg-contain bg-no-repeat h-0 pb-full rounded-full"
    />
  </div>
  <div
    v-else
    class="flex items-center justify-center bg-gray text-center rounded-full"
    :style="containerStyle"
  >
    <span class="font-bold" :style="textStyle">
      {{ userInitials }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    width: {
      type: Number,
      default: 3
    },
    height: {
      type: Number,
      default: 3
    }
  },
  computed: {
    userInitials() {
      if (this.user.name) {
        const names = this.user.name.split(' ');
        return names.reduce((acc, cv) => acc + cv[0], '');
      } else {
        return this.user.email[0];
      }
    },
    containerStyle() {
      return { height: `${this.height}em`, width: `${this.width}em` };
    },
    avatarSource() {
      return this.user.profilePicture && this.user.profilePicture.url;
    },
    imageStyle() {
      return { 'background-image': `url("${this.avatarSource}")` };
    },
    textStyle() {
      return {
        'font-size': `${this.height * 0.55}em`,
        'line-height': `${this.height * 0.55}em`
      };
    }
  }
};
</script>

<style></style>
