<template>
  <div class="credit-card-form">
    <el-form ref="ccForm" :model="form" :rules="rules">
      <slot name="before" />

      <stripe-card-element @card="onCardCreated" />

      <div class="flex mt-5">
        <custom-el-form-item label="First Name" class="pr-2 w-full">
          <el-input v-model="form.first_name" name="first_name" />
        </custom-el-form-item>
        <custom-el-form-item label="Last Name" class="pl-2 w-full">
          <el-input v-model="form.last_name" name="last_name" />
        </custom-el-form-item>
      </div>

      <div class="flex mt-5">
        <custom-el-form-item
          label="Street Address"
          class="w-full"
          prop="address"
        >
          <el-input v-model="form.address" name="street_address" />
        </custom-el-form-item>
      </div>

      <div class="flex mt-5">
        <custom-el-form-item label="PO Box/Apt #" class="w-full">
          <el-input v-model="form.address2" name="street_address_2" />
        </custom-el-form-item>
      </div>

      <div class="flex mt-5">
        <custom-el-form-item label="City" class="pr-2" prop="city">
          <el-input v-model="form.city" name="city" />
        </custom-el-form-item>
        <custom-el-form-item label="State" class="px-2" prop="state">
          <select-state v-model="form.state" name="state" />
        </custom-el-form-item>
        <custom-el-form-item label="Zip Code" class="pl-2" prop="zip_code">
          <el-input v-model="form.zip_code" name="zip_code" />
        </custom-el-form-item>
      </div>

      <!-- Submit Button / Extra Form details Slot -->
      <slot />
    </el-form>
  </div>
</template>

<script>
import SelectState from '@/components/Core/SelectState';
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import StripeCardElement from '@/components/Payment/StripeCardElement';

export default {
  components: {
    CustomElFormItem,
    SelectState,
    StripeCardElement
  },
  data() {
    return {
      creditCard: null,
      form: {
        first_name: '',
        last_name: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip_code: '',
        country: 'US'
      }
    };
  },
  computed: {
    rules() {
      return {
        address: [
          { required: true, message: 'Address is required', trigger: 'change' }
        ],
        city: [
          { required: true, message: 'City is required', trigger: 'change' }
        ],
        state: [
          { required: true, message: 'State is required', trigger: 'change' }
        ],
        zip_code: [
          { required: true, message: 'Zip Code is required', trigger: 'change' }
        ]
      };
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.onChange();
      }
    }
  },
  mounted() {
    this.onChange();
  },
  methods: {
    async validate() {
      await this.$refs.ccForm.validate(valid => {
        if (!valid) {
          throw new Error('Please complete all required fields in the form');
        }
      });

      return true;
    },
    onChange() {
      return this.$emit('input', {
        card: this.creditCard,
        form: this.form
      });
    },
    onCardCreated(creditCard) {
      this.creditCard = creditCard;

      this.onChange();
    }
  }
};
</script>
