<template>
  <div class="ad-date-column">
    <date-column :start-date="ad.start_date" :end-date="ad.end_date" />

    <template v-if="showCanceledStatus && isCanceled">
      <icon :icon="AdStatusInfo.CANCELED.icon" class="text-red" />
      <span class="text-dark-gray ml-1">
        {{ AdStatusInfo.CANCELED.name }}
      </span>
    </template>

    <template v-if="canReschedule">
      <div>
        <a @click="$emit('reschedule', ad)">
          <icon :icon="calendar" />
          Reschedule</a
        >
      </div>
    </template>
  </div>
</template>

<script>
import { AdStatus, AdStatusInfo } from '@/constants';
import { calendar, cancel as cancelIcon } from '@/vendor/icons';
import DateColumn from '@/components/Core/Table/Columns/DateColumn';

export default {
  components: { DateColumn },
  props: {
    ad: {
      type: Object,
      required: true
    },
    canReschedule: Boolean,
    showCanceledStatus: Boolean
  },

  data() {
    return {
      AdStatusInfo,
      // Icons
      calendar,
      cancelIcon
    };
  },

  computed: {
    isCanceled() {
      return this.ad.status === AdStatus.CANCELED;
    }
  }
};
</script>
