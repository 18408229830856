<template>
  <supplier-billing-mutation
    :paginator-info="paginatorInfo"
    :displayed-line-items="lineItems"
    :selections-summary="selectionsSummary"
  >
    <template
      v-slot="{
        isSaving: isSavingBilling,
        selectByIds,
        selectByFilter,
        updateLineItem
      }"
    >
      <el-table
        ref="table"
        :data="isLoading ? null : lineItems"
        border
        class="el-table-slim w-full"
        @select-all="onSelectAll(selectByFilter, $event)"
        @select="onSelect(selectByIds, ...arguments)"
        @row-click="onRowClick(selectByIds, ...arguments)"
      >
        <template v-slot:empty>
          <table-empty-slot
            :is-loading="isLoading"
            :has-data="!!lineItems"
            error-message="There was a problem loading the Line Items. Please try again later."
          >
            <template v-slot:empty>
              <div class="flex items-center justify-center h-full w-full">
                You don't have any Ads running based on the filters you've
                selected.
              </div>
            </template>
          </table-empty-slot>
        </template>

        <el-table-column type="selection" width="45" />

        <el-table-column label="Status" align="center" width="100">
          <template slot-scope="{ row }">
            <billing-status-column
              :campaign="row.order.campaign"
              :line-item="row"
              :loading="isSavingBilling"
              @change="updateLineItem(row.order.id, row, { status: $event })"
            />
          </template>
        </el-table-column>

        <el-table-column label="Order ID" width="90">
          <template v-slot="{ row }">
            {{ row.order.ref }}
          </template>
        </el-table-column>

        <el-table-column label="Customer">
          <template v-slot="{ row }">
            <customer-column
              :customer="row.order.customer"
              :campaign="row.order.campaign"
            />
          </template>
        </el-table-column>

        <el-table-column label="Rep">
          <template v-slot="{ row }">
            <rep-column :order="row.order" />
          </template>
        </el-table-column>

        <el-table-column label="Ad Details">
          <template v-slot="{ row }">
            <ad-column
              v-if="row.type === OrderLineItemType.AD && row.ads.length > 0"
              :ad="row.ads[0]"
              :order-line-item="row"
            />
            <discount-column
              v-else-if="row.type === OrderLineItemType.DISCOUNT"
              :order-discount="row.discount"
            />
          </template>
        </el-table-column>

        <el-table-column label="Run Date(s)" align="center" width="110">
          <template v-slot="{ row }">
            <date-column
              :start-date="row.ads[0] ? row.ads[0].start_date : row.active_date"
              :end-date="row.ads[0] ? row.ads[0].end_date : null"
            />
          </template>
        </el-table-column>

        <el-table-column label="Date Created" align="center" width="100">
          <template v-slot="{ row }">
            {{ row.created_at | date }}
          </template>
        </el-table-column>

        <el-table-column label="Amount" align="center" width="160">
          <template v-slot="{ row, $index }">
            <billing-amount-column
              :tab-index="$index + 1"
              :line-item="row"
              :show.sync="lineItemToModifyAmount"
            />
          </template>
        </el-table-column>

        <el-table-column label="Invoices" align="right" width="200">
          <template v-slot="{ row, $index }">
            <invoice-list-column
              :line-item="row"
              @create-invoice="onCreateInvoice(selectByIds, row)"
            />
          </template>
        </el-table-column>

        <el-table-column label="Comments" min-width="35px" align="center">
          <template v-slot="{ row }">
            <supplier-notes-column :ads="row.ads" :order="row.order" />
          </template>
        </el-table-column>

        <el-table-column width="30" class-name="p-0">
          <template v-slot="{ row }">
            <action-menu
              :actions="getMenuActions(row)"
              @create-sub-line-item="lineItemToModifyAmount = row"
              @create-invoice="
                selectByIds(true, [row.id]) && $emit('create-invoice', row)
              "
            />
          </template>
        </el-table-column>
      </el-table>
    </template>
  </supplier-billing-mutation>
</template>

<script>
import ActionMenu from '@/components/Core/ActionMenu';
import SupplierNotesColumn from '@/components/Supplier/Ads/SupplierNotesColumn';
import SelectionsMixin from '@/components/Core/Table/SelectionsMixin';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import { SupplierBillingMutation } from '@/components/Mutations';

import {
  AdColumn,
  BillingAmountColumn,
  BillingStatusColumn,
  CustomerColumn,
  DateColumn,
  DiscountColumn,
  InvoiceListColumn,
  RepColumn
} from '@/components/Core/Table/Columns';

import { OrderLineItemType } from '@/constants';

export default {
  components: {
    ActionMenu,
    AdColumn,
    BillingAmountColumn,
    BillingStatusColumn,
    CustomerColumn,
    DateColumn,
    DiscountColumn,
    InvoiceListColumn,
    SupplierBillingMutation,
    SupplierNotesColumn,
    RepColumn,
    TableEmptySlot
  },
  mixins: [SelectionsMixin],
  props: {
    isLoading: Boolean,
    paginatorInfo: {
      type: Object,
      default: null
    },
    selectionsSummary: {
      type: Object,
      default: () => {}
    },
    lineItems: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      OrderLineItemType,
      lineItemToModifyAmount: null,
      selectedLineItem: null
    };
  },
  watch: {
    lineItems(newValue) {
      if (newValue) {
        this.tableData = newValue;
      }
    }
  },
  methods: {
    getMenuActions(lineItem) {
      return [
        {
          value: 'create-sub-line-item',
          label: 'Add Discount or Fee',
          disabled:
            !+lineItem.uninvoiced_amount ||
            lineItem.order.paymentMethod.is_credit_card
        },
        {
          value: 'create-invoice',
          label: 'Create Invoice'
        }
      ];
    },
    onCreateInvoice(selectByIds, lineItem) {
      this.$refs.table.toggleRowSelection(lineItem, true);
      selectByIds(true, lineItem.id);
      this.$emit('create-invoice', lineItem);
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-table__body-wrapper {
  height: 100%;
}

/deep/ td.p-0 {
  .cell {
    padding: 0 !important;

    div.flex {
      display: flex !important;
    }
  }
}
</style>
