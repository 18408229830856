var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{ref:"table",staticClass:"el-table-slim mt-6 w-full",attrs:{"data":_vm.isLoading ? null : _vm.invoices,"border":""},on:{"select-all":function($event){return _vm.$emit('select-all', $event)},"select":_vm.onSelect,"row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('table-empty-slot',{attrs:{"is-loading":_vm.isLoading,"has-data":!!_vm.invoices,"error-message":"There was a problem loading the invoices. Please try again later."},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex items-center justify-center h-full w-full"},[_c('div',{staticClass:"w-1/3"},[(_vm.isFiltered)?[_vm._v(" There are no Invoices that match your filter. ")]:[_vm._v(" No invoices created. You can create invoices by selecting ads on the Run Date tab and clicking \"Action,\" then \"Create Invoice.\" ")]],2)])]},proxy:true}])})]},proxy:true}])},[_c('el-table-column',{attrs:{"type":"selection","width":"45"}}),_c('el-table-column',{attrs:{"label":"Status","align":"center","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('invoice-status-column',{attrs:{"loading":_vm.isLoading,"invoice":row}})]}}])}),_c('el-table-column',{attrs:{"label":"ID","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.ref))]}}])}),_c('el-table-column',{attrs:{"label":"Customer","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('customer-column',{attrs:{"customer":row.customer || {
              buyer: {
                buyer_company: row.settings.customerPrimaryContact.name
              }
            },"campaign":row.campaign}})]}}])}),(_vm.showAdCount)?_c('el-table-column',{attrs:{"label":"Ad Count","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.documentLineItems.filter( function (i) { return i.type === _vm.DocumentLineItemTypeKeys.SALE; } ).length)+" ")]}}],null,false,2654031440)}):_vm._e(),_c('el-table-column',{attrs:{"label":"Issued Date","width":"105"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("date")(row.transaction_date))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Billing Period","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticClass:"text-center"},[_c('div',[_vm._v(_vm._s(_vm._f("date")(row.start_date))+" -")]),_c('div',[_vm._v(_vm._s(_vm._f("date")(row.end_date)))])])]}}])}),_c('el-table-column',{attrs:{"label":"Due Date","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [(row.status === _vm.InvoiceStatusKeys.DRAFT)?_c('div',{staticClass:"text-center"},[_c('div',[_c('el-tooltip',{attrs:{"effect":"light"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"text-md"},[_c('div',[_vm._v(" This invoice is inactive and will not show up in any reporting documents. ")]),_c('div',{staticClass:"mt-2"},[_vm._v(" To start tracking overdue status and reporting, please mark as sent. ")])])]},proxy:true}],null,true)},[_c('div',[_vm._v(" Invoice is inactive "),_c('icon',{staticClass:"ml-1",attrs:{"icon":_vm.infoIcon}})],1)])],1),_c('div',{staticClass:"mt-2"},[_c('a',{on:{"click":function($event){$event.stopPropagation();_vm.invoiceToShareId = row.id}}},[_vm._v("Mark As Sent")])])]):(row.status === _vm.InvoiceStatusKeys.VOID)?_c('div',[_vm._v(" N/A ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("date")(row.due_date))+" "),(row.status !== _vm.InvoiceStatusKeys.PAID)?_c('div',{staticClass:"mt-2"},[(_vm.daysPastDue(row) > 0)?_c('div',{staticClass:"text-red font-bold"},[_vm._v(" overdue by "+_vm._s(_vm.daysPastDue(row))+" days ")]):(_vm.daysPastDue(row) === 0)?_c('div',{staticClass:"font-bold text-orange"},[_vm._v(" due today ")]):(_vm.daysPastDue(row) === -1)?_c('div',{staticClass:"font-bold"},[_vm._v(" due tomorrow ")]):_c('div',[_vm._v("due in "+_vm._s(Math.abs(_vm.daysPastDue(row)))+" days")])]):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"label":"Terms","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.terms.text)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Balance","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(row.remaining_amount))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Total","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(row.amount))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Created Date","width":"115"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("date")(row.created_at))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Actions","width":"160","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [(!_vm.isDeleting(row))?_c('supplier-invoices-action-menu',{ref:"actionMenu",attrs:{"invoice":row,"all-actions":"","print-new-window":""},on:{"update:is-exporting":function($event){_vm.isExporting = $event ? row : null}}}):_vm._e(),(_vm.isExporting === row)?_c('el-tooltip',{attrs:{"effect":"light","content":"Exporting Invoice. This may take up to 30 seconds..."}},[_c('icon',{staticClass:"animated loop tada text-dark-silver",attrs:{"icon":_vm.exportIcon,"spin":""}})],1):_vm._e()]}}])})],1),_c('supplier-invoice-document-mutation',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var isSaving = ref.isSaving;
            var markInvoiceSent = ref.markInvoiceSent;
return [(_vm.invoiceToShareId)?_c('share-invoice-dialog',{attrs:{"invoice":_vm.invoiceToShare,"is-saving":isSaving},on:{"sent":function($event){return markInvoiceSent(_vm.invoiceToShare, $event)},"close":function($event){_vm.invoiceToShareId = null}}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }