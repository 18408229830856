<template>
  <div :style="{ width: actualWidth, height: actualHeight }" class="relative">
    <transition name="fade">
      <loader-box
        v-if="!isLoaded"
        :width="actualLoaderWidth"
        :height="actualLoaderHeight"
        :style="{ 'z-index': 1000 }"
        loader-class="m-auto"
      />
    </transition>
    <iframe
      ref="iframe"
      :src="src"
      :style="{
        border: 'none',
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: isLoaded ? 1 : 0,
        transition: 'all 1s'
      }"
      frameborder="0"
      scrolling="no"
      :width="actualWidth"
      :height="actualHeight"
    />
  </div>
</template>
<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    width: {
      type: [String, Number],
      default: '100%'
    },
    height: {
      type: [String, Number],
      default: 1000
    },
    loaderWidth: {
      type: [String, Number],
      default: null
    },
    loaderHeight: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      isLoaded: false,
      resizedWidth: null,
      resizedHeight: null
    };
  },
  computed: {
    actualHeight() {
      return this.stringSize(this.resizedHeight || this.height);
    },
    actualWidth() {
      return this.stringSize(this.resizedWidth || this.width);
    },
    actualLoaderHeight() {
      return this.stringSize(this.loaderHeight || this.actualHeight);
    },
    actualLoaderWidth() {
      return this.stringSize(this.loaderWidth || this.actualWidth);
    }
  },
  mounted() {
    // Signal we are not yet ready
    this.$emit('input', false);

    this.$refs.iframe.addEventListener('v4-event', e => {
      let payload = (e.detail && e.detail.payload) || {};

      switch (payload.event) {
        case 'close':
          this.$emit('close');
          break;

        case 'loaded':
          // Signal the iframe is loaded
          this.$emit('input', true);
          this.isLoaded = true;
          break;

        case 'size':
          // Resize the iframe to match its desired size
          if (payload.size) {
            if (payload.size.width) {
              // TODO issue w/ sizing width triggers loop
              // this.$refs.iframe.width = payload.size.width;
              // this.resizedWidth = payload.size.width;
            }

            if (payload.size.height) {
              this.resizedHeight = payload.size.height + 20;
              this.$refs.iframe.height = payload.size.height + 20;
            }
          }
          break;
      }
    });
  },
  methods: {
    stringSize(size) {
      if (('' + size).match(/^\d+$/)) {
        return size + 'px';
      } else {
        return size;
      }
    }
  }
};
</script>
