<template>
  <div class="supplier-invoices-summary dark-silver min-w-40">
    <div class="summary-item-list flex items-start">
      <template v-if="summary">
        <!-- Totals column -->
        <div class="p-3 flex">
          <div class="min-w-3.5">
            <summary-item label="Invoices" :amount="summary.total" />
          </div>
          <div class="ml-5 min-w-3.5">
            <summary-item
              label="Active"
              :amount="+summary.total_amount"
              currency
            />
          </div>
          <div class="ml-5 min-w-3.5">
            <summary-item
              label="In Draft"
              :amount="+summary.total_amount_draft"
              currency
            />
          </div>
          <div class="ml-5 min-w-3.5">
            <summary-item
              label="Upcoming"
              :amount="+summary.total_not_due"
              :color="colors.blue"
              currency
            />
          </div>
          <div class="ml-5 min-w-5.5">
            <summary-item
              label="Due in 30 days"
              :amount="+summary.total_due_30_days"
              :color="colors.green"
              currency
            />
          </div>
          <div class="ml-5 min-w-3.5">
            <summary-item
              label="Overdue"
              :amount="+summary.total_overdue"
              :color="colors.red"
              currency
            />
          </div>
          <div class="ml-5 min-w-3.5">
            <summary-item
              label="Unpaid"
              :amount="+summary.total_remaining_amount"
              currency
            />
          </div>
        </div>

        <div class="flex items-start ml-5 w-full">
          <!-- progress bar columns -->
          <div class="flex-1 p-3">
            <summary-item
              label="Total Paid"
              :color="colors.green"
              :amount="+summary.total_paid_amount"
              :total="+summary.total_amount"
              currency
              progress
            />
          </div>
        </div>
      </template>

      <template v-else>
        <div class="p-3 pb-5 flex">
          <loader-box height="4.55em" width="3.5em" />
          <loader-box height="4.55em" width="3.5em" class="ml-5" />
        </div>
        <div class="ml-5 w-full mr-5 flex items-start">
          <loader-box height="4.55em" class="flex-1 p-3" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SummaryItem from './SummaryItem';
import { colors } from '@/tailwind';

export default {
  components: {
    SummaryItem
  },
  props: {
    summary: {
      type: Object,
      default: null
    }
  },
  data() {
    return { colors };
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 1500px) {
  .summary-item-list {
    flex-wrap: wrap;
  }
}
</style>
