var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('supplier-billing-mutation',{attrs:{"paginator-info":_vm.paginatorInfo,"displayed-line-items":_vm.lineItems,"selections-summary":_vm.selectionsSummary},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var isSavingBilling = ref.isSaving;
    var selectByIds = ref.selectByIds;
    var selectByFilter = ref.selectByFilter;
    var updateLineItem = ref.updateLineItem;
return [_c('el-table',{ref:"table",staticClass:"el-table-slim w-full",attrs:{"data":_vm.isLoading ? null : _vm.lineItems,"border":""},on:{"select-all":function($event){return _vm.onSelectAll(selectByFilter, $event)},"select":function($event){
    var i = arguments.length, argsArray = Array(i);
    while ( i-- ) argsArray[i] = arguments[i];
return _vm.onSelect.apply(void 0, [ selectByIds ].concat( argsArray ))},"row-click":function($event){
    var i = arguments.length, argsArray = Array(i);
    while ( i-- ) argsArray[i] = arguments[i];
return _vm.onRowClick.apply(void 0, [ selectByIds ].concat( argsArray ))}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('table-empty-slot',{attrs:{"is-loading":_vm.isLoading,"has-data":!!_vm.lineItems,"error-message":"There was a problem loading the Line Items. Please try again later."},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex items-center justify-center h-full w-full"},[_vm._v(" You don't have any Ads running based on the filters you've selected. ")])]},proxy:true}],null,true)})]},proxy:true}],null,true)},[_c('el-table-column',{attrs:{"type":"selection","width":"45"}}),_c('el-table-column',{attrs:{"label":"Status","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('billing-status-column',{attrs:{"campaign":row.order.campaign,"line-item":row,"loading":isSavingBilling},on:{"change":function($event){return updateLineItem(row.order.id, row, { status: $event })}}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Order ID","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(row.order.ref)+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Customer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('customer-column',{attrs:{"customer":row.order.customer,"campaign":row.order.campaign}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Rep"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('rep-column',{attrs:{"order":row.order}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Ad Details"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [(row.type === _vm.OrderLineItemType.AD && row.ads.length > 0)?_c('ad-column',{attrs:{"ad":row.ads[0],"order-line-item":row}}):(row.type === _vm.OrderLineItemType.DISCOUNT)?_c('discount-column',{attrs:{"order-discount":row.discount}}):_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Run Date(s)","align":"center","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('date-column',{attrs:{"start-date":row.ads[0] ? row.ads[0].start_date : row.active_date,"end-date":row.ads[0] ? row.ads[0].end_date : null}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Date Created","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("date")(row.created_at))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Amount","align":"center","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
    var $index = ref.$index;
return [_c('billing-amount-column',{attrs:{"tab-index":$index + 1,"line-item":row,"show":_vm.lineItemToModifyAmount},on:{"update:show":function($event){_vm.lineItemToModifyAmount=$event}}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Invoices","align":"right","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
    var $index = ref.$index;
return [_c('invoice-list-column',{attrs:{"line-item":row},on:{"create-invoice":function($event){return _vm.onCreateInvoice(selectByIds, row)}}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Comments","min-width":"35px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('supplier-notes-column',{attrs:{"ads":row.ads,"order":row.order}})]}}],null,true)}),_c('el-table-column',{attrs:{"width":"30","class-name":"p-0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('action-menu',{attrs:{"actions":_vm.getMenuActions(row)},on:{"create-sub-line-item":function($event){_vm.lineItemToModifyAmount = row},"create-invoice":function($event){selectByIds(true, [row.id]) && _vm.$emit('create-invoice', row)}}})]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }