<template>
  <div>
    <campaign-invoiced-ads-query :campaign="campaign" @data="onInvoicedAds" />

    <confirm-dialog
      confirm-class="button-red"
      :title="'Cancel All Ads in Campaign ' + campaign.ref"
      :disabled="!cancelableAdsCount"
      :confirm-text="
        pager ? 'Cancel ' + cancelableAdsCount + ' Ads' : 'Loading Campaign...'
      "
      cancel-text="Nevermind"
      :is-saving="isSaving"
      @confirm="$emit('cancel')"
      @close="$emit('close')"
    >
      <template v-if="pager">
        <div v-if="invoicedAds.length === 0">
          <template v-if="cancelableAdsCount > 0">
            <div>Are you sure you want to cancel all ads in this campaign?</div>
            <div class="mt-3">
              This will cancel {{ cancelableAdsCount }} ads and their associated
              billing line items, and the campaign status will change to
              canceled.
            </div>
          </template>
          <template v-else>
            All the Ads in this campaign are already canceled
          </template>
        </div>
        <div v-else>
          <div v-if="cancelableAdsCount > 0">
            This will cancel {{ cancelableAdsCount }} / {{ pager.total }} ads
            and their associated billing line items.
          </div>
          <div v-else>
            There are no ads that are allowed to be canceled at this time.
          </div>
          <div class="mt-3">
            The following ads have already been added to an invoice and
            therefore will not be canceled:
            <div class="ml-5 mt-2">
              <div
                v-for="ad in invoicedAds"
                :key="'cancel-ad-' + ad.id"
                class="flex items-center"
              >
                <b>{{ ad.ref }}</b>
                <div class="ml-1">- {{ ad.sku }}</div>
                <status-icon
                  class="ml-3"
                  :icons="AdStatusInfo"
                  :status="ad.status"
                  inline
                />
              </div>
            </div>
          </div>
          <div class="mt-3">
            To cancel paid or past ads you’ll need to contact the publisher to
            do so manually.
          </div>
        </div>
      </template>
      <template v-else>
        <loader-box />
      </template>
    </confirm-dialog>
  </div>
</template>
<script>
import { AdStatusInfo } from '@/constants';

import CampaignInvoicedAdsQuery from '@/components/Queries/Buyer/Campaign/CampaignInvoicedAdsQuery';
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import StatusIcon from '@/components/Core/Statuses/StatusIcon';

export default {
  components: { StatusIcon, CampaignInvoicedAdsQuery, ConfirmDialog },
  props: {
    isSaving: Boolean,
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      pager: null,
      invoicedAds: null,

      // Consts
      AdStatusInfo
    };
  },
  computed: {
    cancelableAdsCount() {
      return this.pager ? this.pager.total - this.invoicedAds.length : 0;
    }
  },
  methods: {
    onInvoicedAds(data) {
      this.invoicedAds = data.buyer.campaign.invoicedAds;
      this.pager = data.buyer.campaign.ads.paginatorInfo;
    }
  }
};
</script>
