<template>
  <el-popover placement="bottom-end" trigger="click" popper-class="p-0 py-3">
    <div
      slot="reference"
      class="text-blue hover:text-dark-blue flex items-center justify-center cursor-pointer w-f"
      @click.stop
    >
      <el-button
        v-if="button"
        class="border-gray group hover:border-dark-silver text-lg"
      >
        <icon
          :icon="ellipsisIcon"
          class="icon-lg text-dark-silver group-hover:text-gunmetal"
        />
      </el-button>
      <div
        v-else
        class="text-blue hover:text-dark-blue flex items-center justify-center cursor-pointer p-4"
      >
        <icon class="icon-lg" :icon="ellipsisIcon" />
      </div>
    </div>
    <div>
      <div
        v-for="action in actions"
        :key="`action-item-${action.value || index}`"
        class="text-left"
      >
        <el-button
          class="w-full border-none text-left"
          :class="action.class || 'text-dark-gray hover:text-blue'"
          :disabled="!!action.disabled"
          @click="$emit(action.value) && $emit('action', action.value)"
        >
          {{ action.label }}
        </el-button>
      </div>
      <slot />
    </div>
  </el-popover>
</template>

<script>
import { ellipsisV as ellipsisIcon } from '@/vendor/icons';

export default {
  props: {
    item: {
      type: Object,
      default: null
    },
    actions: {
      type: Array,
      default: () => []
    },
    button: Boolean
  },
  data() {
    return {
      ellipsisIcon
    };
  }
};
</script>
