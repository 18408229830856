<template>
  <document-exporter :url="ioUrl" :name="ioName">
    <template v-slot="{ exportToPdf, isExporting }">
      <a
        :class="linkClass"
        :href="ioUrl"
        target="_blank"
        @click.prevent="onClick(exportToPdf)"
      >
        <slot :is-exporting="isExporting">
          <template v-if="button">
            <el-button v-if="!isExporting" class="w-full" :class="buttonClass">
              {{ text }}
            </el-button>
            <loading-button v-else class="w-full" />
          </template>
          <template v-else-if="menuItem">
            <el-button
              class="button-plain hover:text-blue w-full"
              :class="buttonClass"
              :disabled="isExporting"
            >
              <template v-if="!isExporting">
                {{ text }}
              </template>
              <template v-else>
                Downloading...
                <icon :icon="downloadingIcon" spin />
              </template>
            </el-button>
          </template>
          <template v-else>
            <template v-if="!isExporting">
              {{ text }}
            </template>
            <template v-else>
              Downloading...
              <icon :icon="downloadingIcon" spin />
            </template>
          </template>
        </slot>
      </a>
    </template>
  </document-exporter>
</template>

<script>
import DocumentExporter from '@/components/Core/DocumentExporter';
import { arrowsCw as downloadingIcon } from '@/vendor/icons';

export default {
  components: { DocumentExporter },
  props: {
    linkClass: {
      type: [Object, String],
      default: ''
    },
    text: {
      type: String,
      default: 'Download IO'
    },
    button: Boolean,
    menuItem: Boolean,
    buttonClass: {
      type: String,
      default: ''
    },
    order: { type: Object, default: null },
    disable: Boolean,
    target: {
      type: String,
      default: '_blank'
    }
  },
  data() {
    return {
      // Icons
      downloadingIcon
    };
  },
  computed: {
    ioName() {
      return (
        this.order.ref +
        (this.order.campaign ? '-' + this.order.campaign.name : '') +
        '.pdf'
      );
    },
    ioUrl() {
      if (this.order) {
        return this.$router.resolve({
          name: 'accounting.io',
          params: { id: this.order.id, supplier_id: this.order.supplier_id }
        }).href;
      } else {
        return '';
      }
    }
  },
  methods: {
    async onClick(exportToPdf) {
      if (!this.disable && this.order) {
        await exportToPdf();
      }

      this.$emit('action');
      return true;
    }
  }
};
</script>
