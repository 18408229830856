<template>
  <div
    class="cursor-pointer stacked-list inline-block"
    :class="{ 'spread-on-hover': spreadOnHover, spread }"
    @click="$emit('click')"
  >
    <div
      v-for="(file, index) in filteredFiles"
      :key="`file-id-${instanceId}-${file.id}-${index}`"
      class="stack-item"
    >
      <file-item
        v-if="spreadOnHover || index < maxCount"
        class="bg-white rounded shadow"
        :file="file"
        :width="width"
        :height="height"
        :enable-preview="enablePreview"
        :can-download="canDownload"
        :show-filename="false"
        :group="true"
        @click="$emit('click')"
      />
    </div>
    <div v-if="filteredFiles.length > 1" class="stack-count">
      {{ filteredFiles.length }}
    </div>
  </div>
</template>

<script>
import FileItem from '@/components/Core/FileItem';

export default {
  components: {
    FileItem
  },
  props: {
    files: {
      type: Array,
      default: () => []
    },
    width: {
      type: [String, Number],
      default: '8em'
    },
    height: {
      type: [String, Number],
      default: '6em'
    },
    canDownload: Boolean,
    enablePreview: Boolean,
    spreadOnHover: Boolean,
    spread: Boolean,
    maxCount: {
      type: Number,
      default: 3
    }
  },
  computed: {
    instanceId() {
      return this._uid;
    },
    filteredFiles() {
      return this.files.filter(f => !!f);
    }
  }
};
</script>
