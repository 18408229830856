<template>
  <div class="form-response-value-preview w-full" @click="$emit('click')">
    <div v-if="files" class="form-response-files">
      <file-stack
        :files="files"
        :can-download="canDownload"
        :enable-preview="enablePreview"
        :spread-on-hover="spreadOnHover"
        @click="$emit('click', $event)"
      />
    </div>
    <div v-else-if="canRender" class="overflow-hidden">
      <div v-if="label" class="form-field-title font-semibold text-center">
        {{ label }}
      </div>
      <div class="form-field-value text-center">
        <template v-if="type === BuilderFields.ICON.type">
          <status-icon :icons="icons" :status="value" />
        </template>
        <template v-else-if="type === BuilderFields.CURRENCY.type">
          {{ value | currency }}
        </template>
        <template v-else-if="type === BuilderFields.NUMBER.type">
          {{ value | number({ precision: 8, minPrecision: 0 }) }}
        </template>
        <template v-else-if="type === BuilderFields.INTEGER.type">
          {{ value | integer }}
        </template>
        <template v-else-if="type === BuilderFields.BOOLEAN.type">
          {{ value | boolean }}
        </template>
        <template v-else-if="type === BuilderFields.DATE.type">
          {{ value | date }}
        </template>
        <template v-else-if="type === BuilderFields.DATE_RANGE.type">
          {{ value[0] | date }} to
          {{ value[1] | date }}
        </template>
        <template v-else-if="type === BuilderFields.WYSIWYG.type">
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="value" />
          <!--eslint-enable-->
        </template>
        <template v-else>
          {{ value }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { BuilderFields } from '@/constants';
import FileStack from '@/components/Core/FileStack';
import StatusIcon from '@/components/Core/Statuses/StatusIcon';

const TEXT_PREVIEWABLE_TYPES = [
  BuilderFields.BOOLEAN,
  BuilderFields.CURRENCY,
  BuilderFields.DATE,
  BuilderFields.ICON,
  BuilderFields.DATE_RANGE,
  BuilderFields.INTEGER,
  BuilderFields.NUMBER,
  BuilderFields.TEXT,
  BuilderFields.WYSIWYG
];

export default {
  components: { StatusIcon, FileStack },
  props: {
    type: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [Object, Array, Number, String, Boolean],
      default: null
    },
    icons: {
      type: Object,
      default: null
    },
    canDownload: Boolean,
    enablePreview: Boolean,
    spreadOnHover: Boolean
  },
  data() {
    return {
      BuilderFields
    };
  },
  computed: {
    files() {
      switch (this.type) {
        case BuilderFields.SINGLE_FILE.type:
          return [this.value];
        case BuilderFields.MULTI_FILE.type:
          return this.value;
      }

      return null;
    },
    canRender() {
      // If the field value has not been set, we cannot render it
      if (this.value === null) {
        return false;
      }

      return !!TEXT_PREVIEWABLE_TYPES.find(t => t.type === this.type);
    }
  }
};
</script>
