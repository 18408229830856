<template>
  <div class="supplier-invoices-action-menu flex items-center">
    <div v-if="!hideActionButton">
      <el-button
        v-if="isDraft"
        size="small"
        class="button-blue w-36"
        @click="showShareInvoiceDialog = true"
      >
        Mark As Sent
      </el-button>

      <el-button
        v-if="isUnpaid"
        size="small"
        class="button-green w-36"
        @click="showPaymentDialog = true"
      >
        Record Payment
      </el-button>

      <el-button
        v-if="isPaid || isVoided"
        size="small"
        class="button-green w-36"
        @click="viewInvoice"
      >
        View Invoice
      </el-button>
    </div>

    <supplier-invoice-document-mutation class="flex-grow">
      <template v-slot="{ isSaving, voidInvoice, deleteInvoice }">
        <invoice-exporter
          @update:is-exporting="$emit('update:is-exporting', $event)"
        >
          <template v-slot="{ exportInvoice }">
            <action-menu
              :button="button"
              :actions="menuActions"
              @view="viewInvoice"
              @payment="showPaymentDialog = true"
              @share="showShareInvoiceDialog = true"
              @print="printInvoice"
              @export="exportInvoice(invoice)"
              @void="showConfirmVoidDialog = true"
              @delete="showConfirmDeleteDialog = true"
            />

            <confirm-dialog
              v-if="showConfirmVoidDialog"
              title="Void Invoice"
              confirm-class="button-red"
              confirm-text="Void"
              :is-saving="isSaving"
              @confirm="
                voidInvoice(invoice).then(() => (showConfirmVoidDialog = false))
              "
              @close="showConfirmVoidDialog = false"
            >
              Are you sure you want to void this Invoice?
            </confirm-dialog>

            <confirm-dialog
              v-if="showConfirmDeleteDialog"
              title="Delete Invoice"
              confirm-class="button-red"
              confirm-text="Delete"
              :is-saving="isSaving"
              @confirm="onDeleteInvoice(deleteInvoice)"
              @close="showConfirmDeleteDialog = false"
            >
              Are you sure you want to permanently delete this Invoice? This
              action cannot be undone.
            </confirm-dialog>
          </template>
        </invoice-exporter>
      </template>
    </supplier-invoice-document-mutation>

    <supplier-billing-mutation v-if="showPaymentDialog">
      <template
        v-slot="{
          isSaving,
          addInvoicePayment,
          voidInvoicePayment
        }"
      >
        <invoice-payment-dialog
          :is-loading="isSaving"
          :invoice="invoice"
          :is-voided="isVoided"
          @save="addInvoicePayment(invoice, $event)"
          @close="showPaymentDialog = false"
          @void-transaction="voidInvoicePayment(invoice, $event)"
        />
      </template>
    </supplier-billing-mutation>

    <supplier-invoice-document-mutation>
      <template v-slot="{ isSaving, markInvoiceSent }">
        <share-invoice-dialog
          v-if="showShareInvoiceDialog"
          :invoice="invoice"
          :is-saving="isSaving"
          @sent="markInvoiceSent(invoice, $event)"
          @close="showShareInvoiceDialog = false"
        />
      </template>
    </supplier-invoice-document-mutation>

    <div ref="invoiceToPrint" class="printable-invoice">
      <invoice-document v-if="showInvoiceForPrint" :invoice="invoice" />
    </div>
  </div>
</template>

<script>
import ActionMenu from '@/components/Core/ActionMenu';
import InvoiceDocument from '@/components/Supplier/Billing/Document/InvoiceDocument/InvoiceDocument';
import InvoiceExporter from '@/components/Supplier/Invoices/InvoiceExporter';
import InvoicePaymentDialog from '@/components/Payment/InvoicePaymentDialog';
import { printElement } from '@/utils/helpers';
import { SupplierBillingMutation } from '@/components/Mutations';
import SupplierInvoiceDocumentMutation from '@/components/Mutations/Supplier/SupplierInvoiceDocumentMutation';
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import ShareInvoiceDialog from '@/components/Supplier/Invoices/ShareInvoiceDialog';
import { InvoiceStatusKeys } from '@/constants';

export default {
  components: {
    ShareInvoiceDialog,
    ConfirmDialog,
    SupplierInvoiceDocumentMutation,
    InvoiceDocument,
    InvoiceExporter,
    ActionMenu,
    InvoicePaymentDialog,
    SupplierBillingMutation
  },
  props: {
    // Data
    invoice: {
      type: Object,
      required: true
    },

    // Actions
    allActions: Boolean,
    deleteAction: Boolean,
    exportAction: Boolean,
    payAction: Boolean,
    printAction: Boolean,
    shareAction: Boolean,
    viewAction: Boolean,
    voidAction: Boolean,

    // Style / Behavior
    hideActionButton: Boolean,
    printNewWindow: Boolean,
    button: Boolean
  },
  data() {
    return {
      // Consts
      InvoiceStatusKeys,

      // Dialogs
      showConfirmDeleteDialog: false,
      showConfirmVoidDialog: false,
      showInvoiceForPrint: false,
      showPaymentDialog: false,
      showShareInvoiceDialog: false
    };
  },
  computed: {
    isVoided() {
      return this.invoice.status === InvoiceStatusKeys.VOID;
    },
    isDraft() {
      return this.invoice.status === InvoiceStatusKeys.DRAFT;
    },
    isPaid() {
      return this.invoice.status === InvoiceStatusKeys.PAID;
    },
    isUnpaid() {
      return [
        InvoiceStatusKeys.CHARGED,
        InvoiceStatusKeys.FAILED_PAYMENT,
        InvoiceStatusKeys.PARTIALLY_PAID,
        InvoiceStatusKeys.SENT
      ].includes(this.invoice.status);
    },
    canManage() {
      return (
        this.$can('pub_manage_invoices') && this.invoice.is_tracked_by_source
      );
    },
    canRecordPayment() {
      return this.$can('pub_manage_invoices');
    },
    canDelete() {
      return (
        this.$can('pub_delete_invoices') && this.invoice.is_tracked_by_source
      );
    },
    menuActions() {
      const actions = [];

      if (this.allActions || this.viewAction) {
        actions.push({
          label: this.canManage ? 'View / Edit Invoice' : 'View Invoice',
          value: 'view'
        });
      }

      if ((this.allActions || this.payAction) && this.canRecordPayment) {
        actions.push({
          label: this.isVoided ? 'View Payments' : 'Record Payment',
          value: 'payment'
        });
      }

      if (this.allActions || this.shareAction) {
        actions.push({
          label: 'Share Invoice',
          value: 'share'
        });
      }

      if (this.allActions || this.printAction) {
        actions.push({ label: 'Print Invoice', value: 'print' });
      }

      if (this.allActions || this.exportAction) {
        actions.push({ label: 'Export PDF', value: 'export' });
      }

      if (
        (this.allActions || this.voidAction) &&
        !this.isVoided &&
        this.canManage
      ) {
        actions.push({ label: 'Void Invoice', value: 'void' });
      }

      if (this.canDelete && (this.allActions || this.deleteAction)) {
        actions.push({
          label: 'Delete Invoice',
          value: 'delete',
          class: 'text-red hover:text-dark-red'
        });
      }

      return actions;
    }
  },
  methods: {
    viewInvoice() {
      this.$router.push({
        name: 'supplier.invoice',
        params: { id: this.invoice.id }
      });
    },

    async onDeleteInvoice(deleteInvoice) {
      await deleteInvoice(this.invoice);

      this.showConfirmDeleteDialog = false;

      this.$message.warning(
        `Invoice ${this.invoice.ref} was deleted and no longer exists.`
      );

      this.$emit('delete');
    },
    printInvoice() {
      if (this.printNewWindow) {
        this.showInvoiceForPrint = true;

        this.$nextTick(async () => {
          await printElement(this.$refs.invoiceToPrint);

          this.showInvoiceForPrint = false;
        });
      } else {
        window.print();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.printable-invoice {
  position: fixed;
  top: -9999px;
  left: -9999px;
}
</style>
