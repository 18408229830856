<template>
  <el-select
    :value="ad ? ad.tags.map(tag => tag.name) : []"
    filterable
    allow-create
    collapse-tags
    multiple
    placeholder="Click to set Tags"
    @change="onChange"
  >
    <el-option
      v-for="option in options"
      :key="`tag-${option.id}`"
      :label="option.name"
      :value="option.name"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  props: {
    ad: {
      type: Object,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tags: this.ad.tags
    };
  },
  methods: {
    onChange(event) {
      this.$emit('change', event);
    }
  }
};
</script>
