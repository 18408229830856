var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ads-mutation',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var adIsMutating = ref.loading;
var setAdNote = ref.setAdNote;
return [_c('supplier-orders-mutation',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var orderIsMutating = ref.loading;
var setOrderNote = ref.setOrderNote;
return [_c('div',{staticClass:"flex flex-col items-center justify-center text-blue hover:text-dark-blue cursor-pointer p-4",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.dialogVisible = true}}},[_c('icon',{staticClass:"icon-lg",attrs:{"icon":_vm.chatIcon},on:{"click":function($event){_vm.dialogVisible = true}}}),(_vm.noteCount > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.noteCount)+" ")]):_vm._e()],1),_c('note-dialog',{attrs:{"is-saving":adIsMutating || orderIsMutating,"visible":_vm.dialogVisible || _vm.startOpen,"start-on-order":_vm.startOnOrder,"ads":_vm.ads,"order":_vm.order},on:{"close":function($event){_vm.$emit('close', true);
            _vm.dialogVisible = false;},"saveAdNote":function($event){return setAdNote($event.id, { text: $event.note })},"saveOrdernote":function($event){return setOrderNote(_vm.order.id, { text: $event })}}})]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }