<template>
  <div>
    <el-popover trigger="hover">
      <a slot="reference" class="buyer inline-block">
        <icon v-if="supplier.is_test" :icon="testIcon" class="mr-1" />
        <div v-if="campus">{{ campus.name }}</div>
        <div>{{ supplier.name }}</div>
      </a>

      <div class="buyer-popover">
        <h3 class="buyer-company">
          {{ supplier.name }}
        </h3>
        <h4 class="mt-4">Reps:</h4>
        <template v-if="reps && reps.length">
          <div v-for="rep in reps" :key="`rep-${rep.id}`" class="mt-4">
            <contact-info :contact="rep" />
          </div>
        </template>
        <div v-else-if="supplier.primaryContact" class="mt-4">
          <contact-info :contact="supplier.primaryContact" />
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { lab as testIcon } from '@/vendor/icons';
import ContactInfo from '@/components/Core/ContactInfo';

export default {
  components: {
    ContactInfo
  },
  props: {
    reps: {
      type: Array,
      required: true
    },

    campus: {
      type: Object,
      default: null
    },
    supplier: {
      type: Object,
      required: true
    }
  },
  data() {
    return { testIcon };
  }
};
</script>
