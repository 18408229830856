<template>
  <tween class="mr-6 text-dark-silver italic" :value="count || 0">
    <template v-slot="{ value }">
      <transition name="fade">
        <div v-if="count > 0 || isSaving" class="leading-tight">
          <slot>
            <div>{{ value | shortNumber }} Selected</div>
          </slot>
          <div class="text-sm text-center">
            <a v-if="!isSaving" @click="$emit('clear', $event)">
              <slot name="clear-text">Clear Selections</slot>
            </a>
            <div v-else>
              <slot name="clear-text-saving">
                Clearing...
                <icon :icon="loadingIcon" class="animated rotate infinite" />
              </slot>
            </div>
          </div>
        </div>
      </transition>
    </template>
  </tween>
</template>

<script>
import { arrowsCw as loadingIcon } from '@/vendor/icons';

export default {
  props: {
    count: {
      type: Number,
      default: 0
    },
    isSaving: Boolean
  },
  data() {
    return {
      loadingIcon
    };
  }
};
</script>
