<template>
  <div class="order-line-item-supplier-price-adjuster">
    <supplier-billing-mutation>
      <template
        v-slot="{
          isSaving,
          createSubLineItem,
          deleteSubLineItem
        }"
      >
        <template v-if="!disabled && !locked">
          <div class="mb-1">
            <el-input
              v-mask.currency
              :tabindex="'' + tabIndex"
              :value="lineItem.supplier_price"
              @change="onAdjustAmount(createSubLineItem, $event)"
            />
          </div>
        </template>
        <template v-else>
          <el-tooltip :content="lockedTitle" effect="light">
            <div class="text-right">
              <div>
                Gross: <b>{{ grossEarnings | currency }}</b>
              </div>
              <div>
                Commission: <b>{{ lineItem.commission | percent }}</b>
              </div>
              <div>
                Earnings: <b>{{ lineItem.pay_supplier | currency }}</b>
              </div>
            </div>
          </el-tooltip>
        </template>

        <div
          v-if="!disabled"
          class="sub-line-items"
          :class="subLineClass"
          @click.stop
        >
          <a v-if="!locked && subLineItemCount === 0" @click="toggleShow(true)">
            + Discount or Fee
          </a>
          <el-popover v-else-if="subLineItemCount > 0" trigger="click">
            <div slot="reference">
              <a>
                {{ subLineItemCount }}
                {{
                  subLineItemCount === 1
                    ? 'Discount or Fee'
                    : 'Discounts / Fees'
                }}
              </a>
            </div>
            <div>
              <div class="py-5">
                <div class="text-lg text-dark-silver">
                  Gross Earnings: {{ grossEarnings | currency }}
                </div>
                <div
                  v-if="+totalSubLineItems"
                  class="text-lg text-dark-silver mt-1"
                >
                  Total Adjustments:
                  {{ totalSubLineItems | currency }}
                </div>
                <div v-if="isNational" class="text-lg text-dark-silver mt-1">
                  Flytedesk Commission:
                  {{ -nationalCommission | currency }}
                </div>
                <div class="text-lg  text-dark-green mt-1">
                  Net Earnings: {{ lineItem.pay_supplier | currency }}
                </div>
              </div>
              <sub-line-items-table
                :locked="locked"
                :sub-line-items="subLineItems"
                @delete="onDeleteSubLineItem(deleteSubLineItem, $event)"
              />

              <a
                v-if="!locked"
                class="mt-5 border-dashed border-width-1 border-color-blue block p-3 hover:bg-light-silver"
                @click="toggleShow(true)"
              >
                + Add Discount or Fee
              </a>
            </div>
          </el-popover>
        </div>

        <sub-line-item-dialog
          v-if="showDialog && !disabled && !locked"
          :line-item="lineItem"
          :is-loading="isSaving"
          @close="toggleShow(false)"
          @save="onCreateSubLineItem(createSubLineItem, $event)"
        />

        <confirm-dialog
          v-if="confirmCancelAdjustmentDialog"
          title="Modify existing Draft Invoices?"
          confirm-text="Modify Existing Draft Invoices"
          confirm-class="button-red"
          @confirm="confirmCancelAdjustmentDialog.confirm"
          @close="confirmCancelAdjustmentDialog.cancel"
        >
          <div>
            This line item's modified amount will also be reflected in
            {{
              lineItem.invoiced_draft_count === 1
                ? `its associated draft invoice's line item`
                : `${lineItem.invoiced_draft_count} associated draft invoices' line items`
            }}
            , which will affect the
            {{
              lineItem.invoiced_draft_count === 1
                ? `invoice's total amount due`
                : `total amount due on each invoice`
            }}
            . This is required so the line item will be paid in full by the
            final invoice.

            <div class="mt-5">Are you sure you want to continue?</div>
          </div>
        </confirm-dialog>
      </template>
    </supplier-billing-mutation>
  </div>
</template>
<script>
import { currency, float } from '@/utils/filters';

import SupplierBillingMutation from '@/components/Mutations/Supplier/SupplierBillingMutation';
import SubLineItemDialog from '@/components/Supplier/Billing/SubLineItemDialog';
import SubLineItemsTable from '@/components/Order/SubLineItemsTable';
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import { OrderSubLineItemType } from '@/constants';

export default {
  components: {
    ConfirmDialog,
    SupplierBillingMutation,
    SubLineItemDialog,
    SubLineItemsTable
  },
  props: {
    // Toggle to show line item dialog (pass in actual line item)
    show: {
      type: Object,
      default: null
    },
    tabIndex: { type: Number, default: null },
    locked: Boolean,
    lockedTitle: {
      type: String,
      default: 'Modifying this amount has been locked'
    },
    disabled: Boolean,
    skipAdjustmentWarning: Boolean,
    lineItem: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      default: null
    },
    subLineClass: {
      type: String,
      default: ''
    },
    isNational: Boolean
  },
  data() {
    return {
      confirmCancelAdjustmentDialog: null
    };
  },
  computed: {
    liOrder() {
      return this.order || this.lineItem.order;
    },
    showDialog() {
      return this.show && this.show.id === this.lineItem.id;
    },
    subLineItemCount() {
      return this.subLineItems.length;
    },
    hasDraftInvoices() {
      return this.lineItem.invoiced_draft_count > 0;
    },
    subLineItems() {
      let subLineItems = this.lineItem.subLineItems || [];

      if (this.isNational) {
        return subLineItems.filter(
          sli => sli.name !== OrderSubLineItemType.ADJUSTMENT.label
        );
      } else {
        return subLineItems;
      }
    },
    totalSubLineItems() {
      return this.subLineItems.reduce((acc, sli) => +sli.amount + acc, 0);
    },
    nationalCommission() {
      return (
        (1 - this.lineItem.commission) *
        (+this.lineItem.delivery_supplier_price ||
          +this.lineItem.supplier_price)
      );
    },
    grossEarnings() {
      return this.isNational
        ? (+this.lineItem.delivery_supplier_price ||
            +this.lineItem.supplier_price) - this.totalSubLineItems
        : +this.lineItem.base_amount;
    }
  },

  methods: {
    toggleShow(show) {
      // Using actual lineItem for convenience when working with lists of line items
      this.$emit('update:show', show ? this.lineItem : null);
    },
    async confirmModifySubLineItem() {
      if (!this.skipAdjustmentWarning && this.hasDraftInvoices) {
        return new Promise(resolve => {
          this.confirmCancelAdjustmentDialog = {
            confirm: () => {
              resolve(true);
            },
            cancel: () => {
              resolve(false);
              // A little hack to revert the input field back to the original value
              this.lineItem.supplier_price = +this.lineItem.supplier_price;
            }
          };
        }).finally(() => {
          this.confirmCancelAdjustmentDialog = null;
        });
      } else {
        return true;
      }
    },
    async onAdjustAmount(createSubLineItem, amount) {
      if (await this.confirmModifySubLineItem()) {
        await createSubLineItem(this.liOrder, this.lineItem, {
          name: 'Adjustment',
          description: 'Manually adjusted amount for Ad',
          amount: -1 * (this.lineItem.supplier_price - float(amount))
        });
      }
    },
    async onCreateSubLineItem(createSubLineItem, input) {
      if (await this.confirmModifySubLineItem()) {
        await createSubLineItem(this.liOrder, this.lineItem, input);

        const amount = ('' + input.amount).replace(/[$,]/g, '');

        this.$message.success(
          `Created ${amount > 0 ? 'Fee' : 'Discount'} for ${currency(amount)}`
        );
      }

      this.toggleShow(false);
    },
    async onDeleteSubLineItem(deleteSubLineItem, subLineItem) {
      if (await this.confirmModifySubLineItem()) {
        await deleteSubLineItem(this.liOrder, this.lineItem, subLineItem);
      }
    }
  }
};
</script>
