<template>
  <io-download-link
    :order="order"
    :disable="!download"
    class="inline-block"
    :link-class="iconClass"
    @action="$emit('action')"
  >
    <template #default="{isExporting}">
      <div :style="isExporting ? { opacity: 0.7 } : {}" class="relative">
        <div>
          <icon :icon="icon" class="text-2" />
        </div>
        <div v-if="campaign && showCampaignRef">#{{ campaign.ref }}</div>
        <div v-else>#{{ order.ref }}</div>
        <div v-if="campaign">
          {{ campaign.name }}
        </div>

        <div v-if="isExporting" class="export-icon text-dark-silver">
          <icon :icon="loadingIcon" spin />
        </div>
      </div>
    </template>
  </io-download-link>
</template>

<script>
import { arrowsCw as loadingIcon, filePdf } from '@/vendor/icons';
import IoDownloadLink from '@/components/Order/IoDownloadLink';

export default {
  components: { IoDownloadLink },
  props: {
    iconClass: {
      type: [Object, String],
      default: ''
    },
    showCampaignRef: Boolean,
    campaign: { type: Object, default: null },
    order: {
      type: Object,
      required: true
    },
    icon: {
      type: Object,
      default: () => filePdf
    },
    download: Boolean
  },
  data() {
    return {
      loadingIcon
    };
  }
};
</script>

<style scoped lang="scss">
.export-icon {
  position: absolute;
  top: 2em;
  left: 2em;
}
</style>
