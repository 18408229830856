<template>
  <div class="ad-column">
    <div v-if="nationalTagPathName">
      {{ nationalTagPathName }}
    </div>
    <div v-else-if="ad.orderProduct.is_ssp_product">
      {{ ad.orderProduct.product_variant_name }}
    </div>
    <template v-else>
      <div class="medium">{{ product.medium.name }}</div>
      <div class="property">{{ product.property.name }}</div>
      <div class="collection">{{ product.collection.name }}</div>
      <div class="product">
        {{ productName }}
      </div>
    </template>
    <div class="ad-id">
      <template v-if="$can('manage_non_account_ads')">
        <a target="_blank" :href="novaUrl">Ad #{{ ad.ref }}</a>
      </template>
      <template v-else> Ad #{{ ad.ref }}</template>
    </div>
    <div v-if="ad.adGroup && $can('manage_non_account_ads')" class="ad-group">
      Ad Group: {{ ad.adGroup.name }}
    </div>
    <div v-if="lineItem && lineItem.quantity" class="whitespace-no-wrap">
      Quantity: {{ lineItem.quantity | number(0) }}
      {{ lineItem.unit }}
    </div>
    <div v-if="ad.production_size">
      {{ ad.production_size }}
    </div>
    <div v-if="ad.dimension" class="flex">
      <div>Dimensions:</div>
      <dimension :dimension="ad.dimension" class="ml-3" />
    </div>
    <div v-if="ad.position">
      {{ ad.position }}
    </div>
    <div v-if="!product.is_super" class="ad-sku text-pewter text-sm">
      SKU: {{ ad.sku }}
      <info-tooltip class="ml-1" placement="top">
        <sku-tooltip-content />
      </info-tooltip>
    </div>
    <div v-if="$can('manage_non_account_ads') && ad.sync_to_digital_vendor">
      <a
        v-if="failedDigitalVendorSync"
        target="_blank"
        class="font-bold text-md text-red"
        :href="novaUrl"
      >
        Failed sync to Kevel
      </a>
      <div v-else>
        <icon :icon="checkIcon" class="text-green" />
        Synced to Kevel
      </div>
    </div>

    <ad-details-form v-if="!hideDetails" :ad="ad" />
  </div>
</template>

<script>
import Dimension from '@/components/Core/Dimension';
import AdDetailsForm from '@/components/Core/Form/AdDetailsForm';
import InfoTooltip from '@/components/Core/Tooltips/InfoTooltip';
import SkuTooltipContent from '@/components/Core/Tooltips/SkuTooltipContent';

import { ok as checkIcon } from '@/vendor/icons';

export default {
  components: {
    Dimension,
    SkuTooltipContent,
    InfoTooltip,
    AdDetailsForm
  },
  props: {
    ad: {
      type: Object,
      required: true
    },
    orderLineItem: {
      type: Object,
      default: null
    },
    hideDetails: Boolean
  },

  data() {
    return { checkIcon };
  },

  computed: {
    failedDigitalVendorSync() {
      return (
        this.ad.sync_to_digital_vendor && !this.ad.is_synced_to_digital_vendor
      );
    },
    novaUrl() {
      return window._env.API_URL + '/nova/resources/ads/' + this.ad.id;
    },
    nationalTagPathName() {
      return this.nationalTagPath?.name;
    },
    product() {
      return this.ad.orderProduct;
    },
    productName() {
      if (this.product.product.name === this.product.product_variant.name) {
        return this.product.product.name;
      } else {
        return (
          this.product.product.name + ', ' + this.product.product_variant.name
        );
      }
    },
    lineItem() {
      return this.orderLineItem || this.ad.orderLineItem;
    }
  }
};
</script>
