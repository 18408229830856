<template>
  <div>
    <el-table
      ref="table"
      :data="isLoading ? null : invoices"
      border
      class="el-table-slim mt-6 w-full"
      @select-all="$emit('select-all', $event)"
      @select="onSelect"
      @row-click="onRowClick"
    >
      <template v-slot:empty>
        <table-empty-slot
          :is-loading="isLoading"
          :has-data="!!invoices"
          error-message="There was a problem loading the invoices. Please try again later."
        >
          <template v-slot:empty>
            <div class="flex items-center justify-center h-full w-full">
              <div class="w-1/3">
                <template v-if="isFiltered">
                  There are no Invoices that match your filter.
                </template>
                <template v-else>
                  No invoices created. You can create invoices by selecting ads
                  on the Run Date tab and clicking "Action," then "Create
                  Invoice."
                </template>
              </div>
            </div>
          </template>
        </table-empty-slot>
      </template>

      <el-table-column type="selection" width="45" />

      <el-table-column label="Status" align="center" width="80">
        <template v-slot="{ row }">
          <invoice-status-column :loading="isLoading" :invoice="row" />
        </template>
      </el-table-column>

      <el-table-column label="ID" width="100">
        <template v-slot="{ row }">{{ row.ref }}</template>
      </el-table-column>

      <el-table-column label="Customer" min-width="200">
        <template v-slot="{ row }">
          <customer-column
            :customer="
              row.customer || {
                buyer: {
                  buyer_company: row.settings.customerPrimaryContact.name
                }
              }
            "
            :campaign="row.campaign"
          />
        </template>
      </el-table-column>

      <el-table-column
        v-if="showAdCount"
        label="Ad Count"
        width="100"
        align="center"
      >
        <template v-slot="{ row }">
          {{
            row.documentLineItems.filter(
              i => i.type === DocumentLineItemTypeKeys.SALE
            ).length
          }}
        </template>
      </el-table-column>

      <el-table-column label="Issued Date" width="105">
        <template v-slot="{ row }">
          {{ row.transaction_date | date }}
        </template>
      </el-table-column>

      <el-table-column label="Billing Period" width="120">
        <template v-slot="{ row }">
          <div class="text-center">
            <div>{{ row.start_date | date }} -</div>
            <div>{{ row.end_date | date }}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Due Date" width="160">
        <template v-slot="{ row }">
          <div
            v-if="row.status === InvoiceStatusKeys.DRAFT"
            class="text-center"
          >
            <div>
              <el-tooltip effect="light">
                <template #content>
                  <div class="text-md">
                    <div>
                      This invoice is inactive and will not show up in any
                      reporting documents.
                    </div>
                    <div class="mt-2">
                      To start tracking overdue status and reporting, please
                      mark as sent.
                    </div>
                  </div>
                </template>
                <div>
                  Invoice is inactive
                  <icon class="ml-1" :icon="infoIcon" />
                </div>
              </el-tooltip>
            </div>
            <div class="mt-2">
              <a @click.stop="invoiceToShareId = row.id">Mark As Sent</a>
            </div>
          </div>
          <div v-else-if="row.status === InvoiceStatusKeys.VOID">
            N/A
          </div>
          <div v-else>
            {{ row.due_date | date }}
            <div v-if="row.status !== InvoiceStatusKeys.PAID" class="mt-2">
              <div v-if="daysPastDue(row) > 0" class="text-red font-bold">
                overdue by {{ daysPastDue(row) }} days
              </div>
              <div
                v-else-if="daysPastDue(row) === 0"
                class="font-bold text-orange"
              >
                due today
              </div>
              <div v-else-if="daysPastDue(row) === -1" class="font-bold">
                due tomorrow
              </div>
              <div v-else>due in {{ Math.abs(daysPastDue(row)) }} days</div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Terms" width="100">
        <template v-slot="{ row }">
          {{ row.terms.text }}
        </template>
      </el-table-column>

      <el-table-column label="Balance" width="110">
        <template v-slot="{ row }">
          {{ row.remaining_amount | currency }}
        </template>
      </el-table-column>

      <el-table-column label="Total" width="110">
        <template v-slot="{ row }">
          {{ row.amount | currency }}
        </template>
      </el-table-column>

      <el-table-column label="Created Date" width="115">
        <template v-slot="{ row }">
          {{ row.created_at | date }}
        </template>
      </el-table-column>

      <el-table-column label="Actions" width="160" align="center">
        <template v-slot="{ row }">
          <supplier-invoices-action-menu
            v-if="!isDeleting(row)"
            ref="actionMenu"
            :invoice="row"
            all-actions
            print-new-window
            @update:is-exporting="isExporting = $event ? row : null"
          />

          <el-tooltip
            v-if="isExporting === row"
            effect="light"
            content="Exporting Invoice. This may take up to 30 seconds..."
          >
            <icon
              :icon="exportIcon"
              class="animated loop tada text-dark-silver"
              spin
            />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <supplier-invoice-document-mutation>
      <template v-slot="{ isSaving, markInvoiceSent }">
        <share-invoice-dialog
          v-if="invoiceToShareId"
          :invoice="invoiceToShare"
          :is-saving="isSaving"
          @sent="markInvoiceSent(invoiceToShare, $event)"
          @close="invoiceToShareId = null"
        />
      </template>
    </supplier-invoice-document-mutation>
  </div>
</template>

<script>
import InvoiceStatusColumn from '@/components/Core/Table/Columns/InvoiceStatusColumn';
import SupplierInvoicesActionMenu from '@/components/Supplier/Invoices/SupplierInvoicesActionMenu';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import { CustomerColumn } from '@/components/Core/Table/Columns';
import { DocumentLineItemTypeKeys, InvoiceStatusKeys } from '@/constants';
import {
  infoCircled as infoIcon,
  shareSquareO as exportIcon
} from '@/vendor/icons';
import moment from 'moment';
import SupplierInvoiceDocumentMutation from '@/components/Mutations/Supplier/SupplierInvoiceDocumentMutation';
import ShareInvoiceDialog from '@/components/Supplier/Invoices/ShareInvoiceDialog';

export default {
  components: {
    ShareInvoiceDialog,
    SupplierInvoiceDocumentMutation,
    CustomerColumn,
    InvoiceStatusColumn,
    SupplierInvoicesActionMenu,
    TableEmptySlot
  },
  props: {
    isLoading: Boolean,
    showAdCount: {
      type: Boolean,
      default: true
    },
    invoices: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      isExporting: null,
      DocumentLineItemTypeKeys,
      InvoiceStatusKeys,
      // NOTE: using invoiceToShareId here instead of invoice directly due to reactivity issues
      invoiceToShareId: null,

      // Icons
      exportIcon,
      infoIcon
    };
  },
  computed: {
    invoiceToShare() {
      return this.invoices.find(i => i.id === this.invoiceToShareId);
    },
    isFiltered() {
      let filterCheck = this.$store.getters['invoices/pager'].filter;

      if (filterCheck) {
        // Sometimes keywords will be set to null / empty
        if (!filterCheck.keywords) {
          delete filterCheck.keywords;
        }

        return Object.keys(filterCheck).length > 0;
      }

      return false;
    }
  },
  methods: {
    daysPastDue(invoice) {
      return moment()
        .startOf('day')
        .diff(invoice.due_date, 'days');
    },
    toggleRowSelection() {
      this.$refs.table.toggleRowSelection(...arguments);
    },
    onSelect() {
      this.$emit('select', ...arguments);
    },
    onRowClick() {
      this.$emit('row-click', ...arguments);
    },
    isDeleting(invoice) {
      return !!invoice.delete_started_at;
    }
  }
};
</script>
