<template>
  <el-select
    :value="value"
    style="width: 100%"
    placeholder="State"
    filterable
    default-first-option
    auto-complete="state"
    @input="$emit('input', $event)"
  >
    <el-option
      v-for="(long, short) in States"
      :key="`state-${short}`"
      :label="long"
      :value="short"
    >
    </el-option>
  </el-select>
</template>

<script>
import { States } from '@/constants';

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      States
    };
  }
};
</script>
