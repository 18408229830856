<template>
  <div class="dimension-input">
    <div class="select-unit">
      <label :for="id">
        <dimension-units-query @data="dimensionUnits = $event.dimensionUnits">
          <template slot-scope="{ isLoading }">
            <el-select
              :id="id"
              v-model="form.unit"
              class="pt-3"
              :loading="isLoading"
              @change="change"
            >
              <el-option
                v-for="unit in unitList"
                :key="`unit-${unit.name}`"
                :label="unit.name"
                :value="unit.name"
              />
            </el-select>
          </template>
        </dimension-units-query>
      </label>
    </div>
    <div class="input-dimensions mt-5">
      <div class="flex">
        <div class="">
          <el-input
            v-model="form.width"
            v-mask.number="{ precision: 3 }"
            class="dim-input"
            @input="change"
          >
            <span slot="suffix" class="input-suffix text-dark-silver">W</span>
          </el-input>
        </div>
        <div class="">
          <span class="px-2">x</span>

          <el-input
            v-model="form.height"
            v-mask.number="{ precision: 3 }"
            class="dim-input"
            @input="change"
          >
            <span slot="suffix" class="input-suffix text-dark-silver">H</span>
          </el-input>
        </div>
        <div v-if="hasDepth" class="">
          <span class="the-x">x</span>

          <el-input
            v-model="form.depth"
            v-mask.number="{ precision: 3 }"
            class="dim-input"
            @input="change"
          >
            <span slot="suffix" class="input-suffix text-dark-silver">D</span>
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DimensionUnitsQuery } from '@/components/Queries';

export default {
  components: {
    DimensionUnitsQuery
  },
  props: {
    dimension: { type: Object, default: null },
    units: { type: Array, default: () => [] },
    hasDepth: Boolean
  },

  data() {
    return {
      id: 'dimension-input-' + this._uid,
      form: {
        width: '',
        height: '',
        depth: '',
        unit: 'Inches',
        ...this.dimension
      },
      dimensionUnits: []
    };
  },

  computed: {
    unitList() {
      return this.units.length > 0 ? this.units : this.dimensionUnits;
    }
  },

  methods: {
    change() {
      this.$emit('update:dimension', this.form);
      this.$emit('change', this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.input-dimensions {
  .dim-input {
    width: 6.1em;
  }
}

.el-select {
  width: 10.9em;
}
</style>
