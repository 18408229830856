<template>
  <div class="ad-details">
    <div v-if="!isEditingDetails" class="flex items-center">
      <div v-if="ad.details" @click="isEditingDetails = true">
        {{ ad.details }}
      </div>

      <el-tooltip
        v-if="$can('add_custom_ad_details')"
        effect="light"
        content="Edit Ad Execution Details"
        class="ad-details-edit-icon ml-2"
      >
        <a class="flex items-center" @click="isEditingDetails = true">
          <div v-if="!ad.details" class="text-gray italic mr-2">
            Edit Details
          </div>
          <icon :icon="editIcon" />
        </a>
      </el-tooltip>
    </div>
    <ads-mutation
      v-if="$can('add_custom_ad_details') && isEditingDetails"
      @done="isEditingDetails = false"
    >
      <template v-slot="{ isSaving, addDetails }">
        <div>
          <el-input v-model="customDetails" type="textarea" maxlength="50" />
          <div class="mt-3">
            <el-button
              v-if="!isSaving"
              size="small"
              class="button-blue w-full "
              @click="addDetails(ad, customDetails)"
            >
              Save
            </el-button>
            <loading-button v-else />
          </div>
        </div>
      </template>
    </ads-mutation>
  </div>
</template>

<script>
import AdsMutation from '@/components/Mutations/Ads/AdsMutation';
import { pencil as editIcon } from '@/vendor/icons';

export default {
  components: { AdsMutation },
  props: {
    ad: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      customDetails: this.ad.details,
      isEditingDetails: false,

      // Icons
      editIcon
    };
  }
};
</script>

<style scoped lang="scss">
.ad-details {
  .ad-details-edit-icon {
    opacity: 0;
    transition: all 0.3s;
  }

  &:hover {
    .ad-details-edit-icon {
      opacity: 1;
    }
  }
}
</style>
