<script>
import { getSupplierInvoiceDocument } from '@/graphql/supplier/getSupplierInvoiceDocument.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    id: { type: String, required: true }
  },
  query(props) {
    return {
      query: getSupplierInvoiceDocument,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        id: props.id
      }
    };
  }
};
</script>
