<template>
  <edit-dialog
    class="p-0"
    width="50%"
    title="Comments"
    hide-primary
    cancel-text="Close"
    :is-saving="isSaving"
    :show-dialog="visible"
    @close="$emit('close')"
  >
    <el-tabs
      :value="activeTab"
      type="border-card"
      stretch
      class="flex flex-col max-h-70vh border-none shadow-none"
      @tab-click="activeTab = $event.name"
    >
      <el-tab-pane
        v-if="order"
        label="Order Comments"
        name="order"
        class="px-4"
      >
        <div class="flex flex-row justify-between">
          <h2 class="mb-1">Order Comments # {{ order.ref }}</h2>
          <!-- <div class="text-green">
            <icon :icon="usersIcon" class="mr-2" />
            Shared
          </div> -->
        </div>
        These comments will appear on every ad within this order.
        <div class="mt-6">
          <el-form
            ref="orderNoteForm"
            :rules="orderRules"
            :model="orderNoteForm"
            :disabled="!$can('manage_order_notes')"
          >
            <el-form-item prop="note">
              <el-input
                v-model="orderNoteForm.note"
                type="textarea"
                :rows="3"
                placeholder="Enter new comment"
              />
            </el-form-item>
          </el-form>
          <loading-button v-if="isSaving" class="button w-40 mt-6" />
          <el-button
            v-else
            class="button button-blue w-40 mt-6"
            @click="onSaveOrderNote"
          >
            Save
          </el-button>
          <template v-if="hasOrderNotes">
            <note
              v-for="note in sortedNotes"
              :key="`ad-note-${note.id}$`"
              :note="note"
              class="my-7"
            />
          </template>
        </div>
      </el-tab-pane>
      <el-tab-pane
        v-if="ads && ads.length"
        label="Ad Comments"
        name="ad"
        class="px-4"
      >
        <div v-for="ad in ads" :key="ad.id" class="mb-6">
          <div class="flex flex-row justify-between">
            <h2 class="mb-1">Ad Comments # {{ ad.ref }}</h2>
          </div>
          Comments about this specific ad
          <div class="mt-6">
            <el-form
              :ref="`ad-${ad.id}-note-form`"
              :rules="adRules"
              :model="adNoteForms[ad.id]"
              :disabled="!$can('manage_order_notes')"
            >
              <el-form-item prop="note">
                <el-input
                  v-if="adNoteForms[ad.id]"
                  v-model="adNoteForms[ad.id].note"
                  type="textarea"
                  :rows="3"
                  placeholder="Enter new comment"
                />
              </el-form-item>
            </el-form>
            <loading-button v-if="isSaving" class="button w-40 mt-6" />
            <el-button
              v-else
              class="button button-blue w-40 mt-6"
              @click="onSaveAdNote(ad.id)"
            >
              Save
            </el-button>
            <template v-if="ad.notes && ad.notes.length">
              <note
                v-for="note in ad.notes"
                :key="`ad-note-${note.id}$`"
                :note="note"
                class="my-7"
              />
            </template>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </edit-dialog>
</template>

<script>
import EditDialog from '@/components/Core/EditDialog';
import { LoadingButton } from '@/components/Core/Loading';
import Note from '@/components/Core/Note';

import { users as usersIcon } from '@/vendor/icons';

export default {
  components: {
    EditDialog,
    LoadingButton,
    Note
  },
  props: {
    isSaving: Boolean,
    visible: Boolean,
    startOnOrder: Boolean,
    ads: {
      type: Array,
      default: null
    },
    order: {
      type: Object,
      default: null
    }
  },
  data() {
    let adNoteForms = {};
    if (this.ads) {
      this.ads.forEach(ad => {
        adNoteForms[ad.id] = {
          note: ''
        };
      });
    }
    return {
      usersIcon,
      activeTab: this.ads && !this.startOnOrder ? 'ad' : 'order',
      adNoteForms,
      orderNoteForm: {
        note: ''
      },
      adRules: {
        note: [
          {
            required: true,
            message: "Comments can't be blank",
            trigger: 'blur'
          }
        ]
      },
      orderRules: {
        note: [
          {
            required: true,
            message: "Comments can't be blank",
            trigger: 'blur'
          }
        ]
      }
    };
  },
  computed: {
    sortedNotes() {
      return [...this.order.notes].sort((a, b) => {
        return a.created_at < b.created_at ? 1 : -1;
      });
    },
    hasOrderNotes() {
      return this.order && this.order.notes && this.order.notes.length;
    }
  },
  watch: {
    ads(newValue, oldValue) {
      if (newValue && oldValue) {
        newValue.forEach(newAd => {
          const oldAd = oldValue.find(oldAd => oldAd.id === newAd.id);

          if (!oldAd) {
            this.adNoteForms[newAd.id] = { notes: '' };
          } else if (
            newAd.notes &&
            oldAd.notes &&
            newAd.notes.length > oldAd.notes.length
          ) {
            this.adNoteForms[newAd.id] = { notes: '' };
          }
        });
      }
    },
    order(newValue) {
      if (newValue && this.$refs.orderNoteForm) {
        this.$refs.orderNoteForm.resetFields();
      }
    }
  },
  methods: {
    onSaveAdNote(id) {
      this.$refs[`ad-${id}-note-form`][0].validate(valid => {
        if (valid) {
          this.$emit('saveAdNote', { id, note: this.adNoteForms[id].note });
        }
      });
    },
    onSaveOrderNote() {
      this.$refs.orderNoteForm.validate(valid => {
        if (valid) {
          this.$emit('saveOrdernote', this.orderNoteForm.note);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__content {
  overflow-y: auto;
}
</style>
