<template>
  <div class="form-response-preview w-full" @click="$emit('click')">
    <div v-if="files" class="file-response">
      <file-stack
        :files="files"
        :spread="spreadFiles"
        :max-count="maxFiles"
        :enable-preview="enablePreview"
        @click="$emit('click', $event)"
      />
    </div>

    <div class="overflow-scroll-y max-h-10">
      <form-field-response-preview
        v-for="fieldResponse in nonFileFieldResponses"
        :key="fieldResponse.id"
        class="form-response-value-preview"
        :value="fieldResponse.value"
        :type="fieldResponse.field.type"
        :label="fieldResponse.field.label"
      />
    </div>
  </div>
</template>

<script>
import { BuilderFields } from '@/constants';
import FileStack from '@/components/Core/FileStack';
import FormFieldResponsePreview from '@/components/Core/Form/FormBuilder/FormFieldResponsePreview';

export default {
  components: { FormFieldResponsePreview, FileStack },
  props: {
    response: {
      type: Object,
      required: true
    },
    spreadFiles: Boolean,
    maxFiles: {
      type: Number,
      default: 3
    },
    enablePreview: Boolean
  },
  computed: {
    fileFieldResponses() {
      return this.response.fieldResponses.filter(rv =>
        [
          BuilderFields.SINGLE_FILE.type,
          BuilderFields.MULTI_FILE.type
        ].includes(rv.field.type)
      );
    },
    nonFileFieldResponses() {
      return this.response.fieldResponses.filter(
        rv =>
          ![
            BuilderFields.SINGLE_FILE.type,
            BuilderFields.MULTI_FILE.type
          ].includes(rv.field.type) &&
          rv.value !== null &&
          rv.value !== ''
      );
    },
    files() {
      let files = [];

      for (let fileResponse of this.fileFieldResponses) {
        if (fileResponse.field.type === BuilderFields.SINGLE_FILE.type) {
          files.push(fileResponse.value);
        } else {
          files = files.concat(fileResponse.value);
        }
      }

      return files;
    }
  }
};
</script>

<style scoped lang="scss">
.form-response-value-preview {
  & + .form-response-value-preview {
    margin-top: 0.3em;
  }
}
</style>
