<template>
  <el-dialog
    :visible="true"
    class="share-invoice-dialog"
    title="Share Invoice"
    width="40em"
    append-to-body
    @update:visible="$emit('close')"
  >
    <div class="share-invoice-body text-lg text-center">
      <transition name="fade">
        <div v-if="canShare" :key="'shareable-invoice'">
          <div>
            Copy and share this link with your client!
          </div>

          <div class="mt-3">
            <template v-if="invoice.settings.allowCreditCardPayment">
              They can view, download and pay the invoice themselves via credit
              card
            </template>
            <template v-else>
              They can view and download the invoice from this link
            </template>
          </div>

          <div class="mt-5 text-dark-silver cursor-pointer" @click="copyLink">
            (Click to copy)
          </div>

          <div
            class="invoice-share-link flex w-full items-center mt-2 cursor-pointer"
            @click="copyLink"
          >
            <template v-if="!isCopied">
              <icon :icon="shareLinkIcon" />
              <div ref="shareLink" class="ml-3">{{ invoice.share_link }}</div>
            </template>
            <div v-else class="text-center w-full flex-grow">Copied!</div>
          </div>

          <div class="share-expiration text-red mt-5">
            This link will expire in {{ expiresIn }}!
          </div>

          <div class="mt-5">
            <a target="_blank" :href="invoice.share_link"
              >Preview Shareable Link</a
            >
          </div>
        </div>
        <div v-else :key="'make-shareable'">
          <div>
            <div>This is a Draft Invoice.</div>
            <div>
              Mark as Sent to start tracking overdue status and reporting
            </div>
          </div>

          <template v-if="$can('pub_manage_invoices')">
            <div v-if="!isSaving" class="mt-5">
              <div class="w-32 mb-3 mx-auto">
                <div class="font-semibold text-base">
                  Issued Date
                </div>
                <el-date-picker
                  v-model="issuedDate"
                  placeholder="Issued Date"
                  format="MM/dd/yyyy"
                  class="mt-2"
                />
              </div>

              <el-button
                class="button-blue w-56"
                @click="$emit('sent', { transaction_date: issuedDate })"
              >
                Mark Invoice Sent
              </el-button>

              <div class="mt-3 text-dark-silver text-sm">
                <div>NOTE: This will NOT send the invoice to the customer.</div>
                <div>You can do this yourself by copying the share link.</div>
              </div>
            </div>
            <loading-button v-else class="w-56" />
          </template>
          <div v-else class="text-dark-red mt-5">
            You do not have permission to send invoices
          </div>
        </div>
      </transition>
    </div>

    <template slot="footer">
      <el-button class="button-blue" @click="$emit('close')">Ok</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { link as shareLinkIcon } from '@/vendor/icons';
import { copyToClipboard, selectText } from '@/utils/helpers';
import { InvoiceStatusKeys } from '@/constants';
import { datetimeDb } from '@/utils/filters';

export default {
  props: {
    invoice: {
      type: Object,
      required: true
    },
    isSaving: Boolean
  },
  data() {
    return {
      isCopied: false,
      issuedDate: this.invoice.transaction_date || datetimeDb(),

      // Icons
      shareLinkIcon
    };
  },
  computed: {
    expiresIn() {
      return '30 days';
    },
    canShare() {
      return this.invoice.status !== InvoiceStatusKeys.DRAFT;
    }
  },
  mounted() {
    this.$onKeyEvent('manual-copy', this.copyLink);
    setTimeout(this.selectLink, 300);
  },
  destroyed() {
    this.$offKeyEvent('manual-copy', this.copyLink);
  },
  methods: {
    selectLink() {
      selectText(this.$refs.shareLink);
    },
    copyLink() {
      this.isCopied = true;
      copyToClipboard(this.invoice.share_link);

      this.$message.success(
        'The link has been copied! Paste it in an email or anywhere to share!'
      );

      setTimeout(() => {
        this.isCopied = false;
        this.$nextTick(this.selectLink);
      }, 3000);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';

.invoice-share-link {
  padding: 0.5em 1em;
  background: $color-dark-silver;
  overflow-x: auto;
  white-space: nowrap;
  color: white;
}
</style>
