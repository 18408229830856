<script>
import { Popover } from 'element-ui';

import { findParentNode } from '@/utils/helpers';

export default {
  name: 'CustomElPopover',

  mixins: [Popover],

  created() {
    document.addEventListener('click', this.checkClickOutside, true);
  },

  destroyed() {
    document.removeEventListener('click', this.checkClickOutside, true);
  },

  methods: {
    checkClickOutside(e) {
      // There is an issue with the default ElPopover where if you have a nested popover (ie: ElSelect),
      // the popover doesn't close when clicking outside of it
      if (this.showPopper) {
        if (!findParentNode(e.target, 'el-popper')) {
          this.showPopper = false;
        }
      }
    }
  }
};
</script>
