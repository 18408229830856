<template>
  <io-column
    :order="order"
    :campaign="campaign"
    :show-status="showStatus"
    :can-review="!hideReview"
    :download="canDownload"
    @action="onIoAction"
  >
    <template slot="reviewing">
      <el-popover placement="right" trigger="hover">
        <div v-if="!canReview" class="w-40">
          <a
            href="https://intercom.help/flytedesk/articles/3102562-assigning-team-roles"
            target="_blank"
            >Learn more about user permission roles
          </a>
        </div>
        <div v-else class="text-left">
          <div class="font-bold">{{ popoverConfig.title }}</div>
          <div class="text-dark-silver">
            {{ popoverConfig.body }}
          </div>
          <div class="mt-3 flex justify-end">
            <el-button
              :disabled="!$can('pub_accept_or_reject_order')"
              class="w-40 button-blue"
              size="mini"
              @click="$emit('review')"
            >
              Review
            </el-button>
          </div>
        </div>
        <io-item
          slot="reference"
          :order="order"
          :campaign="campaign"
          icon-class="text-red hover:text-dark-red"
          :class="{ 'cursor-not-allowed': !canReview }"
          :download="canDownload"
          @action="onIoAction"
        />
      </el-popover>
    </template>

    <template #changes>
      <el-popover placement="right" trigger="hover" width="250">
        <div class="text-left">
          <div class="font-bold">Pending Buyer Approval</div>
          <div class="text-dark-silver">
            The buyer must approve changes made to the payment settings.
          </div>
        </div>
        <io-item
          slot="reference"
          :order="order"
          :campaign="campaign"
          :download="canDownload"
          icon-class="text-yellow hover:text-dark-yellow"
          @action="onIoAction"
        />
      </el-popover>
    </template>
  </io-column>
</template>

<script>
import IoItem from '@/components/Order/IoItem';
import { IoColumn } from '@/components/Core/Table/Columns';
import { CampaignType } from '@/constants';

export default {
  components: { IoColumn, IoItem },
  props: {
    campaign: {
      type: Object,
      default: null
    },
    order: {
      type: Object,
      required: true
    },
    hideReview: Boolean,
    showStatus: Boolean
  },

  computed: {
    canReview() {
      return this.isNational
        ? this.$can('pub_accept_or_reject_order')
        : this.$can('pub_change_invoice_settings');
    },
    canDownload() {
      return this.order.approved_ads_count > 0;
    },
    isNational() {
      return this.campaign && this.campaign.type === CampaignType.NATIONAL;
    },
    popoverConfig() {
      return {
        title: this.isNational ? 'Approve National Order' : 'Approve Order',
        body: this.isNational
          ? 'Review flytedesk national campaign to confirm order.'
          : "Review buyer's requested payment settings to activate order."
      };
    }
  },
  methods: {
    onIoAction() {
      // navigate to supplier.approvals page if there are ads pending approval
      if (this.order.pending_ads_count > 0) {
        this.$router.push({
          name: 'supplier.approvals',
          query: {
            filter: {
              refGroup: { 'order.ref': [this.order.ref] }
            }
          }
        });
      }
    }
  }
};
</script>
