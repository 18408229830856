<template>
  <edit-dialog
    visible
    :title="title"
    :is-saving="isLoading"
    @save="onSave"
    @close="$emit('close')"
  >
    <el-form
      ref="lineItemForm"
      :model="subLineItem"
      :rules="rules"
      :disabled="isLoading"
    >
      <div class="flex flex-row mt-4">
        <custom-el-form-item
          label="Name"
          label-class="font-semibold"
          prop="name"
          class="flex-grow mr-3"
        >
          <el-input v-model="subLineItem.name" placeholder="Enter a name..." />
        </custom-el-form-item>
        <custom-el-form-item
          label="Type"
          label-class="font-semibold"
          prop="type"
          class="mr-3"
        >
          <el-select v-model="subLineItem.type">
            <el-option
              v-for="type in types"
              :key="'type-' + type.value"
              :label="type.label"
              :value="type.value"
            />
          </el-select>
        </custom-el-form-item>
        <custom-el-form-item
          label="Amount"
          label-class="font-semibold"
          prop="amount"
          class="flex-grow"
        >
          <el-input
            v-model="subLineItem.amount"
            v-mask.currency
            placeholder="Enter a dollar amount..."
          />
        </custom-el-form-item>
      </div>
      <custom-el-form-item
        label="Description"
        label-class="font-semibold"
        class="mt-6"
      >
        <el-input
          v-model="subLineItem.description"
          rows="3"
          type="textarea"
          placeholder="Enter a description..."
        />
      </custom-el-form-item>
    </el-form>
  </edit-dialog>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import EditDialog from '@/components/Core/EditDialog';
import { float } from '@/utils/filters';
import { OrderSubLineItemType } from '@/constants';

export default {
  components: {
    CustomElFormItem,
    EditDialog
  },
  props: {
    isLoading: Boolean,
    lineItem: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      types: [OrderSubLineItemType.DISCOUNT, OrderSubLineItemType.FEE],
      rules: {
        name: {
          required: true,
          trigger: 'blur',
          message: `Please enter a name`
        },
        amount: {
          required: true,
          trigger: 'blur',
          message: `An amount is required`
        }
      },
      subLineItem: {
        name: '',
        description: '',
        type: OrderSubLineItemType.DISCOUNT.value,
        amount: null
      }
    };
  },
  computed: {
    title() {
      let text = 'Add a discount or fee';

      if (this.lineItem && this.lineItem.ads && this.lineItem.ads.length) {
        text += ` to #${this.lineItem.ads[0].ref}`;
      }

      return text;
    }
  },
  methods: {
    onSave() {
      this.$refs.lineItemForm.validate(valid => {
        if (valid) {
          const amount =
            float(this.subLineItem.amount) *
            (this.subLineItem.type === OrderSubLineItemType.DISCOUNT.value
              ? -1
              : 1);

          this.$emit('save', {
            name: this.subLineItem.name,
            description: this.subLineItem.description,
            amount
          });
        }
      });
    }
  }
};
</script>
