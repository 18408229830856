<template>
  <supplier-invoice-document-mutation>
    <template v-slot="{ makeCreditCardPayment }">
      <payable-invoice-mutation>
        <template v-slot="{ makePayment: makePaymentAsGuest }">
          <credit-card-form ref="ccForm" v-model="creditCardForm" class="mt-5">
            <div v-if="formError" class="mt-5">
              <el-alert type="error" title="">
                {{ formError }}
              </el-alert>
            </div>

            <stripe-tokenizer
              v-slot="{ createToken }"
              :card="creditCardForm.card"
              :form="creditCardForm.form"
            >
              <div class="mt-5">
                <el-button
                  v-if="!isSubmitting"
                  class="button-blue w-full"
                  @click="
                    onSubmitPayment(
                      createToken,
                      isSupplier ? makeCreditCardPayment : makePaymentAsGuest
                    )
                  "
                >
                  Submit Payment
                </el-button>

                <loading-button v-else class="w-full" />
              </div>
            </stripe-tokenizer>
          </credit-card-form>
        </template>
      </payable-invoice-mutation>
    </template>
  </supplier-invoice-document-mutation>
</template>

<script>
import SupplierInvoiceDocumentMutation from '@/components/Mutations/Supplier/SupplierInvoiceDocumentMutation';
import CreditCardForm from '@/components/Payment/CreditCardForm';
import StripeTokenizer from '@/components/Payment/StripeTokenizer';
import PayableInvoiceMutation from '@/components/Mutations/Accounting/PayableInvoiceMutation';

export default {
  components: {
    PayableInvoiceMutation,
    StripeTokenizer,
    CreditCardForm,
    SupplierInvoiceDocumentMutation
  },
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      creditCardForm: {
        card: null,
        form: null
      },
      formError: false,
      isSubmitting: false
    };
  },
  computed: {
    isSupplier() {
      return this.$store.getters['auth/isSupplier'];
    }
  },
  methods: {
    async onSubmitPayment(createToken, makeCreditCardPayment) {
      this.isSubmitting = true;
      this.formError = false;

      try {
        await this.$refs.ccForm.validate();

        const stripeToken = await createToken();

        await makeCreditCardPayment(this.invoice, stripeToken);

        this.$message.success(
          'The Credit Card Payment was processed successfully.'
        );
      } catch (error) {
        this.formError = error.message;
      }

      this.isSubmitting = false;
    }
  }
};
</script>
