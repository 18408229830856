<template>
  <div class="transaction-history-table">
    <el-table
      :data="transactions"
      border
      class="el-table-slim"
      :default-sort="{ prop: 'date', order: 'descending' }"
    >
      <el-table-column label="ID" sortable prop="id" width="80">
        <template v-slot="{ row }">{{ row.id }}</template>
      </el-table-column>

      <el-table-column label="Date" sortable prop="date" width="100">
        <template v-slot="{ row }">{{ row.date | date }}</template>
      </el-table-column>

      <el-table-column label="Type" prop="type" sortable width="100">
        <template v-slot="{ row }">
          <div v-if="row.type === TransactionType.CREDIT" class="text-red">
            <icon :icon="dollarIcon" />
            Credit
          </div>
          <div v-if="row.type === TransactionType.PAYMENT" class="text-green">
            <icon :icon="moneyIcon" />
            Payment
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Status" prop="status" sortable width="125">
        <template v-slot="{ row }">
          <status-icon
            :icons="TransactionStatusInfo"
            :status="row.status"
            inline
          />
        </template>
      </el-table-column>

      <el-table-column
        label="Amount"
        prop="amount"
        sortable
        width="120"
        align="right"
      >
        <template v-slot="{ row }">
          <div
            :class="{
              'text-green': row.amount > 0,
              'text-red': row.amount < 0
            }"
          >
            {{ row.amount | currency }}
          </div>
          <div
            v-if="+row.refunded_amount"
            class="text-dark-silver text-md mt-2"
          >
            <div>Refunded</div>
            <div>{{ row.refunded_amount | currency }}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Method" prop="paymentMethod" sortable width="120">
        <template v-slot="{ row }">
          <template v-if="row.paymentMethod">
            {{ row.paymentMethod.name }}
          </template>
        </template>
      </el-table-column>

      <el-table-column
        label="Description"
        prop="description"
        sortable
        min-width="200"
      >
        <template v-slot="{ row }">
          <div>{{ row.description }}</div>
          <div v-if="row.provider" class="invoice-provider text-gray">
            Paid via {{ row.provider }}
          </div>
        </template>
      </el-table-column>

      <el-table-column v-if="isVoidable" width="80">
        <template v-slot="{ row }">
          <template v-if="row.status !== TransactionStatus.VOID">
            <div v-if="!isLoading" :key="'voidable-item-' + row.id">
              <a
                v-if="!row.provider"
                class="text-red"
                @click="(isVoiding[row.id] = true) && $emit('void', row)"
              >
                <icon :icon="voidIcon" />
                Void
              </a>
              <el-tooltip
                v-else
                effect="light"
                content="You cannot Void Credit Card Payments. Please contact us for help with this."
              >
                <a class="text-silver hover:text-silver cursor-not-allowed">
                  <icon :icon="voidIcon" />
                  Void
                </a>
              </el-tooltip>
            </div>
            <div v-else-if="isVoiding[row.id]" :key="'voiding-item-' + row.id">
              <icon :icon="loadingIcon" spin />
            </div>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  TransactionStatus,
  TransactionStatusInfo,
  TransactionType
} from '@/constants';

import StatusIcon from '@/components/Core/Statuses/StatusIcon';

import {
  arrowsCw as loadingIcon,
  disable as voidIcon,
  dollar as dollarIcon,
  money as moneyIcon
} from '@/vendor/icons';

export default {
  components: { StatusIcon },
  props: {
    isLoading: Boolean,
    isVoidable: Boolean,
    transactions: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      TransactionStatusInfo,
      TransactionStatus,
      TransactionType,
      isVoiding: {},

      // Icons
      dollarIcon,
      loadingIcon,
      moneyIcon,
      voidIcon
    };
  }
};
</script>
