<template>
  <edit-dialog
    :is-saving="isLoading"
    save-text="Add Payment"
    title="Payments"
    show-dialog
    hide-primary
    cancel-text="Close"
    width="80%"
    @close="$emit('close')"
    @save="onSave"
  >
    <transition name="fade">
      <div v-if="!isVoided">
        <div
          v-if="+invoice.remaining_amount > 0"
          :key="'invoice-make-payment-forms'"
        >
          <el-tabs
            v-if="
              invoice.settings.allowCreditCardPayment &&
                invoice.is_tracked_by_source
            "
            v-model="activeTab"
          >
            <el-tab-pane
              key="tab-record-payment"
              name="record-payment"
              label="Record Payment"
            />
            <el-tab-pane
              key="tab-credit-card"
              name="credit-card"
              label="Credit Card"
            />
          </el-tabs>

          <div class="mt-5">
            <transition name="fade" mode="out-in">
              <div
                v-if="activeTab === 'record-payment'"
                :key="'record-payment-form'"
              >
                <record-payment-form
                  ref="paymentForm"
                  :max-amount="+invoice.remaining_amount"
                  :is-saving="isLoading"
                  @save="$emit('save', $event)"
                />
              </div>
              <div v-else :key="'cc-payment-form'">
                <invoice-credit-card-payment :invoice="invoice" />
              </div>
            </transition>
          </div>
        </div>
        <div v-else-if="+invoice.paid_amount === 0">
          <template v-if="invoice.status !== InvoiceStatusKeys.PAID">
            <div>
              This invoice does not require payment. You can mark it as paid by
              clicking the button below.
            </div>
            <el-button
              class="button-green mt-3"
              :loading="isLoading"
              @click="onMarkPaid"
            >
              Mark as Paid
            </el-button>
          </template>
          <template v-else>
            The invoice has been marked as paid.
          </template>
        </div>
        <div v-else :key="'no-payment-remaining'" class="flex">
          <icon :icon="paidIcon" class="text-green text-lg" />
          <h2 class="text-dark-silver ml-3">
            Invoice {{ invoice.ref }} has been paid in full
          </h2>
        </div>
      </div>
    </transition>

    <template v-if="invoice.amount > 0">
      <h3 v-if="!isVoided" class="font-semibold mt-6 mb-2">
        Payments
      </h3>
      <transaction-history-table
        :is-voidable="$can('pub_manage_invoices')"
        :is-loading="isLoading"
        :transactions="invoice.transactions"
        @void="$emit('void-transaction', $event)"
      />
    </template>
  </edit-dialog>
</template>

<script>
import EditDialog from '@/components/Core/EditDialog';
import TransactionHistoryTable from '@/components/Payment/TransactionHistoryTable';
import RecordPaymentForm from '@/components/Payment/RecordPaymentForm';
import { ok as paidIcon } from '@/vendor/icons';
import { InvoiceStatusKeys } from '@/constants';
import InvoiceCreditCardPayment from '@/components/Payment/InvoiceCreditCardPayment';
import moment from 'moment/moment';

export default {
  components: {
    InvoiceCreditCardPayment,
    RecordPaymentForm,
    EditDialog,
    TransactionHistoryTable
  },
  props: {
    isLoading: Boolean,
    invoice: {
      type: Object,
      default: () => ({})
    },
    isVoided: {
      type: Boolean
    }
  },
  data() {
    return {
      activeTab: 'record-payment',
      InvoiceStatusKeys,

      // Icons
      paidIcon
    };
  },
  methods: {
    onSave() {
      this.$refs.paymentForm.save();
    },
    onMarkPaid() {
      this.$emit('save', {
        payment_date: moment(),
        amount: 0,
        paymentMethod: {
          id: this.$store.getters['auth/supplier'].paymentMethods[0].id
        },
        description: 'Marking as paid'
      });
    }
  }
};
</script>
