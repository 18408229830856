import { loadStripe } from '@stripe/stripe-js';

// Set to the Stripe object once initialized asynchronously
let Stripe = null;

export default async function stripeLoader() {
  if (!Stripe) {
    Stripe = await loadStripe(process.env.VUE_APP_STRIPE_CLIENT_KEY);
  }

  return Stripe;
}
