<template>
  <supplier-invoices-query @data="onReceivedInvoices">
    <template v-slot="{ isLoading }">
      <div class="flex flex-row border-top">
        <div class="flex-shrink-0">
          <collapsible-search-group-sidebar
            v-model="searchInput"
            :search-tools="invoiceData && invoiceData.searchTools"
            :is-loading="!invoices"
            :search-groups-info="InvoiceSearchGroups"
            :filter="pager.filter"
            @search="pager = { ...pager, ...$event }"
          />
        </div>
        <div
          class="flex flex-1 flex-col p-8 overflow-x-auto min-w-40 border-left"
        >
          <supplier-invoice-document-mutation>
            <template v-slot="{ isSaving, exportInvoices, clearSelections }">
              <!-- Toolbar -->
              <filter-toolbar
                class="items-end"
                :value="pager.filter"
                @input="pager = { ...pager, filter: $event }"
                @export="onExport(exportInvoices)"
              >
                <table-selections-count
                  v-if="selectionSummary"
                  :count="selectionSummary.total_selected"
                  :is-saving="isSaving"
                  @clear="clearSelections"
                />
              </filter-toolbar>
            </template>
          </supplier-invoice-document-mutation>

          <supplier-invoices-summary
            :summary="selectionSummary"
            class="mt-5 border border-b-0 border-light-gray"
          />

          <!-- Table -->
          <supplier-invoice-document-mutation :invoice-data="invoiceData">
            <template v-slot="{ isSaving, selectByIds, selectByFilter }">
              <supplier-invoices-table
                ref="table"
                :is-loading="isLoading"
                :invoices="invoices"
                @select-all="onSelectAll(selectByFilter, $event)"
                @select="onSelect(selectByIds, ...arguments)"
                @row-click="onRowClick(selectByIds, ...arguments)"
              />
            </template>
          </supplier-invoice-document-mutation>

          <!-- Pagination -->
          <pagination
            v-if="paginatorInfo && paginatorInfo.total > 0"
            class="mt-6"
            :info="paginatorInfo"
            :pager.sync="pager"
          />
        </div>
      </div>
    </template>
  </supplier-invoices-query>
</template>

<script>
import CollapsibleSearchGroupSidebar from '@/components/Core/CollapsibleSearchGroupSidebar';
import FilterToolbar from '@/components/Core/Table/FilterToolbar';
import Pagination from '@/components/Core/Table/Pagination';
import SupplierInvoiceDocumentMutation from '@/components/Mutations/Supplier/SupplierInvoiceDocumentMutation';
import SupplierInvoicesSummary from '@/components/Supplier/Billing/SupplierInvoicesSummary';
import SupplierInvoicesTable from '@/components/Supplier/Invoices/SupplierInvoicesTable';
import { download } from '@/utils/helpers';
import { InvoiceSearchGroups } from '@/constants';
import { SupplierInvoicesQuery } from '@/components/Queries';
import SelectionsMixin from '@/components/Core/Table/SelectionsMixin';
import TableSelectionsCount from '@/components/Core/Table/TableSelectionsCount';

export default {
  components: {
    TableSelectionsCount,
    CollapsibleSearchGroupSidebar,
    FilterToolbar,
    Pagination,
    SupplierInvoiceDocumentMutation,
    SupplierInvoicesQuery,
    SupplierInvoicesSummary,
    SupplierInvoicesTable
  },
  mixins: [SelectionsMixin],
  data() {
    return {
      invoices: null,
      invoiceData: null,
      paginatorInfo: null,
      InvoiceSearchGroups,
      // TODO: This is needed because of the way SearchGroupSidebar behaves. We need to refactor this
      searchInput: {}
    };
  },
  computed: {
    pager: {
      get() {
        return this.$store.getters['invoices/pager'];
      },
      set(value) {
        this.$store.commit('invoices/setPager', value);
      }
    },
    selectionSummary() {
      return this.invoiceData && this.invoiceData.selectionSummary;
    }
  },
  methods: {
    onReceivedInvoices({ supplier: { invoiceData } }) {
      this.invoices = invoiceData.invoices.data;
      this.invoiceData = invoiceData;
      this.paginatorInfo = invoiceData.invoices.paginatorInfo;
      this.tableData = this.invoices;
    },
    async onExport(exportInvoices) {
      const data = await exportInvoices(this.pager.filter);

      download(data.mutateInvoice.exportInvoices, 'Invoices.csv');
    }
  }
};
</script>
