<template>
  <div class="campaign-status-column">
    <select-content
      v-if="canManageCampaigns"
      :value="campaign.status"
      :items="allowedStatuses"
      placement="right"
      :disabled="allowedStatuses.length === 0"
      @change="doAction"
    >
      <status-icon
        :icons="CampaignStatuses"
        :status="campaign.status"
        :loading="loading"
      />

      <status-icon
        slot="option"
        slot-scope="{ status }"
        :icons="CampaignStatuses"
        :status="status"
        inline
      />
    </select-content>

    <el-tooltip v-else effect="light">
      <template #content>
        <div class="w-40">
          <a
            href="https://intercom.help/flytedesk/articles/3102562-assigning-team-roles"
            target="_blank"
            >Learn more about user permission roles
          </a>
        </div>
      </template>
      <status-icon
        class="cursor-not-allowed"
        :icons="CampaignStatuses"
        :status="campaign.status"
        :loading="loading"
      />
    </el-tooltip>

    <cancel-campaign-dialog
      v-if="showConfirmCancel"
      :campaign="campaign"
      :is-saving="loading"
      @cancel="$emit('cancel')"
      @close="showConfirmCancel = false"
    />

    <confirm-dialog
      v-if="showConfirmResume"
      confirm-class="button-blue"
      confirm-text="Resume"
      :is-saving="loading"
      @confirm="$emit('resume')"
      @close="showConfirmResume = false"
    >
      <div>
        <div>Are you sure you want to Resume this Canceled this campaign?</div>
        <div class="mt-3">
          NOTE: This will not resume the Ads for this Campaign. You will need to
          resume each ad manually via the Run Dates page.
        </div>
      </div>
    </confirm-dialog>

    <confirm-dialog
      v-if="showConfirmDelete"
      confirm-class="button-red"
      confirm-text="Delete"
      :is-saving="loading"
      @confirm="$emit('delete')"
      @close="showConfirmDelete = false"
    >
      Are you sure you want to Delete this campaign?<br />
      This will remove the campaign from the Buyer's account... but to be
      perfectly honest this action is totally recoverable, but requires a dev so
      just make sure you want to do this because you don't want to waste a dev's
      time.
    </confirm-dialog>
  </div>
</template>

<script>
import {
  CampaignActions,
  CampaignStatus,
  CampaignStatusInfo,
  CampaignStatusInfoSuper
} from '@/constants/campaign';

import ConfirmDialog from '@/components/Core/ConfirmDialog';
import SelectContent from '@/components/Core/SelectContent';
import StatusIcon from '@/components/Core/Statuses/StatusIcon';
import CancelCampaignDialog from '@/components/Campaign/CancelCampaignDialog';

export default {
  components: {
    CancelCampaignDialog,
    ConfirmDialog,
    SelectContent,
    StatusIcon
  },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    loading: Boolean
  },

  data() {
    let CampaignStatuses = CampaignStatusInfo;

    if (this.canManageCampaigns) {
      CampaignStatuses = CampaignStatusInfoSuper;
    }

    return {
      CampaignStatuses,
      // The campaign being updated
      showConfirmCancel: false,
      showConfirmDelete: false,
      showConfirmResume: false
    };
  },

  computed: {
    isCanceled() {
      return this.campaign.status === CampaignStatus.CANCELED;
    },

    allowedStatuses() {
      let allowed = [];

      if (this.canManageCampaigns) {
        if (!this.isCanceled) {
          allowed.push(CampaignActions.CANCEL);
        }

        if (this.isCanceled) {
          allowed.push(CampaignActions.DELETE);
          allowed.push(CampaignActions.RESUME);
        }
      }

      return allowed;
    },
    canManageCampaigns() {
      return this.$can('buyer_manage_campaign');
    }
  },

  watch: {
    loading() {
      if (!this.loading) {
        this.showConfirmCancel = false;
        this.showConfirmDelete = false;
        this.showConfirmResume = false;
      }
    }
  },

  methods: {
    doAction(action) {
      switch (action) {
        case CampaignActions.CANCEL:
          this.showConfirmCancel = true;
          break;

        case CampaignActions.DELETE:
          this.showConfirmDelete = true;
          break;

        case CampaignActions.RESUME:
          this.showConfirmResume = true;
          break;
      }
    }
  }
};
</script>
