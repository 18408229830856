<template>
  <custom-el-table-column v-if="!isHidden" v-bind="$props">
    <template v-slot:header="{ column }">
      <slot name="header">
        <div class="flex-important justify-between">
          <span> {{ column.label }} </span>
          <slot name="optional-icon">
            <icon
              :icon="icon"
              class="text-blue hover:text-dark-blue cursor-pointer"
              @click="$emit('update:is-hidden', !isHidden)"
            />
          </slot>
        </div>
      </slot>
    </template>
    <template v-slot="{ row }">
      <slot :row="row" />
    </template>
  </custom-el-table-column>
</template>

<script>
import CustomElTableColumn from '@/components/Custom/CustomElTableColumn';
import { TableColumn } from 'element-ui';
import { minus as minusIcon } from '@/vendor/icons';

export default {
  components: {
    CustomElTableColumn
  },
  props: {
    ...TableColumn.props,
    isHidden: Boolean,
    icon: {
      type: Object,
      default: () => minusIcon
    }
  }
};
</script>

<style lang="scss" scoped>
.flex-important {
  display: flex !important;
}
</style>
