<template>
  <div class="invoice-list-column cursor-pointer" @click.stop.prevent>
    <el-popover
      v-if="lineItem.supplierInvoices.length > 0"
      placement="bottom-end"
    >
      <div slot="reference">
        <template v-if="isLocal">
          <div class="text-dark-silver">
            Not Invoiced: {{ lineItem.uninvoiced_amount | currency }}
          </div>
          <div class="text-dark-silver">
            Invoiced (Draft): {{ lineItem.invoiced_draft_amount | currency }}
          </div>
          <div class="text-dark-blue">
            Invoiced (Due):
            {{ (lineItem.invoiced_active_amount - totalPaid) | currency }}
          </div>
        </template>
        <template v-else>
          <div class="text-dark-silver">
            Remaining: {{ totalRemaining | currency }}
          </div>
        </template>

        <div class="text-dark-green">
          Invoiced (Paid): {{ totalPaid | currency }}
        </div>
        <a class="mt-2">
          <icon :icon="viewIcon" />
          View {{ lineItem.supplierInvoices.length }}
          {{ lineItem.supplierInvoices.length === 1 ? 'Invoice' : 'Invoices' }}
        </a>
      </div>

      <div>
        <supplier-invoices-table
          :show-ad-count="false"
          :invoices="lineItem.supplierInvoices"
        />
      </div>
    </el-popover>

    <template
      v-if="lineItem.uninvoiced_amount > 0 || lineItem.invoiced_count === 0"
    >
      <a v-if="isLocal" @click="$emit('create-invoice')">+ Create Invoice</a>
      <el-tooltip
        v-else
        content="For National ads, flytedesk will be invoiced on your behalf once the ad has run"
        effect="light"
        class="whitespace-no-wrap"
      >
        <div>
          Not yet available
          <icon :icon="helpIcon" />
        </div>
      </el-tooltip>
    </template>
  </div>
</template>

<script>
import SupplierInvoicesTable from '@/components/Supplier/Invoices/SupplierInvoicesTable';
import { eye as viewIcon, helpCircled as helpIcon } from '@/vendor/icons';
import { CampaignType } from '@/constants';

export default {
  components: { SupplierInvoicesTable },
  props: {
    lineItem: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      // Icons
      helpIcon,
      viewIcon
    };
  },
  computed: {
    isLocal() {
      return this.lineItem.order.campaign.type === CampaignType.LOCAL;
    },
    totalRemaining() {
      return this.lineItem.supplierInvoices.reduce(
        (acc, i) => acc + +i.remaining_amount,
        0
      );
    },
    totalPaid() {
      return this.lineItem.supplierInvoices.reduce(
        (acc, i) => acc + +i.paid_amount,
        0
      );
    }
  }
};
</script>
