<template>
  <div class="dimension-column">
    <custom-el-popover :disabled="!canEdit">
      <div
        slot="reference"
        class="inline-flex selected-value"
        :class="{ 'is-clickable': canEdit }"
      >
        <curated-lock-icon v-if="!canEdit" class="mr-2" />
        <dimension :dimension="value" />
      </div>

      <div class="dimension-form">
        <dimension-input :dimension="value" @change="$emit('input', $event)" />
      </div>
    </custom-el-popover>
  </div>
</template>

<script>
import DimensionInput from '@/components/Supplier/Mediakit/Common/DimensionInput';
import CuratedLockIcon from '@/components/Core/MediaKit/CuratedLockIcon';
import CustomElPopover from '@/components/Custom/CustomElPopover';
import Dimension from '@/components/Core/Dimension';

export default {
  components: {
    Dimension,
    CuratedLockIcon,
    CustomElPopover,
    DimensionInput
  },
  props: {
    property: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    canEdit() {
      return (
        !this.property.is_digital_content ||
        this.$can('manage_mediakit_templates')
      );
    }
  }
};
</script>
