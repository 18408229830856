<template>
  <ads-mutation>
    <template v-slot="{ loading: adIsMutating, setAdNote }">
      <supplier-orders-mutation>
        <template v-slot="{ loading: orderIsMutating, setOrderNote }">
          <div
            class="flex flex-col items-center justify-center text-blue hover:text-dark-blue cursor-pointer p-4"
            @click.stop.prevent="dialogVisible = true"
          >
            <icon
              :icon="chatIcon"
              class="icon-lg"
              @click="dialogVisible = true"
            />
            <div v-if="noteCount > 0">
              {{ noteCount }}
            </div>
          </div>
          <note-dialog
            :is-saving="adIsMutating || orderIsMutating"
            :visible="dialogVisible || startOpen"
            :start-on-order="startOnOrder"
            :ads="ads"
            :order="order"
            @close="
              $emit('close', true);
              dialogVisible = false;
            "
            @saveAdNote="setAdNote($event.id, { text: $event.note })"
            @saveOrdernote="setOrderNote(order.id, { text: $event })"
          />
        </template>
      </supplier-orders-mutation>
    </template>
  </ads-mutation>
</template>

<script>
import { chat as chatIcon } from '@/vendor/icons';

import { AdsMutation, SupplierOrdersMutation } from '@/components/Mutations';
import NoteDialog from '@/components/Core/NoteDialog';

export default {
  components: {
    AdsMutation,
    NoteDialog,
    SupplierOrdersMutation
  },
  props: {
    startOpen: Boolean,
    startOnOrder: Boolean,
    ads: {
      type: Array,
      default: null
    },
    order: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      chatIcon,
      dialogVisible: false
    };
  },
  computed: {
    noteCount() {
      let count = 0;
      if (this.ads) {
        this.ads.forEach(ad => {
          if (ad.notes) {
            count += ad.notes.length;
          }
        });
      }
      if (this.order && this.order.notes) {
        count += this.order.notes.length;
      }
      return count;
    }
  }
};
</script>
