<template>
  <div class="quantity-column">
    <custom-el-popover placement="bottom">
      <a slot="reference" class="column-value">
        <template v-if="quantity">{{ quantity }}</template>
        <template v-else>
          <span class="icon-lg margin-right-xxs">&#x221e;</span>
        </template>
        {{ unit.name }}
      </a>

      <div class="quantity-popover pad">
        <el-form>
          <div class="quantity-description">
            <h3 class="font-size-default">Quantity Available</h3>
            <div class="help margin-top">
              Quantity available indicates the total number of<br />
              orders of this variant you will accept on any given day.
            </div>
          </div>
          <div class="quantity-list margin-top-xxl">
            <div class="flex items-center">
              <div class="block p-3">
                <el-input
                  v-mask.integer
                  :value="quantity || ''"
                  class="is-width-sm"
                  placeholder="Unlimited"
                  @input="$emit('update:quantity', +$event)"
                  @change="$emit('change')"
                />
              </div>

              <div class="block p-3">
                <mediakit-units-query @data="setMediakitUnits">
                  <template slot-scope="{ isLoading }">
                    <el-select
                      :value="unit"
                      :loading="isLoading"
                      filterable
                      value-key="id"
                      class="is-width-md"
                      @input="$emit('update:unit', $event)"
                      @change="$emit('change')"
                    >
                      <el-option
                        v-for="mediakitUnit in mediakitUnits"
                        :key="`unit-${mediakitUnit.id}`"
                        :label="mediakitUnit.name"
                        :value="mediakitUnit"
                      >
                        {{ mediakitUnit.name }}
                      </el-option>
                    </el-select>
                  </template>
                </mediakit-units-query>
              </div>

              <div class="block p-3">
                <el-checkbox
                  :value="quantity <= 0"
                  @change="$emit('update:quantity', quantity ? 0 : 1)"
                >
                  Unlimited
                </el-checkbox>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </custom-el-popover>
  </div>
</template>

<script>
import CustomElPopover from '@/components/Custom/CustomElPopover';
import { MediakitUnitsQuery } from '@/components/Queries';

export default {
  components: {
    CustomElPopover,
    MediakitUnitsQuery
  },
  props: {
    unit: {
      type: Object,
      required: true
    },

    quantity: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      mediakitUnits: []
    };
  },

  methods: {
    setMediakitUnits({ mediakitUnits }) {
      this.mediakitUnits = mediakitUnits;

      this.setDefaultUnit();
    },
    setDefaultUnit() {
      if (!this.unit.name && this.mediakitUnits) {
        this.$emit('update:unit', this.mediakitUnits[0]);
        this.$emit('change');
      }
    }
  }
};
</script>
