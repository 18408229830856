import { render, staticRenderFns } from "./EditableDocumentItem.vue?vue&type=template&id=201ff52d&scoped=true&"
import script from "./EditableDocumentItem.vue?vue&type=script&lang=js&"
export * from "./EditableDocumentItem.vue?vue&type=script&lang=js&"
import style0 from "./EditableDocumentItem.vue?vue&type=style&index=0&id=201ff52d&lang=scss&scoped=true&"
import style1 from "./EditableDocumentItem.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "201ff52d",
  null
  
)

export default component.exports