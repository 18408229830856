<template>
  <div class="h-full">
    <link
      rel="stylesheet"
      href="https://unpkg.com/leaflet@1.8.0/dist/leaflet.css"
      integrity="sha512-hoalWLoI8r4UszCkZ5kL8vayOGVae1oxXe/2A4AO6J9+580uKHDO3JdHb7NzwwzK5xr/Fs0W40kiNHxM9vyTtQ=="
      crossorigin=""
    />
    <div id="leaflet-map" class="w-full h-10"></div>
  </div>
</template>

<script>
import { InteractiveMap } from '@/vendor/interactive-map.js';

export default {
  props: {
    markers: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    markers() {
      map.setMarkers(this.markers);
    }
  },
  mounted() {
    const map = new InteractiveMap('leaflet-map');
    map.init().setMarkers(this.markers);
  }
};
</script>

<style lang="scss">
#leaflet-map {
  height: 100%;
  width: 100%;
}
</style>
