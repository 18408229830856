<script>
import { makePaymentForInvoice } from '@/graphql/accounting/payableInvoiceMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        /**
         * Makes a Credit Card Payment for an Invoice
         *
         * @param invoice
         * @param stripeToken
         */
        makePayment(invoice, stripeToken) {
          return this.post(makePaymentForInvoice, {
            id: invoice.id,
            input: { stripeToken }
          });
        }
      }
    };
  }
};
</script>
