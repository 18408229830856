<template>
  <div class="fulfillment-column">
    <el-popover
      v-click-outside="() => (isEditing = false)"
      trigger="manual"
      placement="bottom-left"
      :value="isEditing"
      :popper-options="{
        modifiers: { preventOverflow: { boundariesElement: 'offsetParent' } }
      }"
    >
      <div
        slot="reference"
        class="is-clickable value"
        @click="isEditing = true"
      >
        <template v-if="fulfillmentMethod">
          {{ fulfillmentMethod.name }}
        </template>
        <template v-else>
          <i>None</i>
        </template>
      </div>

      <div class="select-method">
        <template v-if="product.fulfillmentMethods.length > 0">
          <div
            v-for="fulfillment in product.fulfillmentMethods"
            :key="`fulfillment-${fulfillment.id}`"
            class="fulfillment-item column is-narrow"
          >
            <fulfillment-method
              :fulfillment="fulfillment"
              @edit="change(fulfillment)"
            />
          </div>
        </template>

        <template v-else>
          <h3>
            You don't have any fulfillment methods active! Go to the Settings
            tab to add or create one.
          </h3>
        </template>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { find } from 'lodash';

import FulfillmentMethod from '@/components/Supplier/Mediakit/Product/FulfillmentMethod';

/**
 * TODO: Maybe for use in the future, need to fix popover issue...
 */
export default {
  components: {
    FulfillmentMethod
  },
  props: {
    product: {
      type: Object,
      required: true
    },

    value: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isEditing: false
    };
  },

  computed: {
    fulfillmentMethod() {
      if (this.value) {
        return find(this.product.fulfillmentMethods, { id: this.value });
      }

      return false;
    }
  },

  methods: {
    change(fulfillment) {
      this.$emit('input', fulfillment.id);
      this.isEditing = false;
    }
  }
};
</script>
