<template>
  <div class="supplier-run-dates-summary dark-silver">
    <div class="flex items-start">
      <template v-if="summary">
        <!-- Totals column -->
        <div class="p-3 flex">
          <div class="min-w-3.5">
            <summary-item
              :label="
                summary.total_selected > 0 ? 'Total Selected' : 'Total Ads'
              "
              :amount="summary.total_items"
            />
          </div>
          <div class="ml-5 min-w-3.5">
            <summary-item
              label="Total Sold"
              :amount="+summary.total_supplier_price"
              currency
            />
          </div>
          <div class="ml-5 min-w-3.5">
            <summary-item
              label="Total Invoiced"
              :amount="+summary.total_invoiced"
              currency
            />
          </div>
          <div class="ml-5 min-w-3.5">
            <summary-item
              label="Total Active Invoices"
              :amount="+summary.total_invoiced_active"
              currency
            />
          </div>
          <div class="ml-5 min-w-3.5">
            <summary-item
              label="Total Draft Invoices"
              :amount="+summary.total_invoiced_draft"
              currency
            />
          </div>
        </div>
      </template>

      <template v-else>
        <div class="p-3 pb-5 flex">
          <loader-box height="4.55em" width="3.5em" />
          <loader-box height="4.55em" width="3.5em" class="ml-5" />
        </div>
        <div class="ml-5 w-full mr-5 flex items-start">
          <loader-box height="4.55em" class="flex-1 p-3" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SummaryItem from './SummaryItem';
import { colors } from '@/tailwind';

export default {
  components: {
    SummaryItem
  },
  props: {
    summary: {
      type: Object,
      default: null
    }
  },
  data() {
    return { colors };
  }
};
</script>
