<template>
  <a
    class="block"
    :class="iconClass"
    @click="$emit('click')"
    @mouseenter="toggleHover(true)"
    @mouseleave="toggleHover(false)"
  >
    <icon
      v-bind="$props"
      :icon="icon"
      class="transition-all"
      :style="{
        'font-size': iconSize,
        transform: `rotate(${rotation || 0}deg)`
      }"
    />
    {{ text }}
  </a>
</template>

<script>
import { shareSquareO } from '@/vendor/icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  props: {
    ...FontAwesomeIcon.props,
    active: Boolean,
    activeClass: {
      type: String,
      default: ''
    },
    hoverClass: {
      type: String,
      default: 'tada animated'
    },
    iconSize: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: Object,
      default: () => shareSquareO
    }
  },
  data() {
    return {
      isHovering: false
    };
  },
  computed: {
    iconClass() {
      return {
        [this.hoverClass]: this.isHovering,
        [this.activeClass]: this.active
      };
    }
  },
  methods: {
    toggleHover(isHovering) {
      this.isHovering = isHovering;
      this.$emit('hover', isHovering);
    }
  }
};
</script>
