<template>
  <supplier-billing-mutation :show-error-message="false">
    <template
      v-slot="{
        isSaving,
        createInvoiceForLineItems
      }"
    >
      <div class="supplier-create-invoice">
        <create-invoice-dialog
          :selections-summary="selectionsSummary"
          :is-loading="isSaving"
          :errors="invoiceErrors"
          @save="onCreateInvoice(createInvoiceForLineItems, $event)"
          @error="invoiceGraphQLErrors = $event"
          @close="(invoiceGraphQLErrors = null) || $emit('close')"
        />

        <confirm-dialog
          v-if="warningDialogVisible"
          title="Ad Selection Warning"
          :close-on-click-modal="false"
          visible
          append-to-body
          width="40%"
          confirm-class="button-red"
          confirm-text="Accept and Create Invoice"
          @confirm="onAcceptAndCreateInvoice(createInvoiceForLineItems)"
          @close="warningDialogVisible = false"
        >
          <div>
            The selection you have chosen to invoice contains ads that may not
            be invoiceable for the following reasons:
          </div>
          <el-alert
            v-for="message in invoiceWarnings"
            :key="message"
            class="mt-5"
            title=""
            type="warning"
          >
            {{ message }}
          </el-alert>
        </confirm-dialog>
      </div>
    </template>
  </supplier-billing-mutation>
</template>

<script>
import CreateInvoiceDialog from '@/components/Core/Dialogs/CreateInvoiceDialog';
import SupplierBillingMutation from '@/components/Mutations/Supplier/SupplierBillingMutation';
import { currency } from '@/utils/filters';
import { getGraphQLErrorMessages } from '@/utils/helpers';
import ConfirmDialog from '@/components/Core/ConfirmDialog';

export default {
  components: {
    ConfirmDialog,
    SupplierBillingMutation,
    CreateInvoiceDialog
  },
  props: {
    selectionsSummary: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      warningDialogVisible: false,
      invoiceInput: null,
      invoiceGraphQLErrors: null
    };
  },
  computed: {
    invoiceWarnings() {
      return getGraphQLErrorMessages(
        this.invoiceGraphQLErrors,
        'DataValidationWarning'
      );
    },
    invoiceErrors() {
      return getGraphQLErrorMessages(
        this.invoiceGraphQLErrors,
        'DataValidationError'
      );
    }
  },
  methods: {
    async onCreateInvoice(createInvoiceForLineItems, input) {
      this.invoiceInput = input;

      try {
        const {
          mutateInvoice: { invoice, createForLineItems }
        } = await createInvoiceForLineItems(input);

        if (createForLineItems) {
          if (invoice) {
            this.$message.success(
              `Invoice ${invoice.ref} was created for ${currency(
                invoice.amount
              )}`
            );
          } else {
            this.$message.warning(
              'Your invoice is being created in the background! Large requests may take up to 2 hours to complete. Please check back later for your completed invoice.'
            );
          }

          this.$emit('close');
        } else {
          this.$message.error(
            'Failed to create Invoice. Please try again, or contact us for help if you continue having a problem.'
          );
        }
      } catch (error) {
        this.invoiceGraphQLErrors = error.graphQLErrors;

        // Show the Warning Dialog if there are Warning Validations in the response
        this.$nextTick(() => {
          this.warningDialogVisible =
            this.invoiceWarnings && this.invoiceWarnings.length > 0;
        });
      }
    },
    async onAcceptAndCreateInvoice(createInvoiceForLineItems) {
      this.invoiceInput.ignoreWarnings = true;
      this.warningDialogVisible = false;
      await this.onCreateInvoice(createInvoiceForLineItems, this.invoiceInput);
    }
  }
};
</script>
