<template>
  <div class="multi-select-column">
    <el-select
      v-if="selectOptions.length > 0"
      :value="value"
      :placeholder="placeholder"
      multiple
      collapse-tags
      :disabled="disabled"
      @input="$emit('input', $event)"
    >
      <el-option
        v-for="option in selectOptions"
        :key="`select-col-${option.value}`"
        :label="option.label"
        :value="option.value"
        :disabled="disabled"
      />
    </el-select>

    <div v-else>
      <i>Not Required</i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: Boolean,

    value: {
      type: Array,
      required: true
    },

    options: {
      type: Array,
      required: true
    },

    // The key in the options data to use for the Value
    valueKey: {
      type: String,
      default: 'value'
    },

    // The key in the options data to use for the Label
    labelKey: {
      type: String,
      default: 'label'
    },

    placeholder: {
      type: String,
      default: 'Select'
    }
  },

  computed: {
    selectOptions() {
      let options = [];

      if (this.options.length > 0) {
        if (typeof this.options[0] === 'object') {
          // Assume the array of options are already properly formatted
          for (let option of this.options) {
            options.push({
              label: option[this.labelKey],
              value: this.valueKey ? option[this.valueKey] : option
            });
          }
        } else {
          // Format the flat array of scalar values
          for (let opt of this.options) {
            options.push({
              label: opt,
              value: opt
            });
          }
        }
      }

      return options;
    }
  },

  methods: {
    change(value) {
      this.$emit('input', value);
    }
  }
};
</script>
