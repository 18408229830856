var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.ad.orderProduct.is_ssp_product)?[_vm._v(" "+_vm._s(_vm.ad.orderProduct.product_variant_name)+" ")]:(
      (_vm.$store.getters['auth/isSupplier'] &&
        _vm.ad.orderProduct &&
        _vm.ad.orderProduct.product_variant &&
        !_vm.ad.orderProduct.product_variant.is_super) ||
        !_vm.ad.nationalTagPath
    )?[_c('div',[_c('router-link',{attrs:{"to":{
          name: 'supplier.mediakit.medium',
          params: { id: _vm.ad.orderProduct.medium_id }
        }}},[_vm._v(" "+_vm._s(_vm.ad.orderProduct.medium.name)+" ")])],1),_c('div',[_c('router-link',{attrs:{"to":{
          name: 'supplier.mediakit.property',
          params: { id: _vm.ad.orderProduct.property_id }
        }}},[_vm._v(" "+_vm._s(_vm.ad.orderProduct.property.name)+" ")])],1),_c('div',[_c('router-link',{attrs:{"to":{
          name: 'supplier.mediakit.collection',
          params: { id: _vm.ad.orderProduct.collection_id }
        }}},[_vm._v(" "+_vm._s(_vm.ad.orderProduct.collection.name)+" ")])],1),_c('div',[_vm._v(" "+_vm._s(_vm.ad.orderProduct.product.name)+", "+_vm._s(_vm.ad.orderProduct.product_variant.name)+" ")])]:[_c('div',[_vm._v(" "+_vm._s(_vm.ad.nationalTagPath.name)+" ")])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }