<template>
  <div id="page-supplier-billing" class="page-content text-dark-gray">
    <the-page-header>
      <template slot="title">
        Billing
      </template>
      <template slot="subtitle">
        All orders booked through your flytedesk account are shown below.
      </template>
    </the-page-header>

    <el-tabs
      :value="$route.name"
      @tab-click="$router.push({ name: $event.name })"
    >
      <el-tab-pane
        key="tab-billing-run-dates"
        name="supplier.run-dates"
        label="Ads"
      />
      <el-tab-pane
        key="tab-billing-invoices"
        name="supplier.invoices"
        label="Invoices"
      />
    </el-tabs>

    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';

export default {
  components: {
    ThePageHeader
  }
};
</script>
