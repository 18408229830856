<template>
  <div class="rep-column">
    <div v-if="order.reps && order.reps[0]">
      <ul>
        <li v-for="rep in order.reps" :key="`rep-${rep.id}`">
          <span :title="rep.email" class="icon-name">{{
            rep.name || rep.email
          }}</span>
        </li>
      </ul>
      <!--<div class="name">{{order.reps[0].name || order.reps[0].email}}</div>-->
    </div>
    <div v-else>
      No reps assigned
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  }
};
</script>
