import {
  Icon,
  LatLng,
  LatLngBounds,
  Map as LeafletMap,
  Marker,
  TileLayer
} from 'leaflet';

export class InteractiveMap {
  constructor(mapId, options) {
    this.mapId = mapId;
    this.options = options;
    this.map = null;
    this.markers = [];
  }

  /**
   * Initialize the map
   */
  init() {
    this.map = new LeafletMap(this.mapId, {
      maxZoom: 19,
      ...this.options
    });

    this.map.locate({
      setView: true,
      enableHighAccuracy: true,
      watch: true,
      zoom: 19
    });

    this.addMapTileLayer();
    return this;
  }

  /**
   * Add a tile layer to the map
   */
  addMapTileLayer() {
    const tileLayer = new TileLayer(
      '//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 19,
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }
    );
    this.map.addLayer(tileLayer);
    return this;
  }

  /**
   * Set the markers to display on the map and remove any old markers
   * @param newMarkers
   */
  setMarkers(newMarkers) {
    if (newMarkers.length > 0) {
      this.map.stopLocate();
    }

    // Add new markers
    for (let markerData of newMarkers) {
      let existingMarker = this.markers.find(m => m.data.id === markerData.id);
      if (existingMarker) {
        existingMarker.setIcon(this.getMarkerIcon(markerData));
      } else {
        this.createNewMarker(markerData);
      }
    }

    this.removeOldMarkers(newMarkers);
    this.zoomToFit();
    return this;
  }

  /**
   * Create a new marker and add it to the map
   * @param markerData
   */
  createNewMarker(markerData) {
    if (markerData.location.latitude && markerData.location.longitude) {
      const icon = this.getMarkerIcon(markerData);
      const marker = new Marker(
        [markerData.location.latitude, markerData.location.longitude],
        { icon }
      );
      marker.data = markerData;
      this.map.addControl(marker);
      this.markers.push(marker);

      return marker;
    }

    return null;
  }

  /**
   * Remove markers that are no longer in the list of markers
   * @param newMarkers
   */
  removeOldMarkers(newMarkers) {
    for (let existingMarker of this.markers) {
      if (!newMarkers.find(m => m.id === existingMarker.data.id)) {
        existingMarker.remove();
      }
    }
    this.markers = this.markers.filter(em =>
      newMarkers.find(nm => nm.id === em.data.id)
    );
  }

  /**
   * Resolve the marker's icon
   *
   * @param markerData
   * @returns {*}
   */
  getMarkerIcon(markerData) {
    return new Icon({
      iconUrl: '/v3/img/svg/location-pin-cube.svg',
      iconSize: [57, 80]
    });
  }

  /**
   * Zoom the map to fit all markers
   */
  zoomToFit() {
    if (this.markers.length > 0) {
      let minLat = Infinity;
      let minLng = Infinity;
      let maxLat = -Infinity;
      let maxLng = -Infinity;
      for (let marker of this.markers) {
        const lat = marker.getLatLng().lat;
        const lng = marker.getLatLng().lng;
        if (lat && lng) {
          minLat = Math.min(minLat, lat);
          minLng = Math.min(minLng, lng);
          maxLat = Math.max(maxLat, lat);
          maxLng = Math.max(maxLng, lng);
        }
      }

      if (
        minLat !== Infinity &&
        minLng !== Infinity &&
        maxLat !== -Infinity &&
        maxLng !== -Infinity
      ) {
        let corner1 = new LatLng(minLat, minLng);
        let corner2 = new LatLng(maxLat, maxLng);
        let latLngBounds = new LatLngBounds(corner1, corner2);
        this.map.fitBounds(latLngBounds);
      }
    }
  }
}
