<template>
  <div class="variant-rates-column">
    <custom-el-popover placement="bottom">
      <a slot="reference" class="text-green font-bold">
        <min-max-price :rate-sheets="savableRateSheets" show-max />
      </a>

      <div class="rates-popover pad">
        <el-form>
          <div class="rates-enabled">
            <custom-el-form-item
              label="Rate Types Enabled:"
              label-class="font-semibold text-md"
              no-click-focus
            >
              <el-checkbox-group
                class="margin-top-xs"
                :value="enabledRateClasses"
                @input="changeRateClasses"
              >
                <el-checkbox
                  v-for="rateClass in rateClasses"
                  :key="`rc-enabled-${rateClass.name}`"
                  :label="rateClass.name"
                >
                  {{ rateClass.label }}
                </el-checkbox>
              </el-checkbox-group>
            </custom-el-form-item>
          </div>

          <div class="rates-list margin-top-xxl">
            <div
              v-for="rateSheet in editableRateSheets"
              :key="`rate-sheet-${rateSheet.id}`"
              class="flex items-center"
            >
              <div
                v-for="rate in rateSheet.rates"
                :key="`rc-${rateSheet.id}-${rate.rate_class}`"
                class="block p-3"
              >
                <custom-el-form-item
                  :label="find(rateClasses, { name: rate.rate_class }).label"
                  label-class="font-semibold text-md"
                >
                  <el-input
                    v-model="rate.price"
                    v-mask.currency="{ allowEmpty: true }"
                    class="is-width-sm"
                    placeholder="$0.00"
                    @input="change"
                  />
                </custom-el-form-item>
              </div>

              <div class="block rate-unit">
                <div>
                  <div
                    v-if="rateSheet.type === RateType.IMPRESSIONS.value"
                    class="mt-3"
                  >
                    Per 1000 Impressions <b>(CPM)</b>
                  </div>
                  <template v-else>
                    <div class="relative">
                      <div class="flex ml-5">
                        <div class="w-48">
                          <el-select
                            v-model="rateSheet.type"
                            @input="onRateSheetTypeChange(rateSheet)"
                          >
                            <el-option
                              v-for="rateType in rateTypes"
                              :key="'rate-type-' + rateType.value"
                              :value="rateType.value"
                              :label="rateType.name"
                            />
                          </el-select>
                        </div>
                        <div
                          v-if="rateSheet.type === RateType.PER_QUANTITY.value"
                          class="ml-3 w-32"
                        >
                          <el-input
                            v-model="rateSheet.quantity"
                            v-mask.integer="{ min: 1 }"
                            @input="(rateSheet.quantity = +$event) && change()"
                          />
                        </div>
                      </div>
                      <div class="per-quantity-helper">
                        <el-tooltip effect="light">
                          <a>What is this?</a>
                          <template #content>
                            <div class="text-md">
                              <div
                                v-for="rateType in rateTypes"
                                :key="'rate-type-tooltip-' + rateType.value"
                                class="rate-type-tooltip my-4"
                              >
                                <div class="font-bold">{{ rateType.name }}</div>
                                <div class="ml-5 mt-2">
                                  {{ rateType.tooltip }}
                                </div>
                              </div>
                            </div>
                          </template>
                        </el-tooltip>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </custom-el-popover>
  </div>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import CustomElPopover from '@/components/Custom/CustomElPopover';
import MinMaxPrice from '@/components/Supplier/Mediakit/Common/MinMaxPrice';

import { float } from '@/utils/filters';
import { cloneDeep, find, isEqual, uniqueId } from 'lodash';

import {
  ContiguousRateTypes,
  NonContiguousRateTypes,
  RateClass,
  RateType,
  ScheduleTypeValue
} from '@/constants';

export default {
  components: {
    CustomElFormItem,
    CustomElPopover,
    MinMaxPrice
  },
  props: {
    variant: {
      type: Object,
      required: true
    },
    collection: {
      type: Object,
      required: true
    },
    enabledRateClasses: { type: Array, required: true },
    rateSheets: { type: Array, required: true },
    impressions: Boolean
  },
  data() {
    // Rate Sheets can be null
    let editableRateSheets = cloneDeep(this.rateSheets) || [];

    // Make sure there is at least one rate sheet that is not an Impressions Rate Sheet
    if (!this.rateSheets.find(rs => rs.type !== RateType.IMPRESSIONS.value)) {
      editableRateSheets.push(this.getNewRateSheet(RateType.PER_DAY));
    }

    if (this.impressions) {
      // If there is no Impressions Rate Sheet, lets create it
      if (!this.rateSheets.find(rs => rs.type === RateType.IMPRESSIONS.value)) {
        editableRateSheets.push(this.getNewRateSheet(RateType.IMPRESSIONS));
      }
    }

    return {
      editableRateSheets,
      RateType,
      rateClasses: [
        {
          name: RateClass.Standard,
          label: 'Standard'
        },
        {
          name: RateClass.Local,
          label: 'Local'
        }
      ]
    };
  },
  computed: {
    rateTypes() {
      return this.collection.schedule?.scheduleType.value ===
        ScheduleTypeValue.CONTIGUOUS
        ? ContiguousRateTypes
        : NonContiguousRateTypes;
    },
    savableRateSheets() {
      let rateSheets = cloneDeep(this.editableRateSheets);

      for (let rateSheet of rateSheets) {
        for (let rate of rateSheet.rates) {
          rate.price = float(rate.price);
        }
      }

      return rateSheets;
    }
  },
  created() {
    // Make sure we have all the data points
    this.normalizeRateSheets(this.enabledRateClasses);
  },
  methods: {
    find,
    getNewRateSheet(type) {
      return {
        id: uniqueId('rate-sheet-'),
        is_default: true,
        date_start: null,
        date_end: null,
        type: type.value,
        rates: []
      };
    },
    change() {
      if (!isEqual(this.rateSheets, this.savableRateSheets)) {
        this.$emit('update:rateSheets', this.savableRateSheets);
        this.$emit('change', this.savableRateSheets);
      }
    },
    onRateSheetTypeChange(rateSheet) {
      if (rateSheet.type !== RateType.PER_QUANTITY.value) {
        rateSheet.quantity = 1;
      }

      this.change();
    },
    /**
     * Make sure our list of editable rate sheets matches what the user selected
     * @param enabled
     */
    changeRateClasses(enabled) {
      this.$emit('update:enabledRateClasses', enabled);
      this.normalizeRateSheets(enabled);
      // notify parent of rate sheet changes
      this.change();
    },
    /**
     * For each rate sheet, make sure it has all of the expected rate classes,
     * in the enabledRateClasses list. They should be in the same order as the appear
     * in this.rateClasses
     */
    normalizeRateSheets(enabled) {
      for (let rateSheet of this.editableRateSheets) {
        let newRates = [];

        // loop through all rateClasses in order and either find the existing rate, or create a new one
        for (let rateClass of this.rateClasses) {
          if (enabled.indexOf(rateClass.name) >= 0) {
            let rate = find(rateSheet.rates, { rate_class: rateClass.name });

            // The rate was not found, so create a new rate to be appended to the list
            if (!rate) {
              rate = {
                id: uniqueId('rate-'),
                price: '',
                rate_class: rateClass.name,
                quantity: 1
              };
            }

            // Disable the price if it is 0
            if (!float(rate.price)) {
              rate.price = '';
            }

            newRates.push(rate);
          }
        }

        // Overwrite the existing rates with the updated list of rates
        rateSheet.rates = newRates;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.per-quantity-helper {
  position: absolute;
  bottom: -2.2em;
  left: 2em;
}
</style>
