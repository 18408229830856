<template>
  <tr
    class="invoice-line-item-row"
    :class="{ 'is-disabled': disabled }"
    @mouseenter="$emit('hovering', true)"
    @mouseleave="$emit('hovering', false)"
  >
    <td class="line-item-details">
      <div v-if="lineItem.name" class="line-item-name">
        <div class="line-item-input">{{ lineItem.name }}</div>
      </div>
      <div class="line-item-description">
        <transition name="fade" mode="out-in">
          <editable-document-item
            v-if="isEditableType && !disabled"
            :show-icon="false"
            :textarea="{
              placeholder: 'Enter line item description...',
              rows: 1,
              autosize: true
            }"
            dashed
            :value="lineItem.description"
            :editable="allowEditing"
            @change="debounceChange({ description: $event })"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
          />
          <div v-else class="line-item-input">
            {{ lineItem.description }}
          </div>
        </transition>
        <div v-if="lineItem.children" class="line-item-children">
          <div
            v-for="child in subLineItems"
            :key="'line-item-child-' + child.id"
            class="line-item-child flex"
          >
            <div class="child-name">{{ child.name }}</div>
            <div class="child-description">{{ child.description }}</div>
            <div class="child-amount">{{ child.amount | currency }}</div>
          </div>
        </div>
      </div>
    </td>
    <td class="line-item-date text-center">
      <div class="line-item-start-date">
        <transition name="fade" mode="out-in">
          <editable-document-item
            v-if="isEditableType && !disabled"
            :show-icon="false"
            datepicker
            dashed
            :value="lineItem.date"
            :editable="allowEditing"
            @change="$emit('change', { date: $event })"
          >
            <div class="line-item-input">
              {{ lineItem.date | date }}
            </div>
          </editable-document-item>
          <div v-else class="line-item-input">
            {{ lineItem.date | date }}
          </div>
        </transition>
      </div>
      <template v-if="showEndDate">
        <div class="line-item-date-to">to</div>
        <div class="line-item-end-date">
          {{ lineItem.end_date | date }}
        </div>
      </template>
    </td>
    <td class="line-item-quantity">
      <transition name="fade" mode="out-in">
        <editable-document-item
          v-if="isEditableType && !disabled"
          :show-icon="false"
          dashed
          :value="lineItem.quantity"
          :editable="allowEditing"
          @change="$emit('change', { quantity: +$event })"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
        />
        <div v-else class="line-item-input">
          {{ lineItem.quantity }}
        </div>
      </transition>
    </td>
    <td class="line-item-amount relative">
      <transition name="fade" mode="out-in">
        <editable-document-item
          v-if="isEditableType && !disabled"
          :show-icon="false"
          currency
          dashed
          :value="lineItem.amount"
          :editable="allowEditing"
          @change="$emit('change', { amount: $event })"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
        />
        <div v-else class="line-item-input">
          <div
            v-if="originalAmount !== lineItem.amount"
            class="text-dark-silver line-through"
          >
            {{ originalAmount | currency }}
          </div>
          <div>
            {{ lineItem.amount | currency }}
          </div>
        </div>
      </transition>

      <a
        class="remove-line-item text-red hover:text-dark-red hover:bg-light-red"
        :style="{ opacity: allowRemoving ? 1 : 0 }"
        @click="allowRemoving && (showConfirmRemoveDialog = true)"
      >
        <icon :icon="trashIcon" />
      </a>

      <confirm-dialog
        v-if="showConfirmRemoveDialog"
        :modal-append-to-body="false"
        :append-to-body="false"
        title="Confirm Remove Line Item"
        confirm-class="button-red"
        confirm-text="Remove"
        width="40em"
        @confirm="$emit('remove') && (showConfirmRemoveDialog = false)"
        @close="showConfirmRemoveDialog = false"
      >
        <div>Are you sure you want to remove the following line item?</div>

        <div class="mt-5">
          <div>Date: {{ lineItem.date | date }}</div>
          <div>Amount: {{ lineItem.amount | currency }}</div>
          <div>{{ lineItem.name }}</div>
          <div>{{ lineItem.description }}</div>
        </div>
      </confirm-dialog>
    </td>
  </tr>
</template>

<script>
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import EditableDocumentItem from '@/components/Supplier/Billing/Document/EditableDocumentItem';
import { DocumentLineItemTypeKeys, OrderSubLineItemType } from '@/constants';

import {
  arrowsCw as savingIcon,
  lock as lockIcon,
  trashEmpty as trashIcon
} from '@/vendor/icons';
import { debounce } from 'lodash';
import { datetimeDb } from '@/utils/filters';
import moment from 'moment';

export default {
  components: {
    ConfirmDialog,
    EditableDocumentItem
  },
  props: {
    editable: Boolean,
    saving: Boolean,
    disabled: Boolean,
    locked: Boolean,
    lineItem: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      DocumentLineItemTypeKeys,
      showConfirmRemoveDialog: false,

      // Icons
      lockIcon,
      savingIcon,
      trashIcon
    };
  },
  computed: {
    isEditableType() {
      return this.lineItem.type !== DocumentLineItemTypeKeys.SALE;
    },
    allowEditing() {
      return (
        this.isEditableType && this.editable && !this.locked && !this.disabled
      );
    },
    allowRemoving() {
      return this.editable && !this.locked && !this.disabled;
    },
    showEndDate() {
      return (
        moment(this.lineItem.date).diff(this.lineItem.end_date, 'days') < 0
      );
    },
    originalAmount() {
      let amount = this.lineItem.amount;

      for (let subLineItem of this.subLineItems) {
        amount -= subLineItem.amount;
      }

      return amount;
    },
    subLineItems() {
      let subLineItems = [];

      for (let child of this.lineItem.children) {
        if (child.name !== OrderSubLineItemType.ADJUSTMENT.label) {
          subLineItems.push(child);
        }
      }

      return subLineItems;
    }
  },
  methods: {
    datetimeDb,
    debounceChange: debounce(function(input) {
      this.$emit('change', input);
    }, 1000)
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables.scss';

.invoice-line-item-row {
  &.is-disabled {
    opacity: 0.5;
    background: $color-silver;
  }
}

.remove-line-item {
  position: absolute;
  right: -2.8em;
  top: 0.2em;
  padding: 0.5em 1em;
  border-radius: 50%;
  transition: all 0.3s;
}

.line-item-description {
  // This ensures height doesn't flicker when animating editable item
  min-height: 2.32em;
}

.line-item-input {
  padding: 0.3em 1em;
}

.line-item-date-to {
  text-align: center;
}

.line-item-children {
  margin-left: 2em;
  color: $color-dark-silver;

  .line-item-child {
    .child-amount {
      margin-left: 0.5em;
    }
  }
}
</style>
