<template>
  <div class="ad-custom-form">
    <form-renderer
      v-if="!isLoading"
      :form="customForm"
      :value="value"
      :is-disabled="isDisabled"
      class="p-3"
      @uploading="$emit('uploading', $event)"
      @ready="isFormReady = true"
      @input="onInput"
    />
    <template v-else>
      <loader-box height="2.8em" class="mt-6" />
      <loader-box height="2.8em" class="mt-6" />
      <loader-box height="2.8em" class="mt-6" />
      <loader-box height="2.8em" class="mt-6" />
    </template>
  </div>
</template>
<script>
import FormRenderer from '@/components/Core/Form/FormBuilder/FormRenderer';

import { debounce } from 'lodash';
import { DefaultCreativeBuilderFields } from '@/constants';

export default {
  components: { FormRenderer },
  props: {
    form: {
      type: Object,
      default: null
    },
    value: {
      type: Array,
      default: () => []
    },
    defaultFields: {
      type: Array,
      default: () => {
        return DefaultCreativeBuilderFields;
      }
    },
    isDisabled: Boolean,
    isLoading: Boolean,
    isSaving: Boolean
  },
  data() {
    return {
      // Set after the form loads w/ default values to avoid saving on initialization
      isFormReady: false
    };
  },
  computed: {
    customForm() {
      return {
        ...(this.form || {}),
        fields: this.fields
      };
    },
    fields() {
      return this.form ? this.form.fields : this.defaultFields;
    }
  },
  methods: {
    onInput(input) {
      this.$emit('input', input);

      if (this.isFormReady) {
        this.debounceSave();
      }
    },
    debounceSave: debounce(function() {
      this.$emit('save');
    }, 500)
  }
};
</script>
