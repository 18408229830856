<template>
  <div class="billing-amount-column">
    <div class="whitespace-no-wrap" @click.stop.prevent>
      <order-line-item-supplier-price-adjuster
        :tab-index="tabIndex"
        :line-item="lineItem"
        :is-national="isNational"
        :disabled="!canModify"
        :show="show"
        @update:show="$emit('update:show', $event)"
      />
    </div>
    <div v-if="isPayoutCpm" class="mt-1">
      Delivery: {{ lineItem.delivery_supplier_price | currency }}
      <info-tooltip>
        <div>
          The amount calculated based on your CPM pricing and actual
          impressions.
          <div class="text-dark-silver">NOTE: This amount is updated daily</div>
        </div>
        <div class="mt-2 py-2 w-64 flex flex-col flex-end">
          <div class="flex flex-grow">
            <div class="w-1/2 flex-grow text-right">
              {{ ad.payout_rate | currency }}
            </div>
            <div class="w-1/2 flex-grow ml-2 text-left">CPM</div>
          </div>
          <div class="flex flex-no-wrap">
            <div class="w-1/2 flex-grow whitespace-no-wrap text-right">
              x
              {{
                ((1000 * lineItem.delivery_supplier_price) / ad.payout_rate)
                  | number(0)
              }}
            </div>
            <div class="w-1/2 flex-grow ml-2 text-left whitespace-no-wrap">
              Impressions / 1000
            </div>
          </div>
          <div class="flex flex-start font-bold pt-2">
            <div class="w-1/2 flex-grow text-right">
              <span class="border-top border-2">
                {{ lineItem.delivery_supplier_price | currency }}
              </span>
            </div>
            <div class="w-1/2 flex-grow"></div>
          </div>
        </div>
      </info-tooltip>
    </div>
  </div>
</template>

<script>
import { AdGroupRateType, CampaignType } from '@/constants';
import OrderLineItemSupplierPriceAdjuster from '@/components/Order/OrderLineItemSupplierPriceAdjuster.vue';
import InfoTooltip from '@/components/Core/Tooltips/InfoTooltip';

export default {
  components: {
    InfoTooltip,
    OrderLineItemSupplierPriceAdjuster
  },
  props: {
    show: {
      type: Object,
      default: null
    },
    tabIndex: { type: Number, default: null },
    lineItem: {
      type: Object,
      required: true
    }
  },

  computed: {
    ad() {
      return this.lineItem.ads[0] || {};
    },
    isPayoutCpm() {
      return (
        this.ad.payout_type === AdGroupRateType.CPM.value ||
        this.ad.adGroup?.payout_type === AdGroupRateType.CPM.value
      );
    },
    isNational() {
      return this.lineItem.order?.campaign?.type === CampaignType.NATIONAL;
    },
    canModify() {
      return this.isNational
        ? this.$can('takeover_suppliers') && !this.isPayoutCpm
        : this.$can('pub_edit_line_item_amount');
    }
  }
};
</script>
