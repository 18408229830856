<template>
  <supplier-billing-mutation :show-error-message="false">
    <template
      v-slot="{
        isSaving,
        clearSelections,
        exportOrderLineItems
      }"
    >
      <filter-toolbar
        class="items-end"
        :value="value.filter"
        :is-exporting="isExporting"
        @input="
          $emit('input', {
            ...value,
            filter: $event
          })
        "
        @export="onExport(exportOrderLineItems)"
      >
        <transition name="fade">
          <div
            v-if="selectionsSummary && selectionsSummary.total_selected > 0"
            class="flex"
          >
            <table-selections-count
              :count="selectionsSummary.total_selected"
              :is-saving="isSaving"
              @clear="clearSelections"
            />
            <el-dropdown trigger="click" @command="handleCommand">
              <el-button class="min-w-10">
                <div class="flex justify-between">
                  Action <i class="el-icon-arrow-down el-icon--right"></i>
                </div>
              </el-button>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="invoice">
                  Create Invoice
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </transition>

        <div v-if="$feature('bulk-invoicing')" class="ml-4">
          <el-button class="button-blue" @click="showBulkInvoiceDialog = true">
            Bulk Invoice
          </el-button>
          <bulk-invoice-dialog
            v-if="showBulkInvoiceDialog"
            @close="onCloseBulkInvoice"
          />
        </div>
      </filter-toolbar>
    </template>
  </supplier-billing-mutation>
</template>

<script>
import FilterToolbar from '@/components/Core/Table/FilterToolbar';
import { SupplierBillingMutation } from '@/components/Mutations';
import { download } from '@/utils/helpers';
import TableSelectionsCount from '@/components/Core/Table/TableSelectionsCount';
import BulkInvoiceDialog from '@/components/Accounting/BulkInvoiceDialog';
import { apolloClient } from '@/vue-apollo';

export default {
  components: {
    BulkInvoiceDialog,
    TableSelectionsCount,
    FilterToolbar,
    SupplierBillingMutation
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    selectionsSummary: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showBulkInvoiceDialog: false,
      isExporting: false
    };
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case 'invoice':
          this.$emit('create-invoice');
          break;
      }
    },
    async onExport(exportOrderLineItems) {
      this.isExporting = true;

      const data = await exportOrderLineItems(this.value.filter);

      download(data.mutateOrder.exportLineItems, 'AdRunDates.csv');

      this.isExporting = false;
    },
    onCloseBulkInvoice() {
      this.showBulkInvoiceDialog = false;
      apolloClient.resetStore();
    }
  }
};
</script>
