<template>
  <el-dropdown
    class="select-column"
    placement="bottom"
    trigger="click"
    @command="change"
  >
    <template v-if="selectOptions.length > 0">
      <div
        class="select-value"
        :class="selectedOption ? 'is-selected' : 'is-empty'"
      >
        {{ selectedValue }}
      </div>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="option in selectOptions"
          :key="`select-col-${option.value}`"
          :command="option.value"
        >
          {{ option.label }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>

    <template v-else>
      <div class="select-value is-empty-data">
        <div class="whitespace-pre-wrap">{{ emptyText }}</div>
      </div>
    </template>
  </el-dropdown>
</template>

<script>
import { find } from 'lodash';

export default {
  name: 'SelectColumn',
  props: {
    value: {
      type: String,
      required: true
    },

    options: {
      type: Array,
      required: true
    },

    // The key in the options data to use for the Value
    valueKey: {
      type: String,
      default: 'value'
    },

    // The key in the options data to use for the Label
    labelKey: {
      type: String,
      default: 'label'
    },

    placeholder: {
      type: String,
      default: 'Select'
    },

    emptyText: {
      type: String,
      default: 'There are no options available.'
    }
  },

  computed: {
    selectedValue() {
      if (this.selectedOption) {
        return this.selectedOption.label;
      }

      return this.placeholder;
    },

    selectedOption() {
      return find(this.selectOptions, { value: this.value });
    },

    selectOptions() {
      let options = [];

      if (this.options.length > 0) {
        if (typeof this.options[0] === 'object') {
          // Assume the array of options are already properly formatted
          for (let option of this.options) {
            options.push({
              label: option[this.labelKey],
              value: this.valueKey ? option[this.valueKey] : option
            });
          }
        } else {
          // Format the flat array of scalar values
          for (let opt of this.options) {
            options.push({
              label: opt,
              value: opt
            });
          }
        }
      }

      return options;
    }
  },

  methods: {
    change(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables';

.select-column {
  width: 100%;
  cursor: pointer;
  padding: 0.3em 0.8em;
  border-radius: 4px;

  &:hover {
    background: $color-baby-blue;
  }
}

.select-value {
  &.is-empty {
    font-style: italic;
  }
}
</style>
