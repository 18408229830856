<template>
  <div class="creative-column has-content-centered" @click="showDialog = true">
    <template v-if="hasCreativeContent">
      <form-response-preview
        :response="ad.creative.formResponse"
        @click="showDialog = true"
      />

      <div>
        <a class="font-bold" @click="showDialog = true">
          {{ requiresReview ? 'Review' : 'View Creative' }}
        </a>
      </div>

      <status-icon
        v-if="showStatus && ad.creative"
        :status="ad.creative.status"
        :icons="CreativeStatusIcons"
        inline
      />
    </template>

    <template v-else>
      <template v-if="isAllowedToEdit">
        <tooltip-icon
          v-if="isActive"
          :is-disabled="isDisabled"
          :icon="plus"
          icon-class="icon-md text-blue cursor-pointer hover:text-dark-blue"
          @click="showDialog = true"
        />
        <div v-else class="text-red">No Creative (Inactive)</div>
      </template>
      <template v-else-if="isNational">
        <i>Pending upload from flytedesk</i>
      </template>
      <template v-else>
        <i>No Creative</i>
      </template>
    </template>

    <a @click="showCreativePhaseDialog = true">
      Creative Phase: <b>{{ ad.creative_phase }}</b>
    </a>
    <creative-dialog
      v-if="showDialog || startOpen"
      :ad="ad"
      :is-disabled="isDisabled || !isAllowedToEdit"
      @close="(showDialog = false) || $emit('close', true)"
    />
  </div>
</template>

<script>
import CreativeDialog from '@/components/Core/Dialogs/CreativeDialog';
import FormResponsePreview from '@/components/Core/Form/FormBuilder/FormResponsePreview';
import StatusIcon from '@/components/Core/Statuses/StatusIcon';
import TooltipIcon from '@/components/Core/TooltipIcon';

import {
  AccountType,
  AdInactiveStatuses,
  CampaignType,
  CreativeStatus,
  CreativeStatusIcons
} from '@/constants';

import { plus } from '@/vendor/icons';

export default {
  components: {
    CreativeDialog,
    FormResponsePreview,
    StatusIcon,
    TooltipIcon
  },
  props: {
    ad: {
      type: Object,
      required: true
    },
    isDisabled: Boolean,
    showStatus: Boolean,
    startOpen: Boolean
  },

  data() {
    return {
      AccountType,
      CreativeStatus,
      CreativeStatusIcons,
      showDialog: false,

      // Icons
      plus
    };
  },

  computed: {
    accountType() {
      return this.$store.getters['auth/accountType'];
    },

    isNational() {
      return (
        this.ad.campaign && this.ad.campaign.type === CampaignType.NATIONAL
      );
    },

    isActive() {
      return !AdInactiveStatuses[this.ad.status];
    },

    isAllowedToEdit() {
      switch (this.accountType) {
        case AccountType.BUYER:
          return this.isActive && this.$can('buyer_manage_creative');

        case AccountType.SUPPLIER:
        case AccountType.ADMIN:
        default:
          if (this.ad.adGroup) {
            return false;
          }

          return (
            (this.isNational && this.$can('set_national_creative')) ||
            this.$can('pub_manage_creative')
          );
      }
    },

    requiresReview() {
      if (this.isAllowedToEdit && this.hasCreativeContent) {
        switch (this.accountType) {
          case AccountType.SUPPLIER:
            return this.ad.creative.status === CreativeStatus.PENDING_SUPPLIER;
          case AccountType.BUYER:
            return this.ad.creative.status === CreativeStatus.PENDING_BUYER;

          case AccountType.ADMIN:
            return [
              CreativeStatus.PENDING_BUYER,
              CreativeStatus.PENDING_SUPPLIER
            ].includes(this.ad.creative.status);

          default:
            return false;
        }
      }

      return false;
    },

    hasCreativeContent() {
      return !!(
        this.ad.creative &&
        this.ad.creative.formResponse &&
        !this.ad.creative.formResponse.is_empty
      );
    }
  }
};
</script>
