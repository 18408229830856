<template>
  <div class="date-column">
    <template v-if="startDate">
      <div>{{ startDate | date }}</div>
      <template v-if="showEndDate">
        <div>to</div>
        <div>{{ endDate | date }}</div>
      </template>
    </template>
    <template v-else>
      No Run Dates
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import { calendar, cancel as cancelIcon } from '@/vendor/icons';

export default {
  props: {
    startDate: { type: String, default: null },
    endDate: { type: String, default: null }
  },

  data() {
    return {
      // Icons
      calendar,
      cancelIcon
    };
  },

  computed: {
    showEndDate() {
      let duration = moment(this.endDate).diff(this.startDate);

      return (
        this.endDate && duration > moment.duration(1, 'day').asMilliseconds()
      );
    }
  }
};
</script>
