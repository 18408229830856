<template>
  <div class="invoice-status-column">
    <status-icon
      :loading="loading"
      :icons="InvoiceStatusIcons"
      :status="status"
    />
  </div>
</template>

<script>
import StatusIcon from '@/components/Core/Statuses/StatusIcon';

import { InvoiceStatus, InvoiceStatusIcons } from '@/constants';

export default {
  components: {
    StatusIcon
  },
  props: {
    invoice: {
      type: Object,
      required: true
    },
    loading: Boolean
  },
  data() {
    return {
      InvoiceStatusIcons
    };
  },
  computed: {
    status() {
      let status = this.invoice.status;
      if (this.invoice.delete_started_at) {
        status = InvoiceStatus.DELETING.label;
      }
      return status;
    }
  }
};
</script>
