<template>
  <div class="order-payment-column">
    <div>{{ billingPreference }}</div>
    <div>{{ order.paymentMethod.name }}</div>
    <div>{{ rateClass }}</div>
    <div v-if="isChangesRequested">
      <a @click="$emit('review', order)">Review</a>
    </div>
  </div>
</template>

<script>
import {
  BillingPreferenceLabel,
  OrderApprovalStatus,
  RateClassLabel
} from '@/constants';

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },

  computed: {
    isChangesRequested() {
      return (
        this.order.approval_status === OrderApprovalStatus.CHANGES_REQUESTED
      );
    },
    rateClass() {
      return RateClassLabel[this.order.rate_class];
    },

    billingPreference() {
      return BillingPreferenceLabel[this.order.billing_preference];
    }
  }
};
</script>
