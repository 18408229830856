<template>
  <div class="flex flex-grow flex-col">
    <div class="border-bottom border-gray pb-5 flex-grow-0">
      <a class="flex items-center font-bold" @click="$emit('close')">
        <arrow-left class="mr-2" />
        Back
      </a>
    </div>
    <div class="flex-grow pt-5">
      <leaflet-map :markers="filesWithLocation" />
    </div>
  </div>
</template>
<script>
import ArrowLeft from '@/components/Svg/ArrowLeft';
import LeafletMap from '@/components/Core/Vendor/LeafletMap';

export default {
  components: { LeafletMap, ArrowLeft },
  props: {
    files: {
      type: Array,
      required: true
    }
  },
  computed: {
    filesWithLocation() {
      return this.files.filter(file => file.location);
    }
  }
};
</script>
