<template>
  <div
    class="creative-reviewer-sidebar text-dark-gray p-5 shadow-right text-left w-72 pb-8"
  >
    <div
      v-if="!isNational"
      class="requirements pb-4 border-bottom border-very-light-gray"
    >
      <h4 class="font-semibold">Requirements</h4>
      <fulfillment-info
        v-if="ad.fulfillment_method"
        :deadline-date="ad.deadline_date"
        :fulfillment="ad.fulfillment_method"
      />
      <template v-else>N/A</template>
    </div>

    <div class="approvals mt-5 pb-4">
      <h4 class="font-semibold">Approvals</h4>

      <div class="flex mt-3 overflow-hidden">
        <icon
          :icon="supplierStatus.icon"
          class="text-xl"
          :class="supplierStatus.class"
        />
        <div class="ml-3 leading-normal">
          <div class="whitespace-no-wrap">
            {{ supplier.name }}
          </div>
          <div>{{ supplierStatus.label }}</div>
        </div>
      </div>

      <div class="flex mt-3 overflow-hidden">
        <icon
          :icon="buyerStatus.icon"
          class="text-xl"
          :class="buyerStatus.class"
        />
        <div class="ml-3 leading-normal">
          <div class="whitespace-no-wrap">
            {{ isNational ? 'Flytedesk' : buyer.buyer_company }}
          </div>
          <div>{{ buyerStatus.label }}</div>
        </div>
      </div>

      <div v-if="creative" class="rejection-notes">
        <div v-if="creative.supplierRejectionNote" class="mt-3">
          <div class="font-semibold">Supplier Rejected</div>
          <div>{{ creative.supplierRejectionNote.text }}</div>
        </div>

        <div v-if="creative.buyerRejectionNote" class="mt-3">
          <div class="font-semibold">Buyer Rejected</div>
          <div>{{ creative.buyerRejectionNote.text }}</div>
        </div>
      </div>

      <div class="flex flex-col mt-5">
        <loading-button v-if="isLoading" class="w-full my-1" />
        <template v-else>
          <div v-if="canApproveCreative">
            <template v-if="$can('manage_non_account_creative')">
              <el-button
                v-if="!isSupplierApproved"
                class="button-green w-full my-1"
                @click="$emit('approve', AccountType.SUPPLIER)"
              >
                Approve For Publisher
              </el-button>
              <el-button
                v-if="!isBuyerApproved"
                class="button-green w-full my-1"
                @click="$emit('approve', AccountType.BUYER)"
              >
                Approve For Buyer
              </el-button>
            </template>

            <template v-else>
              <el-button
                class="button-green w-full my-1"
                @click="$emit('approve')"
              >
                Approve
              </el-button>
            </template>
          </div>
          <div v-if="canRejectCreative">
            <template v-if="$can('manage_non_account_creative')">
              <el-button
                v-if="!isSupplierRejected && !ad.adGroup"
                class="button-white-red w-full my-1"
                @click="$emit('reject', AccountType.SUPPLIER)"
              >
                Reject For Publisher
              </el-button>
              <el-button
                v-if="!isBuyerRejected"
                class="button-white-red w-full my-1"
                @click="$emit('reject', AccountType.BUYER)"
              >
                Reject For Buyer
              </el-button>
            </template>
            <template v-else>
              <el-button
                v-if="
                  !isBuyerApproved && $feature('pub-approve-creative-for-buyer')
                "
                class="button-green w-full my-1"
                @click="$emit('approve', AccountType.BUYER)"
              >
                Approve Creative for Buyer
              </el-button>
              <el-button
                class="button-white-red w-full my-1"
                @click="$emit('reject')"
              >
                Reject
              </el-button>
            </template>
          </div>
          <div v-if="ad.adGroup" class="mt-5">
            <icon :icon="warningIcon" class="text-yellow" />

            This ad belongs to the ad group {{ ad.adGroup.name }}. The creative
            is managed by Flytedesk.
          </div>
        </template>
      </div>
    </div>

    <div>
      <h4>Product</h4>
      <ad-order-product-info class="mt-2" :ad="ad" />
    </div>
    <div v-if="adDimensions" class="dimensions mt-5">
      <h4>Dimensions</h4>
      <div class="text-green mt-2 font-bold">{{ adDimensions }}</div>
    </div>
  </div>
</template>

<script>
import FulfillmentInfo from '@/components/Supplier/Fulfillment/FulfillmentInfo';
import LoadingButton from '@/components/Core/Loading/LoadingButton';

import {
  AccountType,
  AdStatus,
  CampaignType,
  CreativeStatus
} from '@/constants';
import { attention as warningIcon, clock, disable, ok } from '@/vendor/icons';
import { date as formatDate } from '@/utils/filters';
import AdOrderProductInfo from '@/components/Ads/AdOrderProductInfo';

export default {
  components: { AdOrderProductInfo, FulfillmentInfo, LoadingButton },
  props: {
    ad: {
      type: Object,
      required: true
    },
    supplier: {
      type: Object,
      required: true
    },
    buyer: {
      type: Object,
      required: true
    },
    isLoading: Boolean
  },

  data() {
    return {
      AccountType,

      // Icons
      clock,
      disable,
      ok,
      warningIcon
    };
  },

  computed: {
    isSupplier() {
      return this.accountType === AccountType.SUPPLIER;
    },
    accountType() {
      return this.$store.getters['auth/accountType'];
    },
    isReviewable() {
      return this.ad.status !== AdStatus.CANCELED && this.creative;
    },
    adDimensions() {
      let dimensions = this.ad.orderProduct.product_variant?.attributes
        ?.Dimensions;

      if (dimensions) {
        if (dimensions.width_ratio) {
          return `${dimensions.width_ratio}:${dimensions.height_ratio} Ratio`;
        } else if (dimensions.width) {
          return `${dimensions.width}x${dimensions.height} ${dimensions.unit}`;
        }
      }
      return null;
    },
    hasRejectionNotes() {
      if (this.creative) {
        return (
          this.creative.supplierRejectionNote ||
          this.creative.buyerRejectionNote
        );
      }

      return false;
    },

    canApproveCreative() {
      if (
        this.isReviewable &&
        this.creativeStatus !== CreativeStatus.ACCEPTED
      ) {
        if (this.$can('manage_non_account_creative')) {
          return true;
        }

        switch (this.accountType) {
          case AccountType.SUPPLIER:
            return this.creativeStatus !== CreativeStatus.PENDING_BUYER;

          case AccountType.BUYER:
            return this.creativeStatus !== CreativeStatus.PENDING_SUPPLIER;
        }
      }

      return false;
    },
    canRejectCreative() {
      return (
        this.isReviewable &&
        ![CreativeStatus.PENDING, CreativeStatus.REJECTED].includes(
          this.creativeStatus
        )
      );
    },
    creativeStatus() {
      return this.creative && this.creative.status;
    },
    creative() {
      return this.ad.creative;
    },

    supplierStatus() {
      if (this.isSupplierApproved) {
        return {
          icon: ok,
          class: 'text-green',
          label: formatDate(this.creative.date_supplier_approved)
        };
      } else if (this.isSupplierRejected) {
        return {
          icon: disable,
          class: 'text-red',
          label: formatDate(this.creative.date_supplier_rejected)
        };
      } else {
        return {
          icon: clock,
          class: 'text-blue',
          label: 'Pending Approval'
        };
      }
    },

    buyerStatus() {
      if (this.isBuyerApproved) {
        return {
          icon: ok,
          class: 'text-green',
          label: formatDate(this.creative.date_buyer_approved)
        };
      } else if (this.isBuyerRejected) {
        return {
          icon: disable,
          class: 'text-red',
          label: formatDate(this.creative.date_buyer_rejected)
        };
      } else {
        return {
          icon: clock,
          class: 'text-blue',
          label: 'Pending Approval'
        };
      }
    },

    isNational() {
      return (
        this.ad.campaign && this.ad.campaign.type === CampaignType.NATIONAL
      );
    },

    isSupplierApproved() {
      return this.creative && !!this.creative.date_supplier_approved;
    },

    isSupplierRejected() {
      return (
        !this.isSupplierApproved &&
        this.creative &&
        !!this.creative.date_supplier_rejected
      );
    },

    isBuyerApproved() {
      return this.creative && !!this.creative.date_buyer_approved;
    },

    isBuyerRejected() {
      return (
        !this.isBuyerApproved &&
        this.creative &&
        !!this.creative.date_buyer_rejected
      );
    }
  }
};
</script>
