<script>
import { renderless } from '@/utils/helpers';

import stripeLoader from '@/vendor/stripe';

export default {
  props: {
    card: {
      type: Object,
      default: null
    },
    form: {
      type: Object,
      default: null
    }
  },

  computed: {
    stripeParams() {
      return {
        address_country: this.form.country,
        name: `${this.form.first_name} ${this.form.last_name}`,
        address_line1: this.form.address || '',
        address_line2: this.form.address2 || '',
        address_city: this.form.city || '',
        address_state: this.form.state || '',
        address_zip: this.form.zip_code || ''
      };
    }
  },

  methods: {
    async createToken() {
      const stripe = await stripeLoader();

      const result = await stripe.createToken(this.card, this.stripeParams);

      if (result.error) {
        throw new Error(result.error.message);
      }

      // The generated token
      return result.token;
    }
  },

  render(c) {
    return renderless.call(this, c, {
      createToken: this.createToken
    });
  }
};
</script>
