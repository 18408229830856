<template>
  <div class="page-invoice-document">
    <supplier-invoice-document-query
      :id="$route.params.id"
      :loading.sync="isLoadingInvoice"
      @data="onLoadedInvoice"
    />

    <div class="invoice-page-header relative text-2xl">
      <div class="flex w-full">
        <div class="back-link px-5 py-4 font-bold">
          <router-link :to="{ name: 'supplier.invoices' }">
            <icon :icon="backIcon" flip="horizontal" />
            Back to Invoices
          </router-link>
        </div>
      </div>
    </div>

    <el-alert
      v-if="!invoice && !isLoadingInvoice"
      class="text-center text-lg text-bold my-4 mx-auto w-9/10"
      title="Unable to find the requested Invoice"
      type="error"
    />

    <div v-if="isDeleting" class="mt-3">
      <el-alert
        class="text-center text-lg text-bold my-4 mx-auto w-9/10"
        title="This invoice is being deleted"
        type="error"
      >
      </el-alert>
    </div>

    <div class="invoice-doc-wrap flex justify-center my-5">
      <div class="flex-none relative">
        <supplier-invoice-document-mutation>
          <template
            v-slot="{
              isSaving,
              updateInvoice,
              unlockInvoice,
              createLineItem,
              removeLineItem,
              updateLineItem
            }"
          >
            <invoice-document
              class="m-auto"
              shadow
              :invoice="invoice"
              :editable="allowEditing"
              :locked="isLocked"
              :hovering.sync="isHoveringDocument"
              :disabled="isExporting || isLoadingInvoice"
              :exporting="isExporting"
              :saving="isSaving"
              @focus="isFocusedDocument = true"
              @blur="isFocusedDocument = false"
              @change="updateInvoice(invoice, $event)"
              @create-item="createLineItem(invoice, $event)"
              @update-item="updateLineItem(invoice, $event.id, $event.input)"
              @remove-item="removeLineItem(invoice, $event)"
              @unlock="showConfirmUnlockDialog = true"
            />

            <confirm-dialog
              v-if="showConfirmUnlockDialog"
              confirm-text="Unlock"
              confirm-class="button-blue"
              :is-saving="isSaving"
              width="40em"
              title="Unlock Invoice For Editing"
              @confirm="onUnlockInvoice(unlockInvoice)"
              @close="showConfirmUnlockDialog = false"
            >
              This Invoice is locked for editing because it already has been
              sent to the Buyer. You may unlock this Invoice to edit it by
              Voiding the Invoice and creating a new Invoice that is a copy of
              this one.
              <br /><br />
              Are you sure you want to Void the current Invoice and create a
              copy to make changes?
            </confirm-dialog>
          </template>
        </supplier-invoice-document-mutation>
      </div>

      <div v-if="!isDeleting" class="ml-5">
        <invoice-document-sidebar
          class="invoice-document-sidebar"
          :invoice="invoice"
          :is-exporting.sync="isExporting"
          @update:isExporting="isExporting = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import InvoiceDocument from '@/components/Supplier/Billing/Document/InvoiceDocument/InvoiceDocument';
import InvoiceDocumentSidebar from '@/components/Supplier/Billing/Document/InvoiceDocument/InvoiceDocumentSidebar';
import SupplierInvoiceDocumentMutation from '@/components/Mutations/Supplier/SupplierInvoiceDocumentMutation';
import SupplierInvoiceDocumentQuery from '@/components/Queries/Supplier/SupplierInvoiceDocumentQuery';
import { InvoiceStatusIcons, InvoiceStatusKeys } from '@/constants';
import { share as backIcon } from '@/vendor/icons';

export default {
  components: {
    ConfirmDialog,
    InvoiceDocument,
    InvoiceDocumentSidebar,
    SupplierInvoiceDocumentMutation,
    SupplierInvoiceDocumentQuery
  },
  data() {
    return {
      invoice: null,
      InvoiceStatusIcons,
      isHoveringDocument: false,
      isFocusedDocument: false,
      isLoadingInvoice: false,
      isLocked: true,
      isExporting: false,
      isDeleting: false,
      showConfirmUnlockDialog: false,
      // Icons
      backIcon
    };
  },
  computed: {
    allowEditing() {
      return (
        (this.isHoveringDocument || this.isFocusedDocument) &&
        this.invoice &&
        this.invoice.status !== InvoiceStatusKeys.VOID
      );
    }
  },
  methods: {
    onLoadedInvoice(data) {
      this.invoice = data.supplier.invoice;
      if (this.invoice) {
        this.isDeleting = !!this.invoice.delete_started_at;
        this.isLocked = this.invoice.status !== InvoiceStatusKeys.DRAFT;
      }
    },
    async onUnlockInvoice(unlockInvoice) {
      const {
        mutateInvoice: { unlock, invoice }
      } = await unlockInvoice(this.invoice);

      this.showConfirmUnlockDialog = false;

      if (unlock.id) {
        this.$message.success(
          `The Invoice ${invoice.ref} was Voided and the new Invoice ${unlock.ref} has been created`
        );
        this.$router.push({
          name: 'supplier.invoice',
          params: { id: unlock.id }
        });
      } else {
        this.$message.error(
          'There was a problem while unlocking the Invoice. Please try again later or contact us for help.'
        );
      }
    }
  }
};
</script>

<style lang="scss">
@media print {
  .invoice-page-header,
  .invoice-document-sidebar {
    display: none;
  }

  .invoice-doc-wrap {
    display: block;
    padding: 0;
    background: white;
  }
}
</style>
