<template>
  <div class="form-renderer">
    <div class="form-fields">
      <form-renderer-field
        v-for="field in formFields"
        :key="'form-field-' + field.id"
        class="form-field w-full"
        :field="field"
        :value="getValue(field)"
        :is-disabled="isDisabled"
        @uploading="$emit('uploading', $event)"
        @ready="onReady(field)"
        @input="onInput(field, $event)"
      />

      <template v-if="missingFields.length > 0">
        <div class="mt-5 text-red font-bold text-lg">
          The fields below have values, but no longer are part of this form:
        </div>
        <div class="text-dark-silver text-base mb-5">
          Please move the content / assets in these fields to ones currently on
          this form
        </div>

        <form-renderer-field
          v-for="field in missingFields"
          :key="'form-field-' + field.id"
          class="form-field w-full"
          :field="field"
          :value="getValue(field)"
          :is-disabled="true"
          always-allow-clear
          @input="onInput(field, $event)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import FormRendererField from '@/components/Core/Form/FormBuilder/FormRendererField';
import { BuilderFields } from '@/constants';

export default {
  components: {
    FormRendererField
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    },
    isDisabled: Boolean
  },
  data() {
    return {
      readyList: []
    };
  },
  computed: {
    formValues() {
      let values = cloneDeep(this.value);

      for (let field of this.form.fields) {
        if (field.name && field.type) {
          if (!values.find(v => v.field.name === field.name)) {
            values.push({
              field,
              value: field.default_value
            });
          }
        }
      }

      return values;
    },
    formFields() {
      let fields = [];

      for (let field of this.form.fields) {
        fields.push({ ...BuilderFields[field.type], ...field });
      }

      return fields;
    },
    missingFields() {
      let missingFields = [];

      for (let fieldResponse of this.value) {
        if (!this.form.fields.find(f => f.name === fieldResponse.field.name)) {
          if (fieldResponse.value) {
            let builderField = BuilderFields[fieldResponse.field.type];

            missingFields.push({
              ...builderField,
              ...fieldResponse.field
            });
          }
        }
      }

      return missingFields;
    }
  },
  methods: {
    getValue(field) {
      let resolvedValue = null;

      for (let fieldResponse of this.value.filter(
        fr => fr.field.name === field.name
      )) {
        if (fieldResponse) {
          // If the resolved value is already set, it is guaranteed to be an array at this point
          if (resolvedValue) {
            resolvedValue = resolvedValue.concat(fieldResponse.value);
          } else {
            resolvedValue = fieldResponse.value;
          }

          // If this object is an array, we can potentially combine with other values of fields w/ same name, so we can continue the loop.
          // If not an array, then break
          if (!Array.isArray(resolvedValue)) {
            break;
          }
        }
      }

      return resolvedValue;
    },
    onReady(field) {
      this.readyList.push(field.id);

      if (this.readyList.length === this.form.fields.length) {
        this.$emit('ready');
      }
    },
    onInput(field, value) {
      let values = [...this.formValues];

      let fieldValue = values.find(v => v.field.name === field.name);

      fieldValue.value = value;

      // Make sure we change this field to the correct field record since we were looking up the value by name
      // Its possible the field was previously set via a different field w/ the same name
      fieldValue.field = field;

      this.$emit('input', values);
    }
  }
};
</script>

<style scoped lang="scss">
.form-field {
  & + .form-field {
    margin-top: 2em;
  }
}
</style>
