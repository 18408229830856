var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-invoice-document"},[_c('supplier-invoice-document-query',{attrs:{"id":_vm.$route.params.id,"loading":_vm.isLoadingInvoice},on:{"update:loading":function($event){_vm.isLoadingInvoice=$event},"data":_vm.onLoadedInvoice}}),_c('div',{staticClass:"invoice-page-header relative text-2xl"},[_c('div',{staticClass:"flex w-full"},[_c('div',{staticClass:"back-link px-5 py-4 font-bold"},[_c('router-link',{attrs:{"to":{ name: 'supplier.invoices' }}},[_c('icon',{attrs:{"icon":_vm.backIcon,"flip":"horizontal"}}),_vm._v(" Back to Invoices ")],1)],1)])]),(!_vm.invoice && !_vm.isLoadingInvoice)?_c('el-alert',{staticClass:"text-center text-lg text-bold my-4 mx-auto w-9/10",attrs:{"title":"Unable to find the requested Invoice","type":"error"}}):_vm._e(),(_vm.isDeleting)?_c('div',{staticClass:"mt-3"},[_c('el-alert',{staticClass:"text-center text-lg text-bold my-4 mx-auto w-9/10",attrs:{"title":"This invoice is being deleted","type":"error"}})],1):_vm._e(),_c('div',{staticClass:"invoice-doc-wrap flex justify-center my-5"},[_c('div',{staticClass:"flex-none relative"},[_c('supplier-invoice-document-mutation',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var isSaving = ref.isSaving;
          var updateInvoice = ref.updateInvoice;
          var unlockInvoice = ref.unlockInvoice;
          var createLineItem = ref.createLineItem;
          var removeLineItem = ref.removeLineItem;
          var updateLineItem = ref.updateLineItem;
return [_c('invoice-document',{staticClass:"m-auto",attrs:{"shadow":"","invoice":_vm.invoice,"editable":_vm.allowEditing,"locked":_vm.isLocked,"hovering":_vm.isHoveringDocument,"disabled":_vm.isExporting || _vm.isLoadingInvoice,"exporting":_vm.isExporting,"saving":isSaving},on:{"update:hovering":function($event){_vm.isHoveringDocument=$event},"focus":function($event){_vm.isFocusedDocument = true},"blur":function($event){_vm.isFocusedDocument = false},"change":function($event){return updateInvoice(_vm.invoice, $event)},"create-item":function($event){return createLineItem(_vm.invoice, $event)},"update-item":function($event){return updateLineItem(_vm.invoice, $event.id, $event.input)},"remove-item":function($event){return removeLineItem(_vm.invoice, $event)},"unlock":function($event){_vm.showConfirmUnlockDialog = true}}}),(_vm.showConfirmUnlockDialog)?_c('confirm-dialog',{attrs:{"confirm-text":"Unlock","confirm-class":"button-blue","is-saving":isSaving,"width":"40em","title":"Unlock Invoice For Editing"},on:{"confirm":function($event){return _vm.onUnlockInvoice(unlockInvoice)},"close":function($event){_vm.showConfirmUnlockDialog = false}}},[_vm._v(" This Invoice is locked for editing because it already has been sent to the Buyer. You may unlock this Invoice to edit it by Voiding the Invoice and creating a new Invoice that is a copy of this one. "),_c('br'),_c('br'),_vm._v(" Are you sure you want to Void the current Invoice and create a copy to make changes? ")]):_vm._e()]}}])})],1),(!_vm.isDeleting)?_c('div',{staticClass:"ml-5"},[_c('invoice-document-sidebar',{staticClass:"invoice-document-sidebar",attrs:{"invoice":_vm.invoice,"is-exporting":_vm.isExporting},on:{"update:isExporting":[function($event){_vm.isExporting=$event},function($event){_vm.isExporting = $event}],"update:is-exporting":function($event){_vm.isExporting=$event}}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }