<template>
  <div class="supplier-fulfillment-column">
    <el-popover v-if="isNational" trigger="hover" placement="left" width="275">
      <div slot="reference">
        <a>
          National Fulfillment
        </a>
      </div>

      <div class="text-left">
        <h4 class="mb-2">Flytedesk National Fulfillment</h4>
        Creative will be available 4 business days before the run date. This ad
        is subject to national fulfillment requirements.
        <a
          href="https://intercom.help/flytedesk/en/articles/3338499-how-are-national-ads-different-than-ads-booked-through-my-ad-shop"
          target="_blank"
          >Learn more.</a
        >
      </div>
    </el-popover>

    <el-popover v-else-if="ad.fulfillment_method" title="Fulfillment Criteria">
      <div slot="reference" class="inline-block no-outline">
        <a @click="showDialog = true">
          {{ ad.fulfillment_method.name }}
        </a>

        <div>Due On {{ ad.deadline_date | date }}</div>
      </div>

      <fulfillment-info
        v-if="ad.fulfillment_method"
        :fulfillment="ad.fulfillment_method"
        :deadline-date="ad.deadline_date"
      />
    </el-popover>

    <div v-else><i>N/A</i></div>

    <div v-if="showKeywords" class="mt-3">
      <div class="flex">
        <div class="font-bold flex">
          Keyword Targeting
        </div>
        <info-tooltip class="text-yellow ml-2" :icon="warningIcon">
          <b>Warning:</b> The keyword must match the value configured on the
          targeted placement. Incorrect or unmatched keyword values may prevent
          your ad from serving.
        </info-tooltip>
        <div class="ml-3">
          <a class="text-blue" @click="isEditingKeywords = !isEditingKeywords">
            <icon :icon="isEditingKeywords ? cancelIcon : editIcon" />
          </a>
        </div>
      </div>
      <div v-if="!isEditingKeywords">
        {{ keywords.join(', ') || 'N/A' }}
      </div>
      <ads-mutation v-else>
        <template v-slot="{ isSaving, supplierUpdateAd }">
          <el-select
            v-model="keywords"
            class="keyword-targeting"
            multiple
            allow-create
            filterable
            default-first-option
            popper-class="invisible"
            placeholder="Enter Keywords"
            :loading="isSaving"
            @change="supplierUpdateAd(ad, { keyword_targeting: keywords })"
          />
        </template>
      </ads-mutation>
    </div>
  </div>
</template>

<script>
import { CampaignType, FeatureFlags } from '@/constants';
import {
  attention as warningIcon,
  ok as cancelIcon,
  pencil as editIcon
} from '@/vendor/icons';
import FulfillmentInfo from '@/components/Supplier/Fulfillment/FulfillmentInfo';
import AdsMutation from '@/components/Mutations/Ads/AdsMutation';
import InfoTooltip from '@/components/Core/Tooltips/InfoTooltip';

export default {
  components: {
    InfoTooltip,
    AdsMutation,
    FulfillmentInfo
  },
  props: {
    ad: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      // State
      isEditingKeywords: false,
      showDialog: false,
      keywords: this.ad.keyword_targeting || [],

      // Icons
      cancelIcon,
      editIcon,
      warningIcon
    };
  },

  computed: {
    isNational() {
      return (
        this.ad &&
        this.ad.campaign &&
        this.ad.campaign.type === CampaignType.NATIONAL
      );
    },

    showKeywords() {
      return (
        this.$feature(FeatureFlags.AD_KEYWORD_TARGETING) && !this.isNational
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.supplier-fulfillment-column /deep/ {
  .el-input__suffix {
    display: none;
  }

  .el-select .el-input--suffix .el-input__inner {
    padding-right: 0.5em;
  }
}
</style>
