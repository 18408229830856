<script>
import axios from 'axios';
import { downloadFileResponse, renderless } from '@/utils/helpers';

export default {
  props: {
    name: {
      type: String,
      // Leaving this null will default to the name on the downloaded file
      default: null
    },
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isExporting: false
    };
  },
  computed: {
    pdfUrl() {
      return this.$apiUrl + `/report/pdf/?url=${btoa(this.url)}`;
    }
  },
  methods: {
    async exportToPdf() {
      this.isExporting = true;
      this.$emit('update:is-exporting', this.isExporting);

      this.$message.success(
        'Your PDF is being generated and will be downloaded shortly. Please wait for a moment while we get it ready for you!'
      );

      const response = await axios.get(this.pdfUrl, {
        responseType: 'arraybuffer'
      });

      downloadFileResponse(response, this.name);

      this.isExporting = false;
      this.$emit('update:is-exporting', this.isExporting);
    }
  },
  render(c) {
    return renderless.call(this, c, {
      exportToPdf: this.exportToPdf,
      isExporting: this.isExporting
    });
  }
};
</script>
